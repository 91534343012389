import {
    Injectable,
    Injector,
    inject
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import {
    ConfigurationManagerService,
    EntityManagerService,
    PrinterDocumentTypeId,
    paymentMethodTypes 
} from "src/app/core";

import { DocumentPrinterOptions } from "src/app/shared/model/document-printer.model";

import {
    groupBy,
    keyBy,
    removeFromArray
} from "src/app/shared/utils";

import {
    Sales,
    SalesPayments
} from "tilby-models";

import {
    DocumentPrintersManagerDialogStateService
} from "src/app/dialogs";

import {
    digitalPaymentsManager,
    documentPrinter,
    fiscalUtils
} from "app/ajs-upgraded-providers";

import {
    MathUtils
} from "@tilby/tilby-ui-lib/utilities";

import {
    SaleUtilsService
} from "./sale-utils.service";

import {
    stringToLines
} from "src/app/shared/string-utils";


@Injectable({
    providedIn: 'root'
})
export class SalePaymentService {
    private readonly translateService = inject(TranslateService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly digitalPaymentsManagerService = inject(digitalPaymentsManager);
    private readonly documentPrintersManager = inject(DocumentPrintersManagerDialogStateService);
    private readonly injector = inject(Injector);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly saleUtils = inject(SaleUtilsService)

    /**
     * Calculates the amount left to pay for a given sales target.
     *
     * @param {Sales} sale - The sales target to calculate the amount left.
     * @return {number} The amount left to pay for the sales target.
     */
    public getToPay (sale: Sales) {
        let toPay = 0;
        const saleAmount = sale.final_amount || 0;

        if(saleAmount > 0) {
            const sumPayments = sale.payments?.reduce((sum, p) => sum + p.amount, 0) || 0;
            toPay = MathUtils.round(saleAmount - sumPayments);
        }

        return (toPay > 0 ? toPay : 0);
    }

    /**
     * Calculates and returns the total amount paid for a given sale.
     *
     * @param {Sales} sale - The sale object containing the payments.
     * @return {number} The total amount paid for the sale.
     */
    public getPaid(sale: Sales) {
        return MathUtils.round(sale.payments?.reduce((sum, p) => sum + p.amount, 0) || 0);
    }

    /**
     * Calculates the change to be given back to the customer after a sale.
     *
     * @param {Sales} sale - the sale object containing the final amount and payments
     * @return {number} the amount of change to be given back to the customer, null if the sale is a void/refund
     */
    public getSaleChange(sale: Sales) {
        const finalAmount = sale.final_amount || 0;

        if (finalAmount < 0) {
            return null;
        }

        const sumPayments = sale.payments?.reduce((sum, p) => sum + p.amount, 0) || 0;
        const change = MathUtils.round(sumPayments - finalAmount);

        return (change > 0 ? change : 0);
    }

    /**
     * Checks if the given sale has any online digital payments.
     *
     * @param {Sales} sale - The sale object to check for online digital payments.
     * @return {boolean} Returns true if the sale has online digital payments, false otherwise.
     */
    public hasOnlineDigitalPayment (sale: Sales) {
        return !!sale.payments?.some(p => 
            this.digitalPaymentsManagerService.isPaymentDigital(p.payment_method_type_id) &&
            this.digitalPaymentsManagerService.isPaymentOnlineOnly(p.payment_method_type_id)
        );
    }

    /**
     * Processes the payments for a sale, and executes the digital transactions if needed
     *
     * @param {Sales} sale - The sale object. It will be modified in place.
     * @param {DocumentPrinterOptions} pDocData - The document printer options. It will be modified in place in case of overrides.
     */
    public async processPayments (sale: Sales, pDocData: DocumentPrinterOptions) {
        sale.payments = sale.payments || [];

        const paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline();
        const paymentMethodsById = keyBy(paymentMethods, m => m.id);
        const paymentsInSale = sale.payments.map(p => p.payment_method_id).filter((p, index, self) => self.indexOf(p) === index);

        //Check if we need to override the target printer
        const overridePayment = Object.values(paymentMethodsById)
            .filter(payment => paymentsInSale.includes(payment.id))
            .find(payment => payment.printer_id || payment.document_type_id);
        
        let overridePDocData;
        let targetPrinter = pDocData.printer;

        if(overridePayment) {
            //Abort transaction if there is an override payment and more than one payment
            if(sale.payments.length > 1) {
                throw 'CASHREGISTER.ACTIVE_SALE.MULTIPLE_PAYMENT_OVERRIDE_PRINTERS';
            }

            try {
                overridePDocData = await this.documentPrintersManager.getPrinterDocumentData(overridePayment.printer_id || pDocData?.printer.id || 'default', (overridePayment.document_type_id as PrinterDocumentTypeId || pDocData?.document_template?.id || pDocData.document_type.id), (pDocData.options || {}) );
            } catch(err) {
                switch(err) {
                    case 'PRINTER_NOT_CAPABLE':
                        throw 'CASHREGISTER.ACTIVE_SALE.PRINTER_NOT_CAPABLE';
                    default:
                        throw err;
                }
            }

            targetPrinter = overridePDocData.printer;
        }

        const paymentsByMethodType = groupBy(sale.payments, p => p.payment_method_type_id);
        const digitalPayments = sale.payments.filter((payment) => (!payment.paid && this.digitalPaymentsManagerService.isPaymentDigital(payment.payment_method_type_id)));

        if(digitalPayments.length) {
            const documentPrinterService = this.injector.get(documentPrinter);
            await documentPrinterService.isPrinterReachable(targetPrinter);

            try {
                pDocData.tail = "";

                for(let payment of digitalPayments) {
                    let paymentResults = await this.digitalPaymentsManagerService.digitalPayment(payment.amount, payment.payment_method_id, { sale: structuredClone(sale) });

                    if(!Array.isArray(paymentResults)) {
                        paymentResults = [paymentResults];
                    }

                    sale.payments = sale.payments?.filter((p) => p !== payment) || [];

                    const now = new Date().toISOString();

                    for(const resultPayment of paymentResults) {
                        //Set the payment as paid if the digital payment cannot be rolled back in case of transaction/print failure
                        resultPayment.amount = resultPayment.amount || payment.amount;
                        resultPayment.paid = this.digitalPaymentsManagerService.paymentHasPrintFailRollback(resultPayment.payment_method_type_id) ? false : true;
                        resultPayment.date = resultPayment.date || now;

                        //TODO: can we generate the payment tail from the payment_data in order to make the tail reproducible in case of failure?
                        pDocData.tail += `${resultPayment.tail || ""}`;
                        delete resultPayment.tail;

                        sale.payments.push(resultPayment);
                    }
                }
            } catch(error: any) {
                if(error?.response) {
                    if(error.receiptData) {
                        let printer = pDocData?.printer;

                        if(printer) {
                            documentPrinterService.printFreeNonFiscal(error.receiptData, printer.id, { printHeader: true });
                        }
                    }

                    throw error.response;
                } else {
                    throw error;
                }
            }

            const toPay = this.getToPay(sale);

            if(toPay) {
                throw 'CASHREGISTER.ACTIVE_SALE.DIGITAL_PAYMENT_LEFT_TO_PAY';
            }
        } else {
            const toPay = this.getToPay(sale);

            if(toPay > 0 && this.fiscalUtils.isPaymentRoundingEnabled()) {
                sale.payments.push({
                    amount: toPay,
                    date: new Date(),
                    payment_method_id: 0,
                    payment_method_name: "Sconto a pagare",
                    payment_method_type_id: 26,
                    payment_method_type_name: "Sconto a pagare",
                    payment_data: JSON.stringify({ rounding: true }),
                    unclaimed: true
                });
            }
        }

        //Add tail for bank transfer payments (only if any of the preferences is set)
        if(paymentsByMethodType[8]) {
            pDocData.tail = (pDocData.tail || "") + this.getBankTailInfo();
        }

        if(overridePDocData) {
            Object.assign(pDocData, overridePDocData);
        }
    }

    private getBankTailInfo () {
        const eInvoiceInfo = this.configurationManagerService.getShopPreferences('e_invoice.');
        const bankTailRows = [];

        if (eInvoiceInfo['e_invoice.bank_merchant_name']) {
            bankTailRows.push(eInvoiceInfo['e_invoice.bank_merchant_name']);
        }

        if (eInvoiceInfo['e_invoice.bank_name']) {
            bankTailRows.push(this.translateService.instant('CASHREGISTER.PAYMENTS.BANK_TRANSFER.BANK_NAME', { name: eInvoiceInfo['e_invoice.bank_name'] }));
        }

        if (eInvoiceInfo['e_invoice.bank_iban']) {
            bankTailRows.push(this.translateService.instant('CASHREGISTER.PAYMENTS.BANK_TRANSFER.BANK_IBAN', { iban: eInvoiceInfo['e_invoice.bank_iban'] }));
        }

        if (eInvoiceInfo['e_invoice.bic']) {
            bankTailRows.push(this.translateService.instant('CASHREGISTER.PAYMENTS.BANK_TRANSFER.BANK_BIC', { bic: eInvoiceInfo['e_invoice.bic'] }));
        }

        if(bankTailRows.length) {
            bankTailRows.unshift(
                this.translateService.instant('CASHREGISTER.PAYMENTS.BANK_TRANSFER.TAIL_TITLE'),
                this.translateService.instant('CASHREGISTER.PAYMENTS.BANK_TRANSFER.TAIL_DESCRIPTION'),
            );
        }

        return stringToLines(bankTailRows, 40).join("\n");
    }

    /**
     * Processes the sale change. Sets the change and the change type to the sale.
     *
     * @param {Sales} sale - The sale object.
     */
    public processSaleChange (sale: Sales) {
        sale.change_type = undefined;
        sale.change = undefined;
        sale.payments = sale.payments || [];

        const change = this.getSaleChange(sale) || 0;        

        if(!change) {
            return;
        }

        const cashPaymentsSum = sale.payments.filter(payment => this.fiscalUtils.isCashPayment(payment.payment_method_type_id)).reduce((sum, payment) => sum + payment.amount, 0);
        const ticketPaymentsSum = sale.payments.filter(payment => payment.payment_method_type_id === 6).reduce((sum, payment) => sum + payment.amount, 0);

        let changeType: Sales.ChangeTypeEnum;

        //Determine change type
        if(cashPaymentsSum > 0) {
            changeType = "cash";
        } else if(ticketPaymentsSum > 0) {
            changeType = "ticket";
        } else {
            changeType = "other";
        }

        let changeToGive = change;

        for(const payment of sale.payments) {
            switch(payment.payment_method_type_id) {
                case 19:
                case 21:
                    try {
                        let paymentMeta = JSON.parse(payment.payment_data || "{}");

                        if(paymentMeta.changeGiven) {
                            changeToGive = MathUtils.round(changeToGive - paymentMeta.changeGiven);
                        }
                    } catch(e) {}
                break;
                default:
                break;
            }
        }

        //Set change
        sale.change = changeToGive;
        sale.change_type = changeType;
    }

    /**
     * Converts quick coupons to tickets for a given sale.
     * This method removes all quick coupons from the sale object and adds them to the payments as tickets.
     *
     * @param {Sales} sale - The sale object.
     */
    public async quickCouponsToTickets(sale: Sales) {
        const quickCoupons = this.saleUtils.getConfiguredQuickCoupons();

        if(!quickCoupons.length) {
            return;
        }

        const saleQuickCoupons = removeFromArray(sale.sale_items || [], (saleItem) => 
            saleItem.type === 'coupon' &&
            saleItem.quantity === -1 &&
            quickCoupons.some((coupon) => coupon.name === saleItem.name && coupon.value === saleItem.price && coupon.department_id === saleItem.department_id)
        );

        if(!saleQuickCoupons.length) {
            return;
        }

        const ticketMethodType = paymentMethodTypes.find((paymentMethodType) => paymentMethodType.id === 6)!;
        const ticketPaymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 6 });

        if(!ticketPaymentMethods.length) {
            throw 'CASHREGISTER.PAYMENTS.NO_TICKET_CONFIGURED';
        }

        const ticketMethod = ticketPaymentMethods[0];

        const quickCouponsTickets = <SalesPayments[]>saleQuickCoupons.map((coupon) => ({
            amount: coupon.price,
            date: new Date().toISOString() as any,
            payment_method_id: ticketMethod.id,
            payment_method_name: ticketMethod.name,
            payment_method_type_id: ticketMethodType.id,
            payment_method_type_name: ticketMethodType.name,
            ticket_name: coupon.name,
            unclaimed: this.fiscalUtils.isMethodUnclaimed(ticketMethodType.id)
        }));

        sale.payments = [...(sale.payments || []), ...quickCouponsTickets].filter((payment) => payment.amount !== 0);

        this.saleUtils.calculateSalePrices(sale);
    }
}