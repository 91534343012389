import angular from 'angular';

import {
    SatispayApiService,
    SatispayPayment
} from 'app/modules/application/service/satispay-api/satispay-api';

import $ from 'jquery';

import {
    MathUtils
} from 'src/app/shared/utils';

interface SatispayPaymentsOptions {
    amount: number;
    paymentMethodId: number;
}

interface SatispayPaymentUI {
    id: string;
    amount_unit: number,
    disabled?: boolean,
    expired: boolean,
    insert_date: string,
    original_data: SatispayPayment
    removed?: boolean
    customer_amount: number,
    customer_name?: string,
    customer_image?: string
}

let dialogHasShown = false;

class SatispayPaymentsController {
    protected payments: SatispayPaymentUI[] = [];
    protected needsSetup: boolean = false;
    protected activationCode: string = '';
    protected operationInProgress: boolean = false;
    protected isOffline: boolean = false;
    protected amountToPay: number | undefined;
    protected message: string = '';

    private paymentsById: Record<string, SatispayPaymentUI> = {};
    private paymentMethodId: number;

    constructor(
        private $scope: any,
        private $mdDialog: any,
        private $timeout: any,
        private $translate: any,
        private satispayApi: SatispayApiService,
        private options: SatispayPaymentsOptions
    ) {
        this.paymentMethodId = this.options.paymentMethodId;

        if (this.options.amount) {
            this.amountToPay = MathUtils.round(this.options.amount / 100);
        }

        this.$scope.$on("connection:changed", (_: any, data: any) => {
            this.isOffline = data.status === 'offline';
        });

        this.$timeout(() => this.fetchPayments());
    }

    showLoader(): boolean {
        return this.payments.length === 0 && dialogHasShown && !this.needsSetup;
    }

    async pairShop() {
        if (!this.activationCode) {
            return;
        }

        this.operationInProgress = true;
        this.message = this.$translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLING');

        try {
            await this.satispayApi.setupShop(this.paymentMethodId, this.activationCode);
            this.message = this.$translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLE_SUCCESSFUL');
            this.needsSetup = false;

            window.setTimeout(() => {
                this.deleteMessage();
                this.fetchPayments();
            }, 1500);
        } catch (error: any) {
            $('#SatispayCodeInput').trigger('focus');
            this.message = this.getErrorMessage(error);
        } finally {
            this.operationInProgress = false;
        }
    }

    private getErrorMessage(error: any): string {
        if (!error || error.status === -1) {
            return '';
        }

        switch (error?.status) {
            case 404:
            case 403:
                return this.$translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.INVALID_CODE');
            default:
                return this.$translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLE_FAILED');
        }
    }

    deleteMessage() {
        this.message = '';
    }

    private async fetchPayments() {
        if (this.$scope.$$destroyed) {
            return;
        }

        try {
            const response = await this.satispayApi.getPendingPayments(this.paymentMethodId);

            if (response?.data) {
                this.processPayments(response.data);
            }

            this.$timeout(() => this.fetchPayments(), 2000);
        } catch (error: any) {
            this.handleFetchError(error);
        }
    }

    private processPayments(paymentsData: SatispayPayment[]) {
        const paymentIdsSet = new Set<string>();

        //Add new payments
        for (const payment of paymentsData) {
            const uiPayment: SatispayPaymentUI = {
                id: payment.id,
                amount_unit: payment.amount_unit,
                expired: payment.expired,
                insert_date: payment.insert_date,
                original_data: payment,
                customer_amount: MathUtils.round(payment.amount_unit / 100),
            };

            if (uiPayment.expired || (this.options.amount && uiPayment.amount_unit !== this.options.amount)) {
                uiPayment.disabled = true;
            }

            if (payment.sender) {
                uiPayment.customer_name = payment.sender.name || '';

                const profilePictures = payment.sender.profile_pictures?.data;

                if (Array.isArray(profilePictures)) {
                    const profilePicture = profilePictures.find((pic) => pic.is_original) || profilePictures[0];

                    uiPayment.customer_image = profilePicture?.url;
                }
            }

            this.paymentsById[payment.id] = uiPayment;

            paymentIdsSet.add(payment.id);
        }

        //Disable removed payments
        for (const [id, payment] of Object.entries(this.paymentsById)) {
            if (!paymentIdsSet.has(id)) {
                this.paymentsById[id] = {
                    ...payment,
                    disabled: true,
                    removed: true
                }
            }
        }

        //Sort payments
        this.payments = Object.values(this.paymentsById).sort((a, b) => {
            const aSortValue = a.expired || (this.options.amount && a.amount_unit !== this.options.amount) ? Infinity : new Date(a.insert_date).getTime();
            const bSortValue = b.expired || (this.options.amount && b.amount_unit !== this.options.amount) ? Infinity : new Date(b.insert_date).getTime();

            return aSortValue - bSortValue;
        });
    }

    private handleFetchError(error: any) {
        if (!error) {
            this.$mdDialog.cancel('UNKNOWN_ERROR');
            return;
        }

        switch (error.status) {
            case -1:
                this.$timeout(() => this.fetchPayments(), 1000);
                break;
            case 403:
                this.needsSetup = true;
                break;
            default:
                this.$mdDialog.cancel('UNKNOWN_ERROR');
                break;
        }
    }

    async removePayment(payment: SatispayPaymentUI) {
        this.operationInProgress = true;

        try {
            await this.satispayApi.cancelPayment(this.paymentMethodId, payment.id);
            delete this.paymentsById[payment.id];

            this.payments = this.payments.filter(p => p.id !== payment.id);
        } finally {
            this.operationInProgress = false;
        }
    }

    cancel() {
        this.$mdDialog.cancel('CANCELED');
    }

    selectPayment(payment: SatispayPaymentUI) {
        if (!payment.disabled) {
            this.$mdDialog.hide(payment);
        }
    }
}

SatispayPaymentsController.$inject = [
    "$scope",
    "$mdDialog",
    "$timeout",
    "$translate",
    "satispayApi",
    "options"
];

export class SatispayPaymentsService {
    constructor(
        private $mdDialog: any
    ) {
    }

    show(options: SatispayPaymentsOptions): Promise<SatispayPaymentUI> {
        return this.$mdDialog.show({
            controller: SatispayPaymentsController,
            controllerAs: '$ctrl',
            bindToController: true,
            template: require('./satispay-payments.html'),
            locals: {
                options: options
            },
            onShowing: () => { dialogHasShown = false; },
            onComplete: () => { dialogHasShown = true; },
            onRemoving: () => { dialogHasShown = false; }
        });
    }
};

SatispayPaymentsService.$inject = [
    "$mdDialog"
];

angular.module('cashregister').service('satispayPayments', SatispayPaymentsService);