import angular from 'angular';
import _ from 'lodash';
import { MathUtils, keyBy } from 'src/app/shared/utils';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';

angular.module('cashregister').factory('splitSaleDialog', splitSaleDialog);

splitSaleDialog.$inject = ["$mdDialog", "$filter", "$translate", "saleUtils", "entityManager", "checkManager", "OperatorManager", "promptDialog", "saleTransactionUtils", "newSaleUtils"];

function splitSaleDialog($mdDialog, $filter, $translate, saleUtils, entityManager, checkManager, OperatorManager, promptDialog, saleTransactionUtils, newSaleUtils) {
    splitSaleDialogController.$inject = ["$scope", "sale", "options"];

    function splitSaleDialogController($scope, sale, options) {
        Object.assign($scope, {
            groupItemPrices: {},
            disableAmountAndCovers: checkManager.getPreference('cashregister.disable_split_sale_amount_covers'),
            options: {
                department: null
            }
        });

        var getNewSaleName = function(currentName) {
            var splitSuffix = " - " + $scope.splitIndex;
            return _.truncate(currentName, { length: 30 - splitSuffix.length, omission: '' }) + splitSuffix;
        };

        var updateNewSaleData = function(newSale) {
            const opData = OperatorManager.getOperatorData();
            const dateNow = new Date();

            Object.assign(newSale,{
                name: getNewSaleName($scope.originalSale.name),
                open_at: dateNow.toISOString(),
                sale_parent_uuid: $scope.originalSale.uuid,
                split_sale_origin_uuid: $scope.originalSale.split_sale_origin_uuid || $scope.originalSale.uuid,
                seller_id: opData.id,
                seller_name: opData.full_name,
                status: "open",
                uuid: generateUuid()
            });

            if(!validateUuid($scope.originalSale.id)) {
                newSale.sale_parent_id = parseInt($scope.originalSale.id) || null;
            }

            for(let saleItem of newSale.sale_items) {
                updateNewSaleItemData(saleItem);
            }
        };

        var updateNewSaleItemData = function(saleItem) {
            const opData = OperatorManager.getOperatorData();
            const dateNow = new Date().toISOString();

            if($scope.options.type === 'by_items') {
                _.set($scope.originalSale, ['deleted_items', saleItem.uuid, 'split_sale_quantity'], saleItem.quantity);
            }

            Object.assign(saleItem, {
                added_at: dateNow,
                lastupdate_at: dateNow,
                lastupdate_by: opData.id,
                seller_id: opData.id,
                seller_name: opData.full_name,
                uuid: generateUuid()
            });
        };

        var updateSalePrices = function() {
            saleUtils.calculateSalePrices($scope.originalSale);
            saleUtils.calculateSalePrices($scope.splitSale);
        };

        const removeSaleItemRelations = (sale) => {
            for(let saleItem of sale.sale_items) {
                if($scope.groupItemPrices[saleItem.sale_item_parent_uuid] != null) {
                    saleItem.sale_item_parent_uuid = null;
                }
            }
        };

        const prepareSplitSale = () => {
            //Set covers to 1 (only if is already set, if not don't set it)
            if($scope.originalSale.covers) {
                $scope.originalSale.covers = 1;
            }

            //Keep percent-based price changes
            const priceChangesToKeep = ['discount_perc', 'surcharge_perc', 'gift'];
            const saleItemSubEntities = ['price_changes', 'variations', 'ingredients'];
            const saleFieldsToClean = ['id', 'sale_customer', 'closed_at', 'created_at', 'createdby_id', 'deleted_at', 'deletedby_id', 'updated_at', 'updatedby_id'];
            const saleItemFieldsToClean = ['id', 'sale_id', 'created_at', 'deleted_at', 'updated_at'];
            const saleItemSubFieldsToClean = ['id', 'sale_item_id'];

            //Initialize sale_items and price_changes arrays if necessary
            for(let subEntity of ['sale_items', 'price_changes']) {
                if(!Array.isArray($scope.originalSale[subEntity])) {
                    $scope.originalSale[subEntity] = [];
                }
            }

            //Pre-calculate group item prices
            $scope.groupItemPrices = {};

            for(let saleItem of $scope.originalSale.sale_items.filter((si) => !si.item_id)) {
                const relatedItems = $scope.originalSale.sale_items.filter((si) => si.sale_item_parent_uuid === saleItem.uuid);

                if(relatedItems.length) {
                    $scope.groupItemPrices[saleItem.uuid] = saleItem.price;

                    for(let si of relatedItems) {
                        $scope.groupItemPrices[saleItem.uuid] = MathUtils.round($scope.groupItemPrices[saleItem.uuid] + si.price);
                    }
                }
            }

            //Cleanup price changes from original sale
            $scope.originalSale.price_changes = $scope.originalSale.price_changes.filter((priceChange) => priceChangesToKeep.includes(priceChange.type));

            for(let saleItem of $scope.originalSale.sale_items) {
                //Initialize sale item subentities if necessary
                for(let subEntity of saleItemSubEntities) {
                    if(!Array.isArray(saleItem[subEntity])) {
                        saleItem[subEntity] = [];
                    }
                }

                //Cleanup price changes from sale item
                saleItem.price_changes = saleItem.price_changes.filter((priceChange) => priceChangesToKeep.includes(priceChange.type));
            }

            //Create the split sale as a copy of the original sale
            $scope.splitSale = _.cloneDeep($scope.originalSale);

            //Cleanup backend-related fields from the split sale
            for(let field of saleFieldsToClean) {
                delete $scope.splitSale[field];
            }

            $scope.splitSale.split_sale_origin_uuid = $scope.originalSale.split_sale_origin_uuid || $scope.originalSale.uuid;

            //Cleanup sale documents and payments from split sale
            Object.assign($scope.splitSale, {
                sale_documents: [],
                payments: []
            });

            //Cleanup backend-related fields from split sale price changes
            for(let priceChange of $scope.splitSale.price_changes) {
                for(let field of saleItemFieldsToClean) {
                    delete priceChange[field];
                }
            }

            //Cleanup backend-related fields from split sale items
            for(let saleItem of $scope.splitSale.sale_items) {
                //Delete backend-related fields
                for(let field of saleItemFieldsToClean) {
                    delete saleItem[field];
                }

                for(let subEntityName of saleItemSubEntities) {
                    for(let subEntity of saleItem[subEntityName]) {
                        for(let field of saleItemSubFieldsToClean) {
                            delete subEntity[field];
                        }
                    }
                }

                saleItem.quantity = 0;
            }

            updateSalePrices();

            $scope.dialogMode = 'sale_view';
        };

        $scope.getRowPrice = (saleItem) => $filter('sclCurrency')(($scope.groupItemPrices[saleItem.uuid] ?? saleItem.final_price) * saleItem.quantity);

        //A sale can be confirmed if there is at least an item with quantity != 0
        $scope.canConfirmSale = () => !!$scope.splitSale?.sale_items?.find((saleItem) => (saleItem.quantity !== 0));

        const doMoveSaleItem = (saleItem, destinationSale, quantityPerc) => {
            if (!saleItem.quantity) {
                return;
            }

            const destination = destinationSale.sale_items.find((item) => item.uuid === saleItem.uuid);

            if (!destination) {
                return;
            }

            if (quantityPerc) {
                const totalQuantity = MathUtils.round(saleItem.quantity + destination.quantity, 3);

                destination.quantity = Math.round(totalQuantity * quantityPerc);
                saleItem.quantity = MathUtils.round(totalQuantity - destination.quantity, 3);
            } else if (saleItem.quantity % 1) {
                destination.quantity = saleItem.quantity;
                saleItem.quantity = 0;
            } else {
                destination.quantity++;
                saleItem.quantity--;
            }

            return [destination.quantity, saleItem.quantity];
        };

        const moveSaleItem = (saleItem, sourceSale, destinationSale) => {
            // Don't move childrens if the father is in the sale.
            if (saleItem.sale_item_parent_uuid && sourceSale.sale_items.some(si => si.uuid === saleItem.sale_item_parent_uuid)) {
                return;
            }

            //Move target sale item
            const [destinationQuantity, sourceQuantity] = doMoveSaleItem(saleItem, destinationSale);

            if (!saleItem.sale_item_parent_uuid) {
                //Check for related sale items and move them as well
                const relatedItems = sourceSale.sale_items.filter(si => si.sale_item_parent_uuid && si.sale_item_parent_uuid === saleItem.uuid);

                if (relatedItems.length) {
                    const quantityPerc = destinationQuantity / (destinationQuantity + sourceQuantity);

                    for (const childSaleItem of relatedItems) {
                        doMoveSaleItem(childSaleItem, destinationSale, quantityPerc);
                    }
                }
            }

            //Update sale prices
            updateSalePrices();
        };

        $scope.moveToSplitSale = (saleItem) => moveSaleItem(saleItem, $scope.originalSale, $scope.splitSale);

        $scope.moveToOriginalSale = (saleItem) => moveSaleItem(saleItem, $scope.splitSale, $scope.originalSale);

        $scope.cancel = function() {
            removeSaleItemRelations(sale);
            $mdDialog.cancel();
        };

        $scope.visibleItems = (sale) => sale.sale_items.filter((saleItem) => (saleItem.quantity > 0 && $scope.groupItemPrices[saleItem.sale_item_parent_uuid] == null));

        $scope.canConfirmSplit = function() {
            switch($scope.options.type) {
                case 'by_covers':
                    return $scope.options.covers >= 2;
                case 'by_amount':
                    return $scope.options.amount >= 0.01;
                default:
                    return false;
            }
        };

        $scope.goBack = function() {
            switch($scope.dialogMode) {
                case 'type_options':
                    $scope.dialogMode = 'type_select';
                    $scope.options = {};
                break;
                case 'sale_view':
                    delete $scope.originalSale;
                    delete $scope.splitSale;

                    switch($scope.options.type) {
                        case 'by_covers': case 'by_amount':
                            $scope.dialogMode = 'type_options';
                        break;
                        case 'by_items': default:
                            $scope.dialogMode = 'type_select';
                        break;
                    }
                break;
            }
        };

        $scope.splitItem = async (saleItem) => {
            const answer = await promptDialog.show({ type: 'number', title: $translate.instant('CASHREGISTER.SPLIT_SALE.SPLIT_ITEM_PROMPT'), label: $translate.instant('CASHREGISTER.SPLIT_SALE.SPLIT_ITEM_PARTS'), min: 2, step: 1 });

            if(!answer) {
                return;
            }

            const insertIdx = $scope.originalSale.sale_items.findIndex((si) => si === saleItem);
            const quantityPerItem = MathUtils.round(saleItem.quantity / answer);
            const lastItemQuantity = MathUtils.round(saleItem.quantity - (quantityPerItem * (answer - 1)));
            
            for(let i = 1; i <= (answer - 1); i++) {
                const newSi = structuredClone(saleItem);

                Object.assign(newSi, {
                    quantity: quantityPerItem,
                    id: undefined,
                    uuid: generateUuid()
                });

                const newSplitSi = structuredClone(newSi);
                newSplitSi.quantity = 0;

                $scope.originalSale.sale_items.splice(insertIdx, 0, newSi);
                $scope.splitSale.sale_items.splice(insertIdx, 0, newSplitSi);
            }

            saleItem.quantity = lastItemQuantity;
            updateSalePrices();
        };

        $scope.confirm = function() {
            const result = {
                originalSale: $scope.originalSale
            };

            if(['by_covers', 'by_amount'].includes($scope.options.type)) {
                result.storeCurrentSale = true;
            }

            if(!result.storeCurrentSale) {
                //Initialize sale transaction
                const saleTransaction = saleTransactionUtils.createSaleTransaction(result.originalSale, OperatorManager.getOperatorData());
                saleTransaction.skip_printing = true;

                //Check if the original sale has sale items. If not, we only need to change the original sale name as this is the last sale. 
                const hasSaleItems = $scope.originalSale.sale_items.some(si => si.quantity !== 0);

                if(hasSaleItems) {
                    const originalSaleItems = keyBy(sale.sale_items, (si) => si.uuid);

                    for (const saleItem of $scope.originalSale.sale_items) {
                        const originalSaleItem = originalSaleItems[saleItem.uuid];
                        const saleItemTransaction = saleTransactionUtils.createSaleItemTransaction(saleItem);

                        if (!originalSaleItem) {
                            //Item was added during the split sale (via splitItem method), so add it to the original sale
                            const { quantity, ...itemDetails } = saleItem;

                            saleItemTransaction.quantity_difference = saleItem.quantity;
                            saleItemTransaction.sale_item_details = itemDetails;

                            saleTransaction.sale_items_transactions.push(saleItemTransaction);
                        } else if(originalSaleItem.quantity !== saleItem.quantity) {
                            //Item quantity was changed during the split sale
                            saleItemTransaction.quantity_difference = MathUtils.round(saleItem.quantity - originalSaleItem.quantity, 3);
                            saleItemTransaction.sale_item_details = {
                                price_changes: saleItem.price_changes
                            };

                            saleTransaction.sale_items_transactions.push(saleItemTransaction);
                        }
                    }
                } else {
                    saleTransaction.sale_details = {
                        name: getNewSaleName($scope.originalSale.name),
                        split_sale_origin_uuid: $scope.originalSale.split_sale_origin_uuid || $scope.originalSale.uuid,
                        price_changes: $scope.originalSale.price_changes
                    };

                    if($scope.originalSale.covers) {
                        saleTransaction.sale_details.covers = $scope.originalSale.covers;
                    }
                }

                result.originalSaleTransaction = saleTransaction;
            }

            _.remove($scope.originalSale.sale_items, { quantity: 0 });
            _.remove($scope.splitSale.sale_items, { quantity: 0 });

            removeSaleItemRelations($scope.splitSale);

            if (!$scope.originalSale.sale_items.length) { //if the original sale is empty, ergo this should be the last split sale
                $scope.originalSale.sale_items = $scope.splitSale.sale_items;
                $scope.originalSale.name = getNewSaleName($scope.originalSale.name);

                saleUtils.calculateSalePrices($scope.originalSale);
            } else {
                updateNewSaleData($scope.splitSale);
                result.splitSale = $scope.splitSale;
            }

            $mdDialog.hide(result);
        };

        $scope.splitByItems = function() {
            $scope.originalSale = _.cloneDeep(sale);
            $scope.options = { type: 'by_items' };
            prepareSplitSale();
        };

        const getCountryDefaultVat = () => {
            switch(checkManager.getShopCountry()) {
                case 'IT':
                    return 10;
                case 'DE':
                    return 7;
                case 'ES':
                    return 7;
                case 'FR':
                    return null;
                default:
                    return 10;
            }
        };

        $scope.splitByCoversOrAmount = async (type) => {
            if($scope.disableAmountAndCovers) {
                return;
            }

            $scope.departments = await entityManager.departments.fetchCollectionOffline();

            let covers;

            if(sale.covers) {
                covers = sale.covers;
            } else if(checkManager.getPreference('orders.automated_add_cover') && checkManager.getPreference('orders.automated_add_cover.type') === 'id'){
                let idCover = _.toInteger(checkManager.getPreference('orders.automated_add_cover.value'));

                covers = _.chain(sale.sale_items).find({ item_id: idCover }).get('quantity').value() || 2;
            } else {
                covers = 2;
            }

            $scope.options = {
                type: type,
                covers: covers,
                itemName: checkManager.getPreference('cashregister.split_sale.default_item_name') || $translate.instant('CASHREGISTER.SPLIT_SALE.DEFAULT_ITEM_NAME')
            };

            let defaultDepId = checkManager.getPreference('cashregister.split_sale.default_department_id');

            if(defaultDepId) {
                defaultDepId = _.toInteger(defaultDepId);
                $scope.options.department = $scope.departments.find((dep) => dep.id === defaultDepId) || null;
            } else {
                let countryDefaultVat = getCountryDefaultVat();
    
                if(countryDefaultVat !== null) {
                    $scope.options.department = $scope.departments.find((dep) => dep.vat.value === countryDefaultVat) || null; 
                }
            }

            $scope.dialogMode = "type_options";
        };

        $scope.previewPeopleTotal = function() {
            return ($scope.options.type === 'by_amount') ? $scope.options.amount : MathUtils.round(sale.final_amount / $scope.options.covers);
        };

        $scope.confirmSplitOptions = async () => {
            //Create split sale template
            const newSale = await saleUtils.getSaleTemplate({ skipUpdateProgressive: true });

            newSale.split_sale_origin_uuid = sale.uuid;
            newSale.sale_parent_uuid = sale.uuid;

            Object.assign(newSale, _.pick(sale, ['name', 'covers', 'sale_number', 'seller_id', 'seller_name', 'order_id', 'order_uuid', 'assigned_id', 'assigned_name', 'table_id', 'table_name', 'room_id', 'room_name']));

            newSale.sale_items = await newSaleUtils.repartitionSaleItemsByDepartment(sale, $scope.options.type, $scope.options.type === 'by_amount' ? $scope.options.amount : $scope.options.covers, $scope.options.department);

            for(const saleItem of newSale.sale_items) {
                Object.assign(saleItem, {
                    name: $scope.options.itemName || saleItem.name,
                    seller_id: newSale.seller_id,
                    seller_name: newSale.seller_name,
                });
            }

            saleUtils.calculateSalePrices(newSale);

            $scope.originalSale = newSale;

            prepareSplitSale();
        };
        
        $scope.dialogMode = "type_select";
        $scope.splitIndex = options.splitIndex || 1;

        if(options.type) {
            $scope.disableBack = true;

            if(options.type === 'by_items') {
                $scope.splitByItems();
            } else {
                $scope.splitByCoversOrAmount(options.type);
            }
        }
    }

    var splitSaleDialog = {
        show: function(sale, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            return $mdDialog.show({
                controller: splitSaleDialogController,
                template: require('./split-sale.html'),
                locals: {
                    sale: sale,
                    options: options
                }
            });
        }
    };

    return splitSaleDialog;
}