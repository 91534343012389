import * as pdfMake from 'pdfmake/build/pdfmake';

import {
    Component,
    computed,
    inject,
    Injectable,
    signal,
    ViewEncapsulation
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
    GeneralInputButton
} from '@tilby/tilby-ui-lib/models';

import {
    lastValueFrom
} from 'rxjs';

import {
    EnvironmentInfoService
} from 'src/app/core';

import {
    Rooms,
    Sales,
} from 'tilby-models';

import {
    TilbyCurrencyPipe
} from '@tilby/tilby-ui-lib/pipes/tilby-currency';

import {
    CommonModule
} from '@angular/common';

import {
    MatListModule,
    MatSelectionListChange
} from '@angular/material/list';

import {
    TilbyDatePipe
} from '@tilby/tilby-ui-lib/pipes/tilby-date';

import {
    MatIconModule
} from '@angular/material/icon';

import {
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';

import {
    OpenDialogsService
} from '../services';

import {
    TDocumentDefinitions
} from 'pdfmake/interfaces';

import {
    documentPrinter
} from 'app/ajs-upgraded-providers';

import { mobileCheck } from 'src/utilities';

import {
    MatFormFieldModule
} from '@angular/material/form-field';

import {
    MatInputModule
} from '@angular/material/input';

import {
    MatSelectChange,
    MatSelectModule
} from '@angular/material/select';

import {
    FormControl,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import {
    SelectAllDirective
} from 'src/app/directives/select-all.direttive';

import {
    OccupiedTablesReportGeneratorService
} from './occupied-tables-report-generator.service';

import {
    PrinterErrorFiscalDialogService
} from '../printer-error-fiscal-dialog';

import {
    groupBy
} from 'src/app/shared/utils';

import {
    downloadFile
} from 'src/app/shared/data-conversion-utils';

type SummaryOfOccupiedTablesDialogData = {
    rooms: Rooms[];
    sales: Sales[];
};

type SummaryType = {
    sale: Sales;
    amount: number;
    sentExits: number;
    exits: number;
    seller_id: number | null;
    seller_name: string | null;
};

type SellerType = {
    id: number;
    name: string;
};

@Component({
    selector: 'app-summary-of-occupied-tables-dialog',
    templateUrl: './summary-of-occupied-tables-dialog.component.html',
    styleUrls: ['./summary-of-occupied-tables-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        TilbyCurrencyPipe,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        SelectAllDirective
    ],
    encapsulation: ViewEncapsulation.None
})
export class SummaryOfOccupiedTablesDialog {
    protected readonly data: SummaryOfOccupiedTablesDialogData = inject(MAT_DIALOG_DATA);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly reportGenerator = inject(OccupiedTablesReportGeneratorService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translateService = inject(TranslateService);

    protected isMobile = mobileCheck();
    private canShare = this.environmentInfoService.canShare();
    private canDownloadFiles = this.environmentInfoService.canDownloadFiles();

    protected customActions: GeneralInputButton[] = [
        {
            isIt: signal(this.canDownloadFiles && !this.isMobile),
            name: '',
            icon: signal('download'),
            click: () => this.downloadFile(),
            isDisable: signal(false)
        },
        {
            isIt: signal(this.canDownloadFiles && !this.isMobile),
            name: '',
            icon: signal('picture_as_pdf'),
            click: () => this.downloadPdf(),
            isDisable: signal(false)
        },
        {
            isIt: signal(this.canShare && this.isMobile),
            name: '',
            icon: signal('share'),
            iconType: 'symbols',
            click: () => this.share()
        },
        {
            isIt: signal(true),
            name: '',
            icon: signal('print'),
            click: () => this.openDetails(),
            isDisable: signal(false)
        }
    ];

    private selectedRooms: Rooms[] = [];
    private selectedSellers: SellerType[] = [];
    private readonly originalSummary: SummaryType[];
    private totalAmount = signal(0);

    protected hasSelectedAllRooms = true;
    protected otherLabel = this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OTHER`);
    protected othersLabel = this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OTHERS`);
    protected rooms = this.data.rooms;
    protected selectedRoomsMobile = new FormControl<Rooms[]>(this.data.rooms);
    protected selectedSellersMobile = new FormControl<SellerType[]>([]);
    protected sellers: SellerType[] = [];
    protected summary: SummaryType[] = [];
    protected title = computed(() => `${this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OPEN_TABLES`)} - ${this.tilbyCurrencyPipe.transform(this.totalAmount())} `);
    protected totalCovers = signal(0);

    constructor () {
        const sellers: Map<number, SellerType> = new Map();
        this.originalSummary = [];

        let totalAmount = 0;
        let totalCovers = 0;

        const salesWithRoomsAndTables = this.data.sales.filter(sale => sale.order_type === 'normal' && sale.room_id && sale.table_id);

        for (const sale of salesWithRoomsAndTables) {
            const saleItems = sale.sale_items || [];

            const exits = new Set(saleItems.map(item => item.exit).filter(exit => exit)).size;
            const sentExits = Object.keys(sale.sent_exits || {}).length;

            for (const saleItem of saleItems) {
                if (saleItem.seller_id && !sellers.has(saleItem.seller_id)) {
                    sellers.set(saleItem.seller_id, {
                        id: saleItem.seller_id,
                        name: saleItem.seller_name
                    });
                }
            }

            this.originalSummary.push({
                sale: sale,
                amount: sale.final_amount || 0,
                exits: exits,
                sentExits: sentExits,
                seller_id: sale.seller_id,
                seller_name: sale.seller_name
            });

            totalAmount += sale.final_amount || 0;
            totalCovers += sale.covers || 0;
        }

        this.totalAmount.set(totalAmount);
        this.totalCovers.set(totalCovers);

        this.summary = [...this.originalSummary];
        this.sellers = [...sellers.values()];
    }

    cancel() {
        this.matDialogRef.close();
    };

    onRoomSelect(event: MatSelectionListChange) {
        const roomsList = event.source;

        if (event.options[0].value == 'null') {
            roomsList.deselectAll();
        }

        this.selectedRooms = roomsList.selectedOptions.selected.map(room => room.value).filter(room => room.value !== 'null');
        this.hasSelectedAllRooms = !this.selectedRooms.length || new Set(this.selectedRooms).size === new Set(this.rooms).size;

        if (this.hasSelectedAllRooms) {
            this.selectedRooms = [];
            roomsList.deselectAll();
        }

        this.applyFilter();
    }

    onRoomMobileSelect(event: MatSelectChange) {
        const rooms = event.value;

        if (Array.isArray(rooms) && rooms[0] === 'select-all') {
            this.selectedRooms = this.data.rooms;
        } else {
            this.selectedRooms = rooms.length > 0 ? rooms : [];
        }

        this.applyFilter();
    }

    onSellerSelect(event: MatSelectionListChange) {
        const sellerList = event.source;
        this.selectedSellers = sellerList.selectedOptions.selected.map(seller => seller.value);

        this.applyFilter();
    }

    onSellerMobileSelect(event: MatSelectChange) {
        this.selectedSellers = event.value;
        this.applyFilter();
    }

    applyFilter() {
        let totalAmount = 0;
        let totalCovers = 0;

        const filteredSummary: SummaryType[] = [];

        // Get the target rooms
        const targetRooms = new Set((this.selectedRooms.length ? this.selectedRooms : this.rooms).map(room => room.id!));

        for (const record of this.originalSummary) {
            const sale = record.sale;

            // Skip if the room is not selected
            if (!targetRooms.has(sale.room_id!)) {
                continue;
            }

            if (this.selectedSellers.length > 0) {
                const saleItemsBySeller = groupBy(sale.sale_items || [], el => el.seller_id);
                let hasSeller = false;

                for (const seller of this.selectedSellers) {
                    const sellerItems = saleItemsBySeller[seller.id];

                    if (!sellerItems) {
                        continue;
                    }

                    hasSeller = true;

                    const amount = sellerItems.reduce((total, si) => total += (si.final_price * si.quantity), 0);

                    filteredSummary.push({
                        ...record,
                        amount: amount,
                        seller_id: seller.id,
                        seller_name: seller.name
                    });

                    totalAmount += amount;
                }

                if(hasSeller) {
                    totalCovers += sale.covers || 0;
                }
            } else {
                filteredSummary.push(record);
                totalAmount += record.amount;
                totalCovers += sale.covers || 0;
            }
        }

        // Update the summary
        this.totalAmount.set(totalAmount);
        this.totalCovers.set(totalCovers);
        this.summary = filteredSummary;
    }

    getDataOpenAt(value: Date) {
        const openAt = this.tilbyDatePipe.transform(value, 'dd/MM');
        return (openAt !== new Date().toDateString()) ? ` ${openAt}` : '';
    }

    async openDetails() {
        const printer = await this.openDialogsService.openGeneralDocumentPrinterSelectorDialog({
            data: { titleLabel: 'APPLICATION.ORDER_PRINTER.TITLE' },
            disableClose: true
        });

        const printerId = printer?.id;

        if (!printerId) {
            return;
        }

        const documentToPrint = this.getDocument(printer.columns);

        try {
            await this.documentPrinterService.printFreeNonFiscal(documentToPrint, printerId);
        } catch (error: any) {
            this.printerErrorFiscalService.openDialog({ data: { error, options: { printerId } } });
        }
    }

    private getDocument(columns?: number) {
        const sales = new Set(this.summary.map(s => s.sale));

        return this.reportGenerator.generateReport([...sales], { columns: columns });
    }

    private getPdfDocument(): TDocumentDefinitions {
        const document = this.getDocument();

        return {
            content: [
                document
            ],
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10
            }
        };
    };

    private downloadPdf() {
        const document = this.getPdfDocument();

        pdfMake.createPdf(document).download(`${this.getFileName()}.pdf`);
    }

    private getFileName() {
        return `tables_report_${this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd')}`;
    }

    private async share() {
        if (!this.canShare) {
            return;
        }

        const document = await this.getPdfDocument();
        const dataUri: string = await new Promise((resolve) => pdfMake.createPdf(document).getBase64(resolve));
        const name = this.getFileName();

        window.plugins.socialsharing.shareWithOptions({
            message: name, // not supported on some apps (Facebook, Instagram)
            subject: name, // fi. for email
            files: ['data:application/pdf;base64,' + dataUri], // an array of filenames either locally or remotely
        }, () => { }, (error: any) => { });
    };

    private async downloadFile() {
        const content = await this.getDocument();

        downloadFile(content, `${this.getFileName()}.txt`, 'text/plain');
    }
}

@Injectable({
    providedIn: 'root',
})
export class SummaryOfOccupiedTablesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    public openDialog(config: NonNullableConfigData<SummaryOfOccupiedTablesDialogData>) {
        const data: SummaryOfOccupiedTablesDialogData = config?.data;
        const configEdited: NonNullableConfigData<SummaryOfOccupiedTablesDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ width: '900px', height: '710px' }, { fullScreenForMobile: true }),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(SummaryOfOccupiedTablesDialog, configEdited).afterClosed()
        );
    }
}