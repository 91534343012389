import angular from 'angular';
import $ from 'jquery';
import * as moment from 'moment-timezone';

import {
    SpiaggeItApiService
} from 'app/modules/application/service/spiagge-it-api/spiagge-it-api';

import {
    ConfigurationManagerService
} from 'src/app/core';

import {
    BedzzleCommonFolio
} from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';

import {
    MathUtils
} from 'src/app/shared/utils';

type SpiaggeItPaymentsDialogInput = {
    amount: number,
    paymentMethodId: number
};

class SpiaggeItPaymentsController {
    private folios: BedzzleCommonFolio[] = [];

    protected amountToPay: number;
    protected paymentMethodId: number;
    protected fetchingFolios = false;
    protected isOffline = false;
    protected message = '';
    protected needsSetup = false;
    protected operationInProgress = false;
    protected propertyId = '';
    protected selectedFolio?: BedzzleCommonFolio;
    protected searchText = '';
    protected showAmount = true;
    protected showBalance = true;
    protected visibleFolios: BedzzleCommonFolio[] = [];

    constructor(
        private $scope: any,
        options: SpiaggeItPaymentsDialogInput,
        private $timeout: any,
        private $translate: any,
        private $filter: any,
        private spiaggeItApi: SpiaggeItApiService,
        configurationManagerService: ConfigurationManagerService,
        private $mdDialog: any
    ) {
        this.amountToPay = MathUtils.round(options.amount);
        this.paymentMethodId = options.paymentMethodId;
        this.showAmount = !(configurationManagerService.getPreference('pms_payments.hide_total_amount'));
        this.showBalance = !(configurationManagerService.getPreference('pms_payments.hide_balance'));

        this.$scope.$on("connection:changed", (_: any, data: any) => {
            this.isOffline = data.status === 'offline';
        });

        this.$timeout(() => {
            this.fetchFolios();
        });
    }

    async pairShop(): Promise<void> {
        if (!this.propertyId) {
            return;
        }

        this.operationInProgress = true;
        this.message = this.$translate.instant('CASHREGISTER.SPIAGGE_IT_PAYMENTS.ENABLING');

        try {
            await this.spiaggeItApi.setupShop(this.paymentMethodId, this.propertyId);

            this.message = this.$translate.instant('CASHREGISTER.SPIAGGE_IT_PAYMENTS.ENABLE_SUCCESSFUL');
            this.needsSetup = false;
            window.setTimeout(() => this.deleteMessage(), 1500);

            this.fetchFolios();
        } catch (error: any) {
            $('#SpiaggeItPropertyIdField').trigger('focus');

            switch (error?.status) {
                case -1:
                    this.message = '';
                    break;
                case 404: case 403:
                    this.message = this.$translate.instant('CASHREGISTER.SPIAGGE_IT_PAYMENTS.INVALID_PROPERTY_ID');
                    break;
                default:
                    this.message = this.$translate.instant('CASHREGISTER.SPIAGGE_IT_PAYMENTS.ENABLE_FAILED');
                    break;
            }
        } finally {
            this.operationInProgress = false;
        }
    }

    deleteMessage(): void {
        this.message = '';
    }

    isMiscSale(folio: BedzzleCommonFolio): boolean {
        return folio.stayId == null;
    }

    parseSpiaggeItDate(date: string): string {
        return moment(date, 'YYYY-MM-DD').format('L');
    }

    performSearch(): void {
        this.visibleFolios = this.folios;

        for (const word of this.searchText.split(/\W+/g)) {
            this.visibleFolios = this.$filter('filter')(this.visibleFolios, word);
        }

        if (!this.visibleFolios.find((folio: BedzzleCommonFolio) => folio === this.selectedFolio)) {
            this.selectedFolio = undefined;
        }
    }

    private async fetchFolios(): Promise<void> {
        if (this.$scope.$$destroyed) {
            return;
        }

        this.fetchingFolios = true;

        try {
            this.folios = await this.spiaggeItApi.getRoomFolios(this.paymentMethodId).then((res) => res.folios.sort((a, b) => a.keyDoorCode! > b.keyDoorCode! ? 1 : -1));
            this.visibleFolios = this.folios;
        } catch (error: any) {
            if (error?.status) {
                switch (error.status) {
                    case -1:
                        this.$timeout(this.fetchFolios.bind(this), 1000);
                        break;
                    case 403:
                        this.needsSetup = true;
                        break;
                    default:
                        if (error.data?.error?.message?.error_code === 'CONNECTION_ERROR') {
                            this.$mdDialog.cancel('SPIAGGE_IT_OFFLINE');
                        } else {
                            this.$mdDialog.cancel('UNKNOWN_ERROR');
                        }
                        break;
                }
            } else {
                this.$mdDialog.cancel('UNKNOWN_ERROR');
            }
        } finally {
            this.fetchingFolios = false;
        }
    }

    cancel(): void {
        this.$mdDialog.cancel('CANCELED');
    }

    selectFolio(folio: BedzzleCommonFolio): void {
        this.selectedFolio = folio;
    }

    confirm(): void {
        if (this.selectedFolio) {
            this.$mdDialog.hide(this.selectedFolio);
        }
    }

    newMiscSaleFolio(): void {
        this.$mdDialog.hide({});
    }
}

SpiaggeItPaymentsController.$inject = [
    "$scope",
    "options",
    "$timeout",
    "$translate",
    "$filter",
    "spiaggeItApi",
    "checkManager",
    "$mdDialog"
];

export class SpiaggeItPaymentsService {
    constructor(
        private $mdDialog: any
    ) {
    }

    public show(options: SpiaggeItPaymentsDialogInput): Promise<BedzzleCommonFolio> {
        return this.$mdDialog.show({
            controller: SpiaggeItPaymentsController,
            controllerAs: '$ctrl',
            template: require('./spiagge-it-payments.html'),
            locals: {
                options: options
            }
        });
    }
}

SpiaggeItPaymentsService.$inject = [
    "$mdDialog"
];

angular.module('cashregister').service('spiaggeItPayments', SpiaggeItPaymentsService);