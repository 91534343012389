import {
    Component,
    effect,
    inject,
    Injector,
    Input
} from '@angular/core';

import {CommonModule} from '@angular/common';

import {
    Channels,
    PaymentMethods,
    SalesPayments,
    Tickets,
    TicketsValues
} from 'tilby-models';

import {ToolbarEventsContextService} from 'src/app/core/services/toolbar-events/toolbar-events-context.service';
import {
    addSelectCustomerDialog,
    digitalPaymentsManager,
    promptDialog,
    restManager
} from 'app/ajs-upgraded-providers';
import {CashregisterKeypadComponent, TotalFormValue} from "../cashregister-keyboard";
import {DevLogger} from 'src/app/shared/dev-logger';
import {TicketsValuesSortedComponent} from "./tickets-values-sorted/tickets-values-sorted.component";
import {
    ActiveSaleService,
    ActiveSaleStoreService,
    CashregisterStateService
} from 'src/app/features/cashregister/services';
import {
    AlertDialogService,
    ConfirmDialogService,
    DocumentPrintersManagerDialogStateService,
    DocumentPrintersManagerForm,
    GiftcardDialogService,
    OpenDialogsService
} from 'src/app/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {
    BarcodeManagerService,
    EntityManagerService,
    EnvironmentInfoService,
    ScreenOrientationService,
    UserActiveSessionManagerService
} from 'src/app/core';
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyRadioInputComponent
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {ReactiveFormsModule, Validators} from '@angular/forms';
import {TilbyKeypadDisplayComponent} from "../cashregister-keyboard/tilby-keypad-display";
import {CARD_CIRCUITS} from 'src/app/core/constants/card-circuits';
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {mobileCheck, subscribeInComponent} from '@tilby/tilby-ui-lib/utilities';
import {distinctUntilChanged, pairwise } from 'rxjs';

type AddPaymentToSaleOptions = {
    amount: number,
    code?: string,
    paymentInputObject?: Partial<SalesPayments>
};

type PaymentMethodsFE = PaymentMethods & {$disabled:boolean,$info:string,color?:string};
@Component({
  selector: 'app-cashregister-payments',
  standalone: true,
  providers: [OnDestroyService],
  imports: [
    CommonModule,
    CashregisterKeypadComponent,
    TicketsValuesSortedComponent,
    TilbyKeypadDisplayComponent,
    TilbyRadioInputComponent,
    ReactiveFormsModule
],
  templateUrl: './cashregister-payments.component.html',
  styleUrls: ['./cashregister-payments.component.scss'],
  host:{
      class: 'tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 tw-w-full tw-h-full tw-gap-3 tw-place-items-center'
    }
})
export class CashregisterPaymentsComponent {
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly digitalPaymentsManagerService = inject(digitalPaymentsManager);
    private readonly documentPrintersManagerDialogStateService = inject(DocumentPrintersManagerDialogStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly giftcardDialogService = inject(GiftcardDialogService);
    private readonly injector = inject(Injector);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogService = inject(OpenDialogsService);
    private readonly promptDialogService: any = inject(promptDialog);
    private readonly restManagerService = inject(restManager);
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly translateService = inject(TranslateService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
 
    @Input({ required: true }) ticketsCollection: Tickets[] = [];
    @Input({ required: true }) paymentMethods: PaymentMethodsFE[] = [];

    private cashValueDefault = '0,00';

    protected totalValueForm = new CustomFormGroup<CustomForm<TotalFormValue>>({
        cashValue: new CustomFormControl(this.cashValueDefault, { validators: Validators.pattern(/^\d+(,\d{2})?$/) }, { ...new CustomFormControlProps(), label: 'Total', matElementClass: "tw-align-sub tw-text-right", readonly: true })
    });

    protected documentPrinterValueForm?: DocumentPrintersManagerForm;
    get cashValueController() {
        return this.totalValueForm.controls.cashValue;
    }
    get cashValue() {
        return this.cashValueController.value;
    }

    protected ticketActive = this.cashregisterStateService.ticketViewActive;
    protected selectedTicketCircuit?: Tickets;
    private ticketPaymentMethod?: PaymentMethods;

    protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;
    protected isMobile = mobileCheck();
    private log(...args:any[]){
        DevLogger.log('CASHREGISTER_PAYMENTS_COMPONENT',...args)
}
    // START - LIFECYCLE
    constructor() {
        effect(()=>this.setContextToolbar(this.ticketActive()?'tickets':'payments'));
        subscribeInComponent(
            ActiveSaleStoreService.saleUpdates$.pipe(distinctUntilChanged(),pairwise()),
            ([oldSale, newSale]) => {
                if(oldSale?.currentSale?.sale_customer?.uuid !== newSale?.currentSale?.sale_customer?.uuid){
                    this.checkPrepaidCardStatus();
                }
            }
        )
    }
    async ngOnInit(){
        this.createToolbarButtons();
        await this.checkPrepaidCardStatus();
        this.setContextToolbar('payments');

        //Configure Tickets
        this.selectedTicketCircuit = this.ticketsCollection[0];
        this.ticketPaymentMethod = this.paymentMethods.find(method => method.payment_method_type_id === 6);

        await this.showSelectPrinterDocument();
    }
    // END - LIFECYCLE

    private async showSelectPrinterDocument() {
        this.documentPrinterValueForm = await this.documentPrintersManagerDialogStateService.beforeOpenDialog(this.activeSaleService.printerDocumentData!);

        ActiveSaleService.printerDocumentDataUpdates$.pipe(this.onDestroyService.takeUntilDestroy).subscribe((newDocumentData) => {
            this.documentPrinterValueForm?.controls.document.setValue(newDocumentData.document_template?.id || newDocumentData.document_type.id, { emitEvent: false });
        });

        this.documentPrinterValueForm.controls.document.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(async selectedDocumentId => {
            if (selectedDocumentId === undefined) {
                return;
            }

            const { document_type: selectedDocument } = this.documentPrintersManagerDialogStateService.documentControlChanges(selectedDocumentId) || {};
            const documentData = this.documentPrintersManagerDialogStateService.confirm({ document: selectedDocumentId });

            this.activeSaleService.setPrinterDocumentData({
                ...documentData,
                options: this.documentPrinterValueForm?.value.options || {}
            });

            let { sale_customer: customer } = this.activeSaleService.currentSale;
            const needsCustomer = selectedDocument?.id.includes('invoice') && !customer;

            if (needsCustomer) {
                customer = await this.injector.get(addSelectCustomerDialog).show(this.activeSaleService.currentSale.sale_customer);

                if (customer) {
                    await this.cashregisterStateService.addCustomer(customer);
                }
            }
        });
    };

    protected onFieldChange(totalChanged: string) {
        this.cashValueController.setValue(totalChanged);
    }

    private createToolbarButtons() {
        this.toolbarEventsContextService.backButton$.next({
            isIt: () => true,
            name: 'arrow_back',
            icon: () => 'arrow_back',
            click: () => this.back()
        });
    }

    private setContextToolbar(module:"payments"|"tickets"){
        const title=module=="payments"?"CASHREGISTER.PAYMENTS.TITLE":"CASHREGISTER.PAYMENTS.FOOD_STAMPS_TITLE";
        this.toolbarEventsContextService.label=title;
        this.toolbarEventsContextService.buttons$.next({panelButtons: [], barButtons: [{
                isIt: () => this.environmentInfoService.isCameraBarcodeSupported(),
                iconType: 'symbols',
                name: 'barcode_scanner',
                icon: ()=>'barcode_scanner',
                click: () =>  this.openBarcodeAndSearch()
            }]});

    }

    private back() {
        this.cashregisterStateService.back();
    }

    //Check Prepaid Card status
    private async checkPrepaidCardStatus() {
        const prepaidCardMethod = this.paymentMethods.find(({ payment_method_type_id })=>payment_method_type_id== 16 );

        if(prepaidCardMethod) {
            await this.activeSaleService.checkPrepaidPaymentStatus(prepaidCardMethod);
        }
    }

    protected selectTicketCircuit(ticket: Tickets) {
        this.log('SELECT_TICKET_CIRCUIT',ticket);
        this.selectedTicketCircuit=ticket;
    }
    protected async addNewTicket() {
        const newTicketPrice = await this.promptDialogService.show({ type: 'number', title: this.translateService.instant('CASHREGISTER.ADD_NEW_TICKET.TITLE'), label: this.translateService.instant('CASHREGISTER.ADD_NEW_TICKET.AMOUNT') });
        const ticket = structuredClone(this.selectedTicketCircuit);

        const ticketValue = {
            value: newTicketPrice
        };

        ticket?.ticket_values?.push(ticketValue);
        this.selectTicketValue(ticketValue);

        if(ticket){
            await this.entityManagerService.tickets.putOneOfflineFirst(ticket)
            this.selectedTicketCircuit = structuredClone(ticket);
        }
    }
    protected selectTicketValue(ticketValue: TicketsValues) {
        if(!this.ticketPaymentMethod) {
            return;
        }

        this.addPaymentToSale(this.ticketPaymentMethod, { 
            amount: ticketValue.value,
            paymentInputObject: {
                ticket_circuit: this.selectedTicketCircuit?.circuit,
                ticket_id: this.selectedTicketCircuit?.id,
                ticket_name: this.selectedTicketCircuit?.name
            }
        });
    }
    protected async removeTicketValue(ticketValue: TicketsValues) {
        const ticket = structuredClone(this.selectedTicketCircuit);

        if (!ticket) {
            return;
        }

        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'CASHREGISTER.PAYMENTS.WANT_TO_REMOVE_FROM_CIRCUIT_TICKET'
            } 
        });

        if (!answer) {
            return;
        }

        ticket.ticket_values = ticket.ticket_values?.filter((v) => v.value !== ticketValue.value);
        await this.entityManagerService.tickets.putOneOfflineFirst(ticket);
        this.selectedTicketCircuit = ticket;
    }

    private isCashDrawer = (paymentMethod:PaymentMethods) => [19, 21].includes(paymentMethod.payment_method_type_id);
    private isPMS = (paymentMethod:PaymentMethods) => [22, 23, 28, 29].includes(paymentMethod.payment_method_type_id);

    protected async addPaymentToSale(paymentMethod: PaymentMethods, options?: AddPaymentToSaleOptions) {

        const addGenericPaymentToSale = async () => {
            try {
                await this.activeSaleService.addGenericPayment(paymentMethod, paymentAmount, paymentInputObject);
            } catch (error: any) {
                this.alertDialogService.openDialog({ data: { messageLabel: error } });
                throw error;
            }
        }

        const amountFromKeyboard = Number.parseFloat(this.cashValue.replace(',', '.'));

        let paymentAmount = options?.amount || amountFromKeyboard;
        let paymentInputObject: Partial<SalesPayments> | undefined;

        if (paymentAmount === 0) {
            if (paymentMethod.payment_method_type_id === 6) { // Ticket view
                this.ticketActive.set(true);
                return;
            } else {
                paymentAmount = this.activeSaleService.getToPay();
            }
        }

        // Check if payment is necessary
        if (
            (this.activeSaleService.getToPay() <= 0 && !this.isPMS(paymentMethod)) ||
            (this.isCashDrawer(paymentMethod) && (paymentAmount || 0) > this.activeSaleService.getToPay())
        ) {
            return this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'CASHREGISTER.PAYMENTS.AMOUNT_NOT_NECESSARY'
                }
            });
        }

        switch (paymentMethod.payment_method_type_id) {
            case 4: { //Credit Cards
                const circuit = await this.openDialogService.openGenericListDialog({
                    data: {
                        title: { label: 'CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_CARD' as any },
                        list: CARD_CIRCUITS
                    }
                });

                paymentInputObject = {
                    card_circuit_id: circuit?.id,
                    card_circuit_name: circuit?.name
                };

                addGenericPaymentToSale();
                break;
            }
            case 6: { // Tickets
                paymentInputObject = options?.paymentInputObject;

                if (!paymentInputObject) {
                    const ticket = await this.openDialogService.openGenericListDialog({
                        data: {
                            title: { label: 'CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_TICKET' as any },
                            list: <Array<Tickets & { name: string }>>this.ticketsCollection
                        }
                    });

                    paymentInputObject = {
                        ticket_circuit: ticket?.circuit,
                        ticket_id: ticket?.id,
                        ticket_name: ticket?.name,
                        code: options?.code
                    };
                }

                await addGenericPaymentToSale();
                break;
            }
            case 16: //Prepaid Cards
            case 24: { //Deferred Invoice
                if (!this.activeSaleService.currentSale.sale_customer) {
                    try {
                        const customer = await this.injector.get(addSelectCustomerDialog).show(this.activeSaleService.currentSale.sale_customer);

                        if(!customer) {
                            throw 'NO_CUSTOMER_CONFIGURED';
                        };
                        
                        await this.cashregisterStateService.addCustomer(customer);
                    } catch (e) {
                        this.alertDialogService.openDialog({
                            data: { messageLabel: this.translateService.instant('CASHREGISTER.PAYMENTS.NO_CUSTOMER_CONFIGURED', { name: paymentMethod.name }) } });
                        return;
                    }
                }

                const isPrepaidCards = paymentMethod.payment_method_type_id == 16;
                const currentSale = this.activeSaleService.currentSale;
                const prepaidCredit = (currentSale.sale_customer?.$prepaidCredit || (await this.activeSaleService.getCustomerPrepaidInfo())?.total_credit || 0);
                const alreadyPaidWithPrepaidCardMethod = currentSale.payments?.find(payment => payment.payment_method_type_id == 16);

                if (isPrepaidCards && alreadyPaidWithPrepaidCardMethod) {
                    this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.PREPAID_CREDIT_IS_ALREADY_PRESENT' } });
                    return;
                }

                paymentAmount = amountFromKeyboard || this.activeSaleService.getToPay();

                if (isPrepaidCards && paymentAmount > prepaidCredit) {
                    paymentAmount = prepaidCredit; // return prepaid credit
                }

                if(paymentAmount <= 0) {
                    return;
                }

                await addGenericPaymentToSale();
                break;
            }
            case 17: { //Delivery
                if (this.activeSaleService.currentSale.channel && this.activeSaleService.currentSale.channel !== 'pos') {
                    paymentInputObject = {
                        payment_data: this.activeSaleService.currentSale.channel
                    };
                } else {
                    const channels = this.cashregisterStateService.deliveryChannels.map((channel) => <Channels & { icon: string }>{
                        ...channel,
                        icon: channel.image_url || `assets/images/channels/${channel.id}.png`
                    });

                    const circuit = await this.openDialogService.openGenericListDialog({
                        data: {
                            title: { label: 'CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_DELIVERY' as any },
                            list: channels
                        }
                    });

                    paymentInputObject = {
                        payment_data: circuit?.id
                    };
                }

                await addGenericPaymentToSale();
                break;
            }
            case 22: case 23: case 28: case 29: case 36: case 39: case 41: { //Bedzzle/Beddy/Lean PMS/Spiagge.it
                if (this.activeSaleService.currentSale.payments?.length) {
                    return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.MIXED_PAYMENTS_NOT_SUPPORTED' } });
                }

                if (this.activeSaleService.currentSale.sale_items?.some((saleItem) => saleItem.quantity < 0)) {
                    return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.REFUNDS_NOT_ALLOWED' } });
                }

                paymentAmount = this.activeSaleService.getToPay();
                await addGenericPaymentToSale();
                break;
            }
            case 33: { //Gift cards
                const giftCardCode = await this.giftcardDialogService.openDialog();

                if (!giftCardCode) {
                    return;
                }

                const giftCards = await this.restManagerService.getList('giftcards', { code: giftCardCode });

                if (!giftCards.length) {
                    return this.alertDialogService.openDialog({ data: { messageLabel: "GIFT_CARD.PAYMENT_DIALOG.NOT_FOUND" } });
                }

                const giftCard = giftCards[0];

                if (!giftCard.active) {
                    return this.alertDialogService.openDialog({ data: { messageLabel: "GIFT_CARD.PAYMENT_DIALOG.GIFTCARD_DISABLED" } });
                }

                if (giftCard.origin_environment_only && this.userActiveSessionManagerService.getSession()?.shop.name !== giftCard.origin_environment) {
                    return this.alertDialogService.openDialog({ data: { messageLabel: "GIFT_CARD.PAYMENT_DIALOG.ORIGIN_ENVIRONMENT_ONLY" } });
                }

                const credit = giftCard.last_value ?? giftCard.initial_value;

                this.activeSaleService.updateSaleDetails({
                    payments: this.activeSaleService.currentSale.payments?.filter(payment => !(payment.payment_method_type_id === 33 && payment.code === giftCard.code)) || []
                });

                paymentAmount = Math.min(paymentAmount || 0, this.activeSaleService.getToPay(), credit);
                paymentInputObject = {
                    code: giftCard.code,
                    payment_data: JSON.stringify({ giftcard_uuid: giftCard.uuid })
                };

                await addGenericPaymentToSale();
                break;
            }
            default: {
                if (this.digitalPaymentsManagerService.isPaymentDigital(paymentMethod.payment_method_type_id)) { // Digital payments
                    if (!this.digitalPaymentsManagerService.isPaymentDigitalEnvironmentAllowed(paymentMethod.payment_method_type_id)) {
                        return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.METHOD_NOT_AVAILABLE_ON_THIS_DEVICE' } });
                    }

                    const digitalPayment = this.activeSaleService.currentSale.payments?.find((payment) => !payment.paid && this.digitalPaymentsManagerService.isPaymentDigital(payment.payment_method_type_id));

                    if (digitalPayment) {
                        return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.POS_PAYMENT_PRESENT' } });
                    }
                }

                await addGenericPaymentToSale();
                break;
            }
        }

        this.cashValueController.setValue(this.cashValueDefault);
    }
    // START - BARCODE
    private async openBarcodeAndSearch() {
        const barcode = await this.barcodeManagerService.openCameraBarcode();

        if (barcode) {
            this.onSubmitBarcode(barcode);
        }
    }
    private onSubmitBarcode(barcodeInput: string) {
        if (!this.ticketPaymentMethod) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.PAYMENTS.NO_TICKET_CONFIGURED' } });
            return;
        }

        const ticketValue = this.barcodeManagerService.parseTicket(barcodeInput);

        if (!ticketValue) {
            return;
        }

        this.addPaymentToSale(this.ticketPaymentMethod, { amount: ticketValue, code: barcodeInput });
    };
    //END - BARCODE
}
