import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Injectable, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { lastValueFrom } from 'rxjs';

export type ItemListSelectorData = { message: string, items: ItemListSelectorElement[]; options: ItemListSelectorOptions | undefined };

export type ItemListSelectorElement = {
    id: any,
    name: string
}

export type ItemListSelectorOptions = {
    disableSetAsDefault?: boolean,
    multiple?: boolean,
    defaultElement?: any,
    itemIcon?: string
};

@Component({
    selector: 'item-list-selector-dialog',
    templateUrl: './item-list-selector-dialog.component.html',
    styleUrl: './item-list-selector-dialog.component.scss',
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule, 
        MatSelectModule,
        MatCardModule,
        MatSlideToggleModule,
        MatIconModule,
        MatDividerModule,
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        TranslateModule,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        CdkVirtualScrollViewport,
        FormsModule
    ]
})
export class ItemListSelectorDialogComponent {
    protected readonly data: ItemListSelectorData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    
    message: string = this.data.message;
    items = this.data.items;
    itemIcon = this.data.options?.itemIcon;
    canSetAsDefault: boolean = this.data.options?.disableSetAsDefault || this.data.options?.multiple ? false : true;
    multiple: boolean = this.data.options?.multiple ? true : false;
    selectedItems: any = {};
    setAsDefault: boolean = false;
    calculatedHeight: string = "";
    @ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewport!: CdkVirtualScrollViewport;

    ngAfterViewInit(): void {
        this.calculateContainerHeight();

        setTimeout(() => {
            this.cdkVirtualScrollViewport.checkViewportSize();
        }, 300);
    }

    calculateContainerHeight() {
        const numberOfItems = this.data.items.length;
        const itemHeight = 50;
        const visibleItems = 8;

        if (numberOfItems <= visibleItems) {
            this.calculatedHeight = `${itemHeight * numberOfItems}px`;
        } else {
            this.calculatedHeight = `${itemHeight * visibleItems}px`;
        }
    }

    protected selectPayment(element: ItemListSelectorElement){
        if(this.multiple) {
            this.selectedItems[element.id] = !this.selectedItems[element.id];
        } else {
            this.matDialogRef.close({
                element: element,
                setAsDefault: this.setAsDefault
            });
        }
    };

    confirmMultiple() {
        const selectedItems = this.items.filter((item: ItemListSelectorElement) => this.selectedItems[item.id]);

        if(selectedItems.length) {
            this.matDialogRef.close({
                elements: selectedItems
            });
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class ItemListSelectorDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<ItemListSelectorData>) {
        const data: ItemListSelectorData = config?.data||{ message: '', items: [], options: {} };
        const configEdited: NonNullableConfigData<ItemListSelectorData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width: '320px'}, {fullScreenForMobile: true}),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(ItemListSelectorDialogComponent, configEdited).afterClosed()
        );
    }
}
