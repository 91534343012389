import angular from 'angular';

import {
    SatispayApiService
} from 'app/modules/application/service/satispay-api/satispay-api';

import {
    SatispayPaymentsService
} from 'app/modules/cashregister/service/dialog/satispay-payments/satispay-payments';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import {
    DocumentPrinterOptions,
    DocumentPrintHook
} from 'src/app/shared/model/document-printer.model';

import {
    Sales
} from 'tilby-models';

export class SatispayService implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private satispayPayments: SatispayPaymentsService,
        private satispayApi: SatispayApiService
    ) {
    }

    private buildTail(response?: any): string {
        if (!response) {
            return "";
        }

        const tailLines = [
            'SATISPAY'.padEnd(46, ' '),
            "",
        ];

        if (response.sender) {
            tailLines.push(`NAME:   ${response.sender.name}`);
        }

        tailLines.push(
            `STATUS: ${response.status}`,
            `AMOUNT: ${(response.amount_unit / 100).toFixed(2)}`,
        );

        if (response.sender) {
            tailLines.push(`CID:    ${response.sender.id}`);
        }

        if (response.receiver) {
            tailLines.push(`SHOP:   ${response.receiver.id}`);
        }

        tailLines.push(
            `TID:    ${response.id}`,
            `DATE:   ${response.insert_date}`,
            "",
        );

        return tailLines.join('\n');
    }

    async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        try {
            const payment = await this.satispayPayments.show({
                amount: Math.round(amount * 100),
                paymentMethodId: options.paymentMethod.id
            });

            if(!payment) {
                throw "CANCELED";
            }

            const response = await this.satispayApi.acceptPayment(options.paymentMethod.id, payment.id);

            let responseString = '';

            try {
                responseString = JSON.stringify(response);
            } catch (e) {
            }

            return {
                acquirer_name: "Satispay",
                payment_data: responseString,
                tail: this.buildTail(response),
                unclaimed: false
            };
        } catch (error: any) {
            if (error && error.status) {
                switch (error.status) {
                    case -1:
                        throw "SATISPAY_OFFLINE";
                    case 400:
                    case 401:
                        throw "SATISPAY_UNABLE_TO_ACCEPT_PAYMENT";
                    case 403:
                    case 404:
                        throw "SATISPAY_PAYMENT_NOT_FOUND";
                    default:
                        throw "UNKNOWN_ERROR";
                }
            } else {
                throw typeof error === 'object' ? 'CANCELED' : error;
            }
        }
    }

    isEnabled(): boolean {
        return true; //for now we only need this for the printFail hook, which will determine if actions are necessary
    }

    async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions) {
        const satispayPayments = sale.payments?.filter(p => p.payment_method_type_id === 18);

        if (!satispayPayments?.length) {
            return;
        }

        let result = 'OK';

        for (const payment of satispayPayments) {
            try {
                const sPayment = JSON.parse(payment.payment_data || '{}');

                if (sPayment && sPayment.id) {
                    try {
                        await this.satispayApi.rollbackPayment(payment.payment_method_id, sPayment);
                    } catch (error) {
                        result = 'KO';
                    }
                }
            } catch (e) {
                //Nothing to do
            }
        }

        throw `SATISPAY_ROLLBACK_${result}`;
    }
}

SatispayService.$inject = [
    "satispayPayments",
    "satispayApi"
];

angular.module('digitalPayments').service('satispay', SatispayService);