import angular from 'angular';
import { EricsoftPMSApiService } from 'app/modules/application/service/ericsoft-pms-api/ericsoft-pms-api';
import { EricsoftPMSPaymentsDialog } from 'app/modules/cashregister/service/dialog/ericsoft-pms-payments/ericsoft-pms-payments';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import {
    Sales,
    SalesPayments
} from 'tilby-models';

import { validate as validateUuid } from 'uuid';

import {
    BedzzleCommonTransaction,
    BedzzleCommonTransactionPayment,
    BezzleCommonService
} from '../bedzzle/bedzzle-common';

import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

import { MigrationHelper } from 'app/modules/application/service/migration-helper/migration-helper';

type EricsoftTransaction = BedzzleCommonTransaction & {
    originalTransactionId: string;
}

type EricsoftTransactionPayment = BedzzleCommonTransactionPayment & {
    code: string;
}

export class EricsoftPMSService implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly migrationHelper: MigrationHelper,
        private readonly ericsoftPayments: EricsoftPMSPaymentsDialog,
        private readonly ericsoftPMSApi: EricsoftPMSApiService,
        private readonly bedzzleCommon: BezzleCommonService,
        private readonly entityManagerService: EntityManagerService,
        private readonly userActiveSession: UserActiveSessionManagerService,
    ) {
    }

    private getPaymentMethodCode(payment: SalesPayments): string {
        switch (payment.payment_method_type_id) {
            case 1: case 17: case 19: case 21: case 32: case 38: case 40:
                return '1';
            case 2: case 6: case 20: case 22: case 23: case 26: case 28: case 29: case 34: case 39: case 41:
                return '19';
            case 3:
                return '6';
            case 4: case 5:
                return '18';
            case 8:
                return '5';
            case 10: case 16: case 33:
                return '10';
            case 11: case 13: case 14: case 15: case 18: case 27: case 30: case 31: case 35: case 37:
                return '2';
            case 24: case 25:
                return '20';
            default:
                return '18';
        }
    }

    private mapEricsoftPayments(sale: Sales): EricsoftTransactionPayment[] {
        return (sale.payments || []).filter(p => p.payment_method_type_id != 36).map((p) => {
            return {
                ...this.bedzzleCommon.mapPaymentToTransaction(p),
                code: this.getPaymentMethodCode(p),
            };
        });
    }

    private commonTransactionToEricsoftTransaction(transaction: BedzzleCommonTransaction, sale: Sales, folio: any): EricsoftTransaction {
        return {
            ...transaction,
            payments: this.mapEricsoftPayments(sale),
            originalTransactionId: sale.split_sale_origin_uuid || sale.uuid!,
        }
    }

    private async buildTransaction(folio: any, sale: Sales): Promise<EricsoftTransaction> {
        const baseTransaction = await this.bedzzleCommon.buildTransaction(folio, sale);

        return this.commonTransactionToEricsoftTransaction(baseTransaction, sale, folio);
    }

    private async buildExtTransaction(folio: any, sale: Sales): Promise<EricsoftTransaction> {
        const baseTransaction = await this.bedzzleCommon.buildExtTransaction(folio, sale);

        return this.commonTransactionToEricsoftTransaction(baseTransaction, sale, folio);
    }

    async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const currentSale = options.sale;
        const ericsoftPaymentMethod = options.paymentMethod;
        let folio;

        try {
            folio = await this.ericsoftPayments.show({ amount: amount, paymentMethod: ericsoftPaymentMethod });
        } catch (error) {
            throw (typeof error === 'object') ? 'CANCELED' : error;
        }

        if (options.paymentMethod.require_signature) {
            const activeSaleService = this.migrationHelper.getActiveSale();

            try {
                await activeSaleService.addSignatureToSale();
            } catch (err) {
                throw 'CANCELED';
            }
        }

        if (!folio) {
            throw 'MISSING_FOLIO';
        }

        const ericsoftPMSApiHandler = await this.ericsoftPMSApi.getApiInstance(ericsoftPaymentMethod);

        const transaction = await this.buildTransaction(folio, currentSale);

        try {
            const response = await ericsoftPMSApiHandler.createTransaction(transaction);

            if (response.data.folioId) {
                folio.folioId = response.data.folioId;
                transaction.folioId = response.data.folioId;
            }

            let responseString = '';

            try {
                responseString = JSON.stringify(transaction);
            } catch (err) {
                //Nothing to do
            } finally {
                return {
                    acquirer_name: 'PMS Zucchetti',
                    payment_data: responseString,
                    tail: this.bedzzleCommon.buildTail(folio, currentSale, 'zucchetti_pms'),
                    unclaimed: true
                };
            }
        } catch (error: any) {
            if (!error) {
                throw "ERICSOFT_PMS_OFFLINE";
            }

            if (!(error instanceof Response)) {
                throw "UNKNOWN_ERROR";
            }

            switch (error.status) {
                case 400:
                case 401:
                    throw "ERICSOFT_PMS_UNABLE_TO_COMPLETE_TRANSACTION";
                default:
                    throw "UNKNOWN_ERROR";
            }
        }
    }

    async refund(amount: number, options: DigitalPaymentHandlerOptions): Promise<any> {
        const resultTemplate = {
            acquirer_name: 'PMS Zucchetti',
            payment_data: '{}',
            unclaimed: true,
        };

        const currentSale = options.sale;

        if (!currentSale) {
            return resultTemplate;
        }

        //Make sure that the referenced sale had a ericsoft payment and in that case perform the rollback
        const parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

        if (!parentSaleId) {
            return resultTemplate;
        }

        const parentSale = validateUuid(parentSaleId.toString())
            ? await this.entityManagerService.sales.fetchCollectionOnline({ uuid: parentSaleId, pagination: false }).then((sales) => (sales as Sales[])[0])
            : await this.entityManagerService.sales.fetchOneOfflineFirst(parentSaleId);

        if (!parentSale) {
            return resultTemplate;
        }

        const ericsoftPayment = parentSale.payments?.find((payment) => payment.payment_method_type_id === 36);

        if (!ericsoftPayment) {
            return resultTemplate;
        }

        const paymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(ericsoftPayment.payment_method_id);

        if (paymentMethod?.payment_method_type_id !== 36) {
            return resultTemplate;
        }

        let ericsoftApi;

        try {
            ericsoftApi = await this.ericsoftPMSApi.getApiInstance(paymentMethod);
        } catch (err) {
            return resultTemplate;
        }

        let refundResult = {};

        try {
            const shop = this.userActiveSession.getSession()?.shop;
            refundResult = await ericsoftApi.rollbackTransaction(String(shop?.id), ericsoftApi.getPropertyId(), parentSale.uuid!);
        } catch (err: any) {
            if (err instanceof Response) {
                refundResult = await err.json().catch(() => undefined);
            }
        }

        return {
            ...resultTemplate,
            payment_data: JSON.stringify(refundResult)
        };
    }

    isEnabled(): boolean {
        return true;
    }

    async postPrintHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<void> {
        /*
            1. Sending other sales to Zucchetti PMS must be enabled
            2. The sale must not have a Zucchetti PMS payment
        */
        if (
            !this.configurationManagerService.getPreference('zucchetti_pms.send_other_sales') ||
            sale.payments?.some((payment) => payment.payment_method_type_id == 36)
        ) {
            return;
        }

        const ericsoftPaymentMethod = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 36 }).then((methods) => methods[0]);

        if (!ericsoftPaymentMethod) {
            return;
        }

        this.buildExtTransaction({ folioId: null }, sale).then((transaction) => {
            this.ericsoftPMSApi.getApiInstance(ericsoftPaymentMethod)
                .then((ericsoftApi) => ericsoftApi.createTransaction(transaction));
        });
    }

    async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const ericsoftPayment = sale.payments?.find((payment) => payment.payment_method_type_id === 36);

        if (!ericsoftPayment) {
            return;
        }

        const ericsoftPaymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(ericsoftPayment.payment_method_id);

        if (!ericsoftPaymentMethod) {
            return;
        }

        let result = 'KO';

        try {
            const shop = this.userActiveSession.getSession()?.shop;

            const ericsoftApi = await this.ericsoftPMSApi.getApiInstance(ericsoftPaymentMethod);
            await ericsoftApi.rollbackTransaction(String(shop?.id), ericsoftApi.getPropertyId(), sale.uuid!);

            result = 'OK';
        } catch (err) {
            //Nothing to do
        } finally {
            throw `ERICSOFT_PMS_ROLLBACK_${result}`;
        }
    }
}

EricsoftPMSService.$inject = [
    "checkManager",
    "migrationHelper",
    "ericsoftPayments",
    "ericsoftPMSApi",
    "bedzzleCommon",
    "entityManager",
    "userActiveSession",
];

angular.module('digitalPayments').service('ericsoftPMS', EricsoftPMSService);