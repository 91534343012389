import {
    inject,
    Injectable
} from '@angular/core';

import {
    UserSessionsManagerDialogService
} from 'src/app/dialogs';

import {
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    User
} from 'src/app/models';

export type OperatorUser = {
    id: number
    first_name: string
    last_name: string
    full_name: string
    username: string
};

const userDataToOperatorUser = (userData: User): OperatorUser => ({
    id: userData.id,
    first_name: userData.first_name || '',
    last_name: userData.last_name || '',
    full_name: (userData.first_name && userData.last_name) ? `${userData.first_name} ${userData.last_name}` : userData.username,
    username: userData.username
});

@Injectable({
    providedIn: 'root'
})
export class OperatorManagerService {
    private readonly userSessionsManager = inject(UserSessionsManagerDialogService);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);

    private currentOperator: OperatorUser | undefined;

    constructor(
    ) {
    }

    public async changeOperator(): Promise<void> {
        let userData = await this.userSessionsManager.show({ disableNewUser: true, canDismiss: true });

        if (userData) {
            this.currentOperator = userDataToOperatorUser(userData);
        }
    }

    public getOperatorData(userData?: any): OperatorUser {
        if (!this.currentOperator) {
            this.currentOperator = userDataToOperatorUser(this.userActiveSession.getSession()!);
        }

        if (typeof userData === 'object') {
            return userDataToOperatorUser(userData);
        }

        return structuredClone(this.currentOperator!);
    }
}