import {
    ApiFilterPrefix,
    CustomerFe,
    FilteredQuery,
    GiftcardsFe,
    KeysOfUnion,
    OperatorType,
    QueryPagination,
    SalesFe,
    CashMovementsFe,
    SuppliersFe,
    TableRow,
    StaticTokenFe,
    FileImporterFe,
    FileExporterFe,
    PromotionsFe,
} from 'src/app/shared/model/model';
import {BaseModel, ColumnsDefaultModel} from './columns-default/columns-default.model';
import {ISimpleFilterModelType} from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';
import {DateFilterModel, NumberFilterModel, TextFilterModel} from 'ag-grid-community';
import {SetFilterModel} from 'ag-grid-community/dist/lib/interfaces/iSetFilter';
import { CustomUIFilter } from '../../../model/grid-custom-filter.model';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { Feature } from 'src/app/models';
import { DevLogger } from 'src/app/shared/dev-logger';

type XQueryFilterOrder = ((QueryPagination | FilteredQuery) & { [prop in KeysOfUnion<TableRow>]?: any & TableRow } & { x_query?: string });

const GridStateDefault = new Map(Object.keys(ColumnsDefaultModel).flatMap((feature) => [
    // [`grid.${feature}.tablet.landscape.columns`, ColumnsDefaultModel[feature].tablet_landscape_columns],
    // [`grid.${feature}.tablet.portrait.columns`, ColumnsDefaultModel[feature].tablet_portrait_columns],
    // [`grid.${feature}.mobile.landscape.columns`, ColumnsDefaultModel[feature].mobile_landscape_columns],
    // [`grid.${feature}.mobile.portrait.columns`, ColumnsDefaultModel[feature].mobile_portrait_columns],
    [`grid.${feature}.tablet.columns`, (<BaseModel>(ColumnsDefaultModel[feature as keyof ColumnsDefaultModel])).tablet_columns],
    [`grid.${feature}.mobile.columns`, (<BaseModel>(ColumnsDefaultModel[feature as keyof ColumnsDefaultModel])).mobile_columns]
]));

export default class GridUtils {

    private getFieldsByFeature(feature: Feature): string[] | undefined {
        switch (feature) {
            case 'customers':
                return Object.getOwnPropertyNames(new CustomerFe());
            case 'suppliers':
                return Object.getOwnPropertyNames(new SuppliersFe());
            case 'history_sales':
                return Object.getOwnPropertyNames(new SalesFe());
            case 'giftcards':
                return Object.getOwnPropertyNames(new GiftcardsFe());
            case 'settings_um_static_token':
                return Object.getOwnPropertyNames(new StaticTokenFe());
            case 'history_cash_movements':
                return Object.getOwnPropertyNames(new CashMovementsFe());
            case 'file_importer':
                return Object.getOwnPropertyNames(new FileImporterFe())
            case 'file_exporter':
                return Object.getOwnPropertyNames(new FileExporterFe())
            case 'promotions':
                return Object.getOwnPropertyNames(new PromotionsFe());
        }
    }

    getConfiguration(type: string, feature: Feature, index = -1): string {
        DevLogger.debug('[ GridUtils.getConfiguration ]', type, feature, index, GridStateDefault, GridStateDefault.get(type));
        let configurationParse = GridStateDefault.get(type);
        let fields = this.getFieldsByFeature(feature);
        fields?.forEach((element) => {
            let value = configurationParse?.filter(c => c.colId === element);
            if (value && value.length > 0) {
                index = configurationParse?.indexOf(value[0]) || index;
            }
            if (configurationParse && configurationParse?.filter(c => c.colId === element).length <= 0) {
                configurationParse?.splice(++index, 0, {'colId': element, 'hide': true});
            }
        });
        return JSON.stringify(configurationParse);
    }
}

export const filterType = (k: string, v: any, formatter: any) => {
    const filterType = typeof v === 'object'
        ? v instanceof Date
            ? 'date'
            : v?.type
        : typeof v === 'boolean'
            ? 'set'
            : typeof v === 'string'
                ? 'text'
                : 'number'

    const commonFilterParams = ['equals', 'blank', 'notBlank'] as Partial<ISimpleFilterModelType>[];
    return {
        filter: `ag${filterType?.toTitleCase()}ColumnFilter`,
        filterParams: {
            ...(filterType === 'text' && {filterOptions: ['contains', ...commonFilterParams] as Partial<ISimpleFilterModelType>[]}),
            ...((filterType === 'number' || filterType === 'date') && {filterOptions: [...commonFilterParams, 'inRange', 'lessThanOrEqual', 'greaterThanOrEqual'] as Partial<ISimpleFilterModelType>[]}),
            ...(filterType === 'set' && {values: typeof v === 'object'
                    ? (params: any) => v.getUIFilter(params)
                    : (params: any) => params.success([true, false])
            }),
            valueFormatter: formatter,
        },
        // ...(filterType==='set' && typeof v === 'object' && {valueGetter: params => v.__proto__.getUIFilterValue(params.data[k])||params.data[k]}),
    }
}

export const queryFilteredOrdered_FEtoBEConverter = (queryFilteredOrdered: XQueryFilterOrder, columnName: KeysOfUnion<TableRow>, f: TextFilterModel | NumberFilterModel | DateFilterModel | SetFilterModel, dataType: any & TableRow) => {
    const null_notnull = {
        ...((<TextFilterModel | NumberFilterModel | DateFilterModel>f).type === 'blank' && {[`${columnName}`]: 'null'}),   // null
        ...((<TextFilterModel | NumberFilterModel | DateFilterModel>f).type === 'notBlank' && {[`${columnName}`]: 'notnull'}),   // notnull
    };
    if (f.filterType === 'text' && (typeof dataType[columnName] === 'string'))
        queryFilteredOrdered = {
            ...queryFilteredOrdered,
            ...(f.filter && f.type === 'contains' && {[`${columnName}_like`]: f.filter}),   // partial=
            ...(f.filter && f.type === 'equals' && {[`${columnName}`]: f.filter}),   // =
            ...null_notnull
        };
    else if (f.filterType === 'number' && (typeof dataType[columnName] === 'number'))
        queryFilteredOrdered = {
            ...queryFilteredOrdered,
            ...(f.filter && f.type === 'equals' && {[`${columnName}`]: f.filter}),   // =
            ...null_notnull,
            ...(f.filter && (f.type === 'greaterThanOrEqual' || f.type === 'inRange') && {[`${columnName}_since`]: f.filter}),   // >=
            ...(f.filterTo && (f.type === 'inRange') && {[`${columnName}_max`]: f.filterTo}), // <=
            ...(f.filter && (f.type === 'lessThanOrEqual') && {[`${columnName}_max`]: f.filter})  // <=
        };
    else if (f.filterType === 'date' && (typeof dataType[columnName] === 'object'))
        queryFilteredOrdered = {
            ...queryFilteredOrdered,
            ...(f.dateFrom && f.type === 'equals' && {[`${columnName}_since`]: TilbyDatePipe.shopDateGridFilterFrom(f.dateFrom), [`${columnName}_max`]: TilbyDatePipe.shopDateGridFilterTo(f.dateFrom)}),   // =
            ...null_notnull,
            ...(f.dateFrom && (f.type === 'greaterThanOrEqual' || f.type === 'inRange') && {[`${columnName}_since`]: TilbyDatePipe.shopDateGridFilterFrom(f.dateFrom)}),   // >=
            ...(f.dateFrom && (f.type === 'lessThanOrEqual') && {[`${columnName}_max`]: TilbyDatePipe.shopDateGridFilterTo(f.dateFrom)}),  // <=
            ...(f.dateTo && (f.type === 'inRange') && {[`${columnName}_max`]: TilbyDatePipe.shopDateGridFilterTo(f.dateTo)})  // <=
        };
    else if (f.filterType === 'set' && (typeof dataType[columnName] === 'boolean')) {
        queryFilteredOrdered[`${columnName}`] = f.values;
    } else if ((typeof dataType[columnName] === 'object' && f.filterType !== 'date' && columnName in dataType && (!!(<CustomUIFilter>dataType[columnName])?.getConversionFilter))) {
        queryFilteredOrdered['x_query'] = singleOrMultipleQuery(queryFilteredOrdered, dataType[columnName], f);
    }
    return queryFilteredOrdered;
}

export const singleOrMultipleQuery = (queryFilteredOrdered: XQueryFilterOrder, columnValue: any, filter: any) => queryFilteredOrdered['x_query'] ? `AND(${queryFilteredOrdered['x_query']},${(<CustomUIFilter>columnValue).getConversionFilter(filter)})` : (<CustomUIFilter>columnValue).getConversionFilter(filter);

export const customStringConvertionFilter = (params: TextFilterModel, x_queryPrefix: OperatorType): { prefix: ApiFilterPrefix, x_queryPrefix: OperatorType, inputFilter: string } => {
    let prefix: ApiFilterPrefix = '';
    let inputFilter = params.filter || '';
    switch (params.type) {
        case 'contains':
            prefix = '_like'
            break;
        case 'equals':
            break
        case 'blank':
            inputFilter = 'null';
            x_queryPrefix = 'AND';
            break;
        case 'notBlank':
            inputFilter = 'notnull';
            break
    }
    return {
        prefix,
        x_queryPrefix,
        inputFilter
    }
}
