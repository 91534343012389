import angular from "angular";

interface SatispayProfilePicture {
    id: string;
    url: string;
    width: number;
    height: number;
    is_original: boolean;
}

interface SatispayProfilePictures {
    has_more: boolean;
    data: SatispayProfilePicture[];
}

interface SatispaySender {
    id?: string;
    type: string;
    name?: string;
    profile_pictures?: SatispayProfilePictures;
}

interface SatispayReceiver {
    id: string;
    type: string;
}

export interface SatispayPayment {
    id: string;
    type: string;
    amount_unit: number;
    currency: string;
    status: string;
    expired: boolean;
    metadata?: Record<string, any>; // You can be more specific if you know the structure
    sender?: SatispaySender;
    receiver?: SatispayReceiver;
    insert_date: string;
    expire_date: string;
}

interface SatispayGetPaymentsResponse {
    has_more: boolean;
    data: SatispayPayment[];
}

export class SatispayApiService {
    constructor(
        private restManager: any
    ) {
    }

    setupShop(paymentMethodId: number, code: string): Promise<any> {
        return this.restManager.post('satispay/setup', { code: code }, { tilbyPaymentId: paymentMethodId });
    }

    getAcceptedPayments(paymentMethodId: number): Promise<SatispayGetPaymentsResponse> {
        return this.restManager.getList('satispay/payments', { tilbyPaymentId: paymentMethodId });
    }

    getPendingPayments(paymentMethodId: number): Promise<SatispayGetPaymentsResponse> {
        return this.restManager.getList('satispay/payments', { status: 'PENDING', tilbyPaymentId: paymentMethodId });
    }

    acceptPayment(paymentMethodId: number, paymentId: string): Promise<SatispayPayment> {
        return this.restManager.put('satispay/payments', paymentId, { action: 'ACCEPT' }, { tilbyPaymentId: paymentMethodId });
    }

    cancelPayment(paymentMethodId: number, paymentId: String): Promise<SatispayPayment> {
        return this.restManager.put('satispay/payments', paymentId, { action: 'CANCEL' }, { tilbyPaymentId: paymentMethodId });
    }

    rollbackPayment(paymentMethodId: number, payment: SatispayPayment): Promise<SatispayPayment> {
        return this.restManager.post('satispay/payments', {
            flow: 'REFUND',
            amount_unit: payment.amount_unit,
            currency: payment.currency,
            parent_payment_uid: payment.id
        }, {
            tilbyPaymentId: paymentMethodId
        });
    }
}

SatispayApiService.$inject = [
    'restManager'
];

angular.module('application').service('satispayApi', SatispayApiService);