import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    LeanPMSApiService,
    LeanPMSReservation,
    LeanPMSCharge,
    LeanPMSGroupReservation 
} from 'app/modules/application/service/lean-pms-api/lean-pms-api';

import { MigrationHelper } from 'app/modules/application/service/migration-helper/migration-helper';

import {
    LeanPMSPaymentsDialog,
    LeanPMSPaymentsDialogOptions,
    LeanPMSPaymentsReturn,
    LeanPMSReferenceData
} from 'app/modules/cashregister/service/dialog/lean-pms-payments/lean-pms-payments';

import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import { keyBy } from 'src/app/shared/utils';

import {
    Departments,
    Items,
    PaymentMethods,
    Printers,
    Sales
} from 'tilby-models';

import { validate as validateUuid } from 'uuid';

type LeanPMSTransactionData =
    { reservation: LeanPMSReservation, charges: LeanPMSCharge[] } |
    { reservation_group: LeanPMSGroupReservation, charges: LeanPMSCharge[] };

export class LeanPMSPayment implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private readonly $rootScope: angular.IRootScopeService,
        private readonly translateService: any,
        private readonly checkManager: ConfigurationManagerService,
        private readonly migrationHelper: MigrationHelper,
        private readonly entityManager: EntityManagerService,
        private readonly leanPMSPayments: LeanPMSPaymentsDialog,
        private readonly leanPMSApi: LeanPMSApiService,
        private readonly util: any,
        private readonly userActiveSession: UserActiveSessionManagerService
    ) {
    }

    /**
     * Retrieves charges from a target sale and a reservation.
     *
     * @param {Sales} targetSale - the target sale object
     * @param {LeanPMSReservation} reservation - the LeanPMS reservation
     * @param {number} paymentMethodId - the payment method ID
     * @return {Promise<LeanPMSCharge[]>} a promise that resolves to an array of LeanPMS charges
     */
    private async getChargesFromSale(targetSale: Sales, referenceData: LeanPMSReferenceData, paymentMethodId?: number): Promise<LeanPMSCharge[]> {
        const pmsTaxes = await this.leanPMSApi.getTaxes(paymentMethodId);
        const pmsTaxesMap = keyBy(pmsTaxes.filter(tax => tax.active), (tax) => tax.value, { firstMatchOnly: true });

        const missingVatItem = targetSale.sale_items?.find((saleItem) => (!pmsTaxesMap[saleItem.vat_perc]));

        if (missingVatItem) {
            throw { sclErrorId: 'ITEMS.LEAN_PMS_IMPORT.MISSING_VAT', sclErrorData: { vatValue: missingVatItem.vat_perc } };
        }

        let charges: LeanPMSCharge[] = [];

        const prefix = this.checkManager.getPreference('lean_pms.sale_id_prefix') || '';
        const saleId = `${targetSale.id || targetSale.uuid || ''}`;
        const saleIdPrefix = `${prefix}${saleId} - `;
        const sendPrefixOnly = !!(this.checkManager.getPreference('lean_pms.send_prefix_only'));

        const itemsMap = await this.util.getItemsFromIds(targetSale.sale_items);
        const departmentsMap = await this.entityManager.departments.fetchCollectionOffline().then((departments) => keyBy(departments, d => d.id));

        for (const saleItem of targetSale.sale_items || []) {
            charges.push({
                ...referenceData,
                chain_product: itemsMap[saleItem.item_id!]?.option1_value || departmentsMap[saleItem.department_id]?.pms_code,
                value: saleItem.final_price * saleItem.quantity,
                date: moment(saleItem.added_at).format('YYYY-MM-DD'),
                description: sendPrefixOnly ? prefix : `${saleIdPrefix}${saleItem.name}`,
                quantity: Math.abs(saleItem.quantity),
                tax: pmsTaxesMap[saleItem.vat_perc].id,
                is_billable: true
            });
        }

        //Group charges by chain product if enabled (chain product must be defined for each charge)
        if (!!(this.checkManager.getPreference('lean_pms.group_charges_by_lean_product_code'))) {
            const chargesByChainProduct: Record<string, LeanPMSCharge[]> = {};

            try {
                for (const charge of charges) {
                    const chainProduct = charge.chain_product;

                    if (!chainProduct) {
                        throw charge.description.replace(prefix, '');
                    }

                    if (!chargesByChainProduct[chainProduct]) {
                        chargesByChainProduct[chainProduct] = [];
                    }

                    chargesByChainProduct[chainProduct].push(charge);
                }
            } catch (err: any) {
                throw { sclErrorId: 'LEAN_PMS_ITEM_IS_MISSING_OPTION', sclErrorData: { itemName: err } };
            }

            //Recreate charges
            charges = [];

            for (const chainProduct in chargesByChainProduct) {
                const chainProductCharges = chargesByChainProduct[chainProduct];

                charges.push({
                    ...chainProductCharges[0],
                    quantity: 1,
                    description: sendPrefixOnly ? prefix : `${saleIdPrefix}${chainProduct}`,
                    value: this.util.round(chainProductCharges.reduce((sum, charge) => sum + charge.value, 0))
                });
            }
        }

        return charges;
    }

    /**
     * Handles the LeanPMS error and returns the corresponding error code.
     *
     * @param {any} error - The error object to be handled.
     * @return {string} The error code based on the error status.
     */
    private handleLeanPMSError(error: any) {
        switch (error.status) {
            case -1:
                return "LEAN_PMS_OFFLINE";
            case 400:
            case 401:
                return error.data?.error?.message || "LEAN_PMS_UNABLE_TO_COMPLETE_TRANSACTION";
            default:
                return "UNKNOWN_ERROR";
        }
    }

    /**
     * Creates the tail message for a LeanPMS reservation.
     *
     * @param {LeanPMSReservation} reservation - The LeanPMS reservation.
     * @return {string} - The generated tail message.
     */
    private createTail(reservation: LeanPMSReservation | LeanPMSGroupReservation, hotelId: number): string {
        let tailRows = [
            this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.HEADER'),
            this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.HOTEL_ID', { hotelId: hotelId })
        ];

        if ('reference' in reservation) { //Single reservation
            if (reservation.main_guest) {
                tailRows.push(this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.MAIN_GUEST', { mainGuest: `${reservation.main_guest.name} ${reservation.main_guest.surname}` }));
            }

            tailRows.push(...[
                this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.ROOM', { roomType: reservation.room_type, room: reservation.room }),
                this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.BOOKING_REFERENCE', { bookingReference: reservation.reference }),
                this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.BOOKING_STAY', { dateFrom: moment(reservation.date_from, 'YYYY-MM-DD').format('L'), dateTo: moment(reservation.date_to, 'YYYY-MM-DD').format('L') }),
            ]);
        } else { //Group reservation
            tailRows.push(this.translateService.instant('DIGITAL_PAYMENTS.LEAN_PMS.TAIL.MAIN_GUEST', { mainGuest: reservation.name }));
        }

        return tailRows.join('\n');
    }

    /**
     * Retrieves the lean transaction data for a sale.
     *
     * @param {Sales} sale - The sale.
     * @return {LeanPMSTransactionData} The parsed LeanPMS transaction data, or undefined if no LeanPMS transaction data is found.
     */
    private async getSaleLeanTransactionData(sale: Sales): Promise<[LeanPMSTransactionData | undefined, PaymentMethods | undefined]> {
        const leanPMSPayment = sale.payments?.find((payment) => payment.payment_method_type_id == 28);

        if (!leanPMSPayment) {
            return [undefined, undefined];
        }

        const leanPMSMethod = await this.entityManager.paymentMethods.fetchOneOffline(leanPMSPayment.payment_method_id);

        return [JSON.parse(leanPMSPayment.payment_data!), leanPMSMethod];
    }

    /**
     * Checks if the integration is enabled.
     *
     * @return {boolean} - Returns true if the integration is enabled, false otherwise.
     */
    public isEnabled() {
        return true;
    }

    /**
     * Checks if the integration is properly setup.
     *
     * @return {boolean} - Returns true if the integration is properly setup, false otherwise.
     */
    public async isSetup() {
        try {
            return !!(await this.leanPMSApi.getHotelId());
        } catch(err: any) {
            return false;
        }
    }

    /**
     * Determines if the LeanPMS daily closing is enabled.
     *
     * @return {boolean} `true` if the daily closing is enabled, `false` otherwise.
     */
    public isDailyClosingEnabled() {
        return this.checkManager.getPreference('lean_pms.enable_daily_closing');
    }

    /**
     * Executes the daily closing process.
     * If data is not provided, it will default to an empty object.
     * If from_date or to_date is not provided in the data object,
     * it will default to the current day's start time and the current
     * date/time respectively.
     *
     * @param {Printers} printer - the printer object to use for printing
     * @param {any} data - optional data object containing from_date,
     *                     to_date, and cash_verification properties
     * @throws {string} - throws an error if the daily closing process fails
     */
    public async dailyClosing(printer: Printers, data: any) {
        if (!_.isObject(data)) {
            data = {};
        }

        if (data.from_date == null || data.to_date == null) {
            let dayStartTime = this.util.getDayStartTime();

            Object.assign(data, {
                from_date: dayStartTime.toISOString(),
                to_date: moment().toISOString()
            });
        }

        try {
            await this.leanPMSApi.dailyClosing(data.from_date, data.to_date, data.cash_verification);
        } catch (error) {
            throw 'DIGITAL_PAYMENTS.LEAN_PMS.DAILY_CLOSING_FAILED';
        }
    }

    /**
     * Performs a payment transaction.
     *
     * @param {number} amount - The amount to be paid.
     * @param {DigitalPaymentHandlerOptions} options - The payment options.
     * @return {Promise<DigitalPaymentHandlerResult>} - A promise that resolves to the payment result.
     */
    public async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const currentSale = options.sale;

        try {
            let selectedReservation: LeanPMSPaymentsReturn;
            const paymentMethodId = options.paymentMethod.id;

            try {
                selectedReservation = await this.leanPMSPayments.show({
                    amount: amount,
                    reservationId: parseInt(currentSale.pms_reservation_id || '') || undefined,
                    paymentMethodId: paymentMethodId
                });
            } catch (error) {
                throw 'CANCELED';
            }

            const referenceData = selectedReservation.referenceData;

            if (options.paymentMethod.require_signature) {
                const activeSaleService = this.migrationHelper.getActiveSale();

                if ('addSignatureToSale' in activeSaleService) {
                    try {
                        await activeSaleService.addSignatureToSale();
                    } catch (err) {
                        throw 'CANCELED';
                    }
                }
            }

            let checkResult;

            if ('reservation' in referenceData) {
                checkResult = await this.leanPMSApi.getReservations(referenceData.hotel, referenceData.reservation, paymentMethodId);
            } else {
                checkResult = await this.leanPMSApi.getGroupReservations(referenceData.hotel, referenceData.reservation_group, paymentMethodId);
            }

            //Check if the chosen reservation is still in checkin state
            const chosenReservation = checkResult.results[0];

            if (!chosenReservation) {
                throw 'The reservation is not in Check-In status';
            }

            let charges = await this.getChargesFromSale(currentSale, referenceData, paymentMethodId);

            const shopId = this.userActiveSession.getSession()?.shop.id;
            const documentUrl = shopId ? `https://er.tilby.com/${shopId}/${currentSale.uuid}.pdf` : undefined;

            for (let charge of charges) {
                Object.assign(charge, {
                    type: 2,
                    ticket_identifier: currentSale.id,
                    ticket_image_url: documentUrl,
                });
            }

            await this.leanPMSApi.createCharges(charges, paymentMethodId);

            let paymentData;

            if ('reference' in chosenReservation) {
                paymentData = JSON.stringify({ reservation: chosenReservation, charges: charges });
            } else {
                const { reservations, ...cleanGroupReservation } = chosenReservation;
                paymentData = JSON.stringify({ reservation_group: cleanGroupReservation, charges: charges });
            }

            return {
                acquirer_name: 'Lean PMS',
                payment_data: paymentData,
                tail: this.createTail(chosenReservation, referenceData.hotel),
                unclaimed: true
            };
        } catch (error: any) {
            switch (typeof error) {
                case 'string':
                    throw error;
                case 'object':
                    if (error?.sclErrorId) {
                        throw error;
                    }

                    throw this.handleLeanPMSError(error);
                default:
                    throw 'UNKNOWN_ERROR';
            }
        }
    }

    /**
     * Refunds a LeanPMS transaction given a refund sale.
     *
     * @param {number} amount - The amount to be refunded (not used).
     * @param {DigitalPaymentHandlerOptions} options - The options for the digital payment handler.
     * @return {Promise<DigitalPaymentHandlerResult | undefined>} - The result of the refund operation.
     */
    public async refund(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult | undefined> {
        const currentSale = options.sale;

        if (!currentSale) {
            return;
        }

        const parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

        if (!parentSaleId) {
            return;
        }

        let parentSale;

        if (validateUuid(parentSaleId.toString())) {
            const results = await this.entityManager.sales.fetchCollectionOnline({ uuid: parentSaleId });

            if (Array.isArray(results)) {
                parentSale = results[0];
            }
        } else {
            parentSale = await this.entityManager.sales.fetchOneOfflineFirst(parentSaleId);
        }

        if (!parentSale) {
            return;
        }

        let transactionData;

        try {
            transactionData = await this.getSaleLeanTransactionData(parentSale);
        } catch (err) {
            //Nothing to do
        }
        
        if (!transactionData?.[0]) {
            return;
        }

        const [leanTransactionInfo, paymentMethod] = transactionData;

        const hotelId = await this.leanPMSApi.getHotelId(paymentMethod?.id);

        if (!hotelId) {
            return;
        }

        let charges = [], paymentData, tail;

        if ('reservation' in leanTransactionInfo) {
            charges = await this.getChargesFromSale(currentSale, { reservation: leanTransactionInfo.reservation.reference, hotel: hotelId }, paymentMethod?.id);
        } else {
            charges = await this.getChargesFromSale(currentSale, { reservation_group: leanTransactionInfo.reservation_group.id, hotel: hotelId }, paymentMethod?.id);
        }

        for (let charge of charges) {
            Object.assign(charge, { type: 6 });
        }

        try {
            await this.leanPMSApi.createCharges(charges, paymentMethod?.id);

            if ('reservation' in leanTransactionInfo) {
                paymentData = JSON.stringify({ reservation: leanTransactionInfo.reservation, charges: charges });
                tail = this.createTail(leanTransactionInfo.reservation, hotelId);
            } else {
                paymentData = JSON.stringify({ reservation_group: leanTransactionInfo.reservation_group, charges: charges });
                tail = this.createTail(leanTransactionInfo.reservation_group, hotelId);
            }

            return {
                acquirer_name: 'Lean PMS',
                payment_data: paymentData,
                tail: tail,
                unclaimed: true
            };
        } catch (error) {
            throw this.handleLeanPMSError(error);
        }
    }

    /**
     * Opens the LeanPMS dialog.
     */
    public openDialog(options?: LeanPMSPaymentsDialogOptions) {
        return this.leanPMSPayments.show(options || {});
    }

    public async getSaleReservationData(sale: Sales) {
        try {
            const reservation = await this.openDialog({ enableConfirm: true, reservationId: parseInt(sale.pms_reservation_id || '') }).then((res) => res.reservation);
            let reservationName: string;

            if('room' in reservation) {
                if(reservation.main_guest) {
                    reservationName = `${reservation.room} ${reservation.main_guest.name} ${reservation.main_guest.surname}`;
                } else {
                    reservationName = `${reservation.room}`.padEnd(3, ' ');
                }
            } else {
                reservationName = `${reservation.id} ${reservation.name} ${reservation.contact_name}`;
            }

            return {
                pms_type: 'lean',
                pms_reservation_id: String(reservation.id),
                pms_reservation_name: reservationName,
                name: reservationName.slice(0, 30),
            };
        } catch(err) {
            return null;
        }
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const leanPMSPayment = sale.payments?.find((payment) => payment.payment_method_type_id == 28);

        if(!leanPMSPayment) {
            return;
        }

        let result = 'OK';
        
        try {
            const [leanTransactionInfo, paymentMethod] = await this.getSaleLeanTransactionData(sale);

            if (!leanTransactionInfo) {
                return;
            }

            for (const charge of leanTransactionInfo.charges) {
                Object.assign(charge, { type: 6, value: -charge.value });
            }

            await this.leanPMSApi.createCharges(leanTransactionInfo.charges, paymentMethod?.id);
        } catch (error) {
            result = 'KO';
        } finally {
            throw `LEAN_PMS_ROLLBACK_${result}`;
        }
    }

    /**
     * Imports items from the PMS
     *
     * @return {Promise<void>} Promise that resolves when the import is complete.
     */
    public async importItems() {
        const lean2TilbyDepsMap: Record<number, Departments> = {};
        const hotelId = await this.leanPMSApi.getHotelId();

        if(!hotelId) {
            return;
        }

        //Step 1: check if we have all the VATs of the PMS
        const leanTaxes = await this.leanPMSApi.getTaxes();
        const tilbyDepartments = await this.entityManager.departments.fetchCollectionOnline();
        const tilbyDepartmentsByVat = _(tilbyDepartments).reverse().keyBy('vat.value').value();

        //Check for a missing VAT and meanwhile create the conversion map
        let missingVat = leanTaxes.find((leanTax) => {
            if (tilbyDepartmentsByVat[leanTax.value]) {
                lean2TilbyDepsMap[leanTax.id] = tilbyDepartmentsByVat[leanTax.value];
            } else {
                return true;
            }
        });

        if (missingVat) {
            throw { message: 'LEAN_PMS_IMPORT.MISSING_VAT', errorInfo: { vatValue: missingVat.value } };
        }

        //Step 2: perform the import
        const pmsProducts = await this.leanPMSApi.getProducts(hotelId);
        const itemsToSend = pmsProducts.map((pmsProduct) => {
            //TODO: add some validation to pmsProduct fields (e.g. truncate, etc...)
            return {
                sku: _.toString(pmsProduct.chain_product),
                name: pmsProduct.name,
                price1: pmsProduct.price,
                department_id: lean2TilbyDepsMap[pmsProduct.tax_id].id, //This should always be successful
                description: pmsProduct.description,
                option1_value: _.toString(pmsProduct.chain_product),
                on_sale: pmsProduct.active,
                thumbnail: pmsProduct.image
            } as Items;
        });

        const items = await this.entityManager.items.fetchCollectionOnline({ sku: 'notnull' }) as Items[];
        const tilbyItemsBySku = _(items).filter('sku').keyBy('sku').value();

        let index = 0;

        for (let item of itemsToSend) {
            this.$rootScope.$broadcast("wait-dialog:update-state", { message: this.translateService.instant('ITEMS.LEAN_PMS_IMPORT.IMPORTING_STATUS', { current: ++index, total: itemsToSend.length }) });
            let currentItem = tilbyItemsBySku[item.sku!];

            if (!currentItem) {
                await this.entityManager.items.postOneOnline(item);
            } else {
                let newItem = Object.assign({}, currentItem, item);

                if (!_.isEqual(_.pickBy(newItem, _.identity), _.pickBy(currentItem, _.identity))) {
                    await this.entityManager.items.putOneOnline(newItem);
                }
            }
        }
    }
}

LeanPMSPayment.$inject = [
    '$rootScope',
    '$translate',
    'checkManager',
    'migrationHelper',
    'entityManager',
    'leanPMSPayments',
    'leanPMSApi',
    'util',
    'userActiveSession'
];

angular.module('digitalPayments').service('leanPMS', LeanPMSPayment);