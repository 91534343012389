import angular from 'angular';
import moment from 'moment-timezone';

import {
    EricsoftApi,
    EricsoftApiConfig,
    EricsoftPMSApiService
} from 'app/modules/application/service/ericsoft-pms-api/ericsoft-pms-api';

import {
    BedzzleCommonFolio
} from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';

import {
    MathUtils
} from 'src/app/shared/utils';

import {
    PaymentMethods
} from 'tilby-models';

import {
    ConfigurationManagerService
} from 'src/app/core';

type EricsoftPaymentsDialogInput = {
    amount: number;
    paymentMethod: PaymentMethods;
}

class EricsoftPaymentsController {
    //PMS configuration parameters
    private ipAddress;
    private port;
    private username;
    private password;
    private propertyId;
    private apiHandler?: EricsoftApi;

    //UI params
    message = '';
    amountToPay: number;
    fetchingFolios = false;
    folios: BedzzleCommonFolio[] = [];
    needsSetup = false;
    operationInProgress = false;
    searchText = '';
    selectedFolio: BedzzleCommonFolio | null = null;
    showAmount: boolean;
    showBalance: boolean;
    paymentMethod: PaymentMethods;
    visibleFolios: BedzzleCommonFolio[] = [];

    constructor(
        private readonly $mdDialog: any,
        private readonly $translate: any,
        private readonly $filter: any,
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly ericsoftPMSApi: EricsoftPMSApiService,
        options: EricsoftPaymentsDialogInput
    ) {
        //Get PMS configuration
        let paymentConfig = options.paymentMethod.configuration as (EricsoftApiConfig | undefined);

        if (!paymentConfig?.ip_address) {
            paymentConfig = this.ericsoftPMSApi.getLegacyConfig();
        }

        this.ipAddress = paymentConfig.ip_address || '';
        this.port = paymentConfig.port || 5000;
        this.username = paymentConfig.username || '';
        this.password = paymentConfig.password || '';
        this.propertyId = paymentConfig.property_id || '';

        //Set UI params
        this.amountToPay = MathUtils.round(options.amount);
        this.paymentMethod = options.paymentMethod;
        this.showAmount = !(this.configurationManagerService.getPreference('pms_payments.hide_total_amount'));
        this.showBalance = !(this.configurationManagerService.getPreference('pms_payments.hide_balance'));
    }

    canSetupShop() {
        return this.needsSetup && this.ipAddress && this.username && this.password && this.propertyId;
    }

    async setupShop() {
        this.operationInProgress = true;

        try {
            //Setup API manager (handles saving of parameters into the shop_preferences)
            await this.ericsoftPMSApi.setupApi(this.paymentMethod, {
                ip_address: this.ipAddress,
                port: this.port,
                username: this.username,
                password: this.password,
                property_id: this.propertyId
            });

            this.needsSetup = false;
            this.fetchFolios();
        } finally {
            this.operationInProgress = false;
        }
    };

    $onInit() {
        this.fetchFolios();
    }

    async fetchFolios() {
        this.fetchingFolios = true;

        try {
            this.apiHandler = await this.ericsoftPMSApi.getApiInstance(this.paymentMethod);

            try {
                const roomFolios = await this.apiHandler.getRoomFolios();
                const miscSalesFolios = await this.apiHandler.getMiscSalesFolios().catch(() => []);

                this.folios = [...miscSalesFolios, ...roomFolios.sort((a, b) => a.keyDoorCode! > b.keyDoorCode! ? 1 : -1)];
                this.visibleFolios = this.folios;
            } catch (error: any) {
                if (error?.status == -1) {
                    this.message = this.$translate.instant('CASHREGISTER.ERICSOFT_PMS_PAYMENTS.OFFLINE');
                } else {
                    this.message = error?.data?.error?.msg || '';
                }

                throw error;
            }
        } catch (error) {
            this.needsSetup = true;
            return;
        } finally {
            this.fetchingFolios = false;
        }
    };

    performSearch() {
        //Reset visibleFolios
        this.visibleFolios = this.folios;

        //Split searchText by spaces and filter by each word
        this.searchText.split(/\s*/g).forEach((word) => {
            this.visibleFolios = this.$filter('filter')(this.visibleFolios, word);
        });

        //If the selectedFolio is not in the visibleFolios, unselect it
        if (!this.visibleFolios.find((folio) => folio === this.selectedFolio)) {
            this.selectedFolio = null;
        }
    };

    cancel() {
        this.$mdDialog.cancel('CANCELED');
    }

    isMiscSale(folio: BedzzleCommonFolio) {
        return !folio.stayId;
    }

    deleteMessage() {
        this.message = '';
    }

    selectFolio(folio: BedzzleCommonFolio) {
        this.selectedFolio = folio;
    }

    parseEricsoftDate(date: string) {
        return moment(date, 'YYYY-MM-DD').format('L');
    }

    confirm() {
        if (this.selectedFolio) {
            this.$mdDialog.hide(this.selectedFolio);
        }
    };
}

EricsoftPaymentsController.$inject = [
    '$mdDialog',
    '$translate',
    '$filter',
    'checkManager',
    'ericsoftPMSApi',
    'options',
];

export class EricsoftPMSPaymentsDialog {
    constructor(
        private readonly $mdDialog: any,
    ) {
    }

    public show(options: EricsoftPaymentsDialogInput): Promise<BedzzleCommonFolio> {
        return this.$mdDialog.show({
            controller: EricsoftPaymentsController,
            controllerAs: '$ctrl',
            template: require('./ericsoft-pms-payments.html'),
            clickOutsideToClose: true,
            locals: {
                options: options
            }
        });
    }
}

EricsoftPMSPaymentsDialog.$inject = [
    "$mdDialog"
];

angular.module('cashregister').factory('ericsoftPayments', EricsoftPMSPaymentsDialog);