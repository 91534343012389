import {
    CommonModule
} from '@angular/common';

import {
    Component,
    Input,
    inject
} from '@angular/core';

import {
    PaymentsListComponent
} from '../payments-list/payments-list.component';

import {
    Sales,
    SalesPayments
} from 'tilby-models';

import {
    ActiveSaleService,
    CashregisterStateService
} from 'src/app/features/cashregister';

import {
    ConfirmDialogService,
} from 'src/app/dialogs';

import {
    TranslateModule,
} from '@ngx-translate/core';

import {
    keyBy
} from 'src/app/shared/utils';

@Component({
  selector: 'app-payments-list-wrapper',
  templateUrl: './payments-list-wrapper.component.html',
  styleUrls: ['./payments-list-wrapper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PaymentsListComponent
  ],
  host: {
    class:'tw-flex tw-flex-col tw-h-full'
  }
})
export class PaymentsListWrapperComponent {
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly deliveryChannels = keyBy(this.cashregisterStateService.deliveryChannels, c => c.id);

    @Input() sale: Sales | undefined;

    protected getPaymentDetail(payment: SalesPayments) {
        switch(payment.payment_method_type_id) {
            case 17:
                return this.deliveryChannels[payment.payment_data || '']?.name || payment.payment_data || '';
            case 33:
                return payment.code;
            default:
                if (payment.card_circuit_id) {
                    return payment.card_circuit_name;
                }
                if (payment.ticket_id) {
                    return payment.ticket_name;
                }
                return '';
        }
    };

    protected async removeSalesPayment(salePayments: SalesPayments){
        if (salePayments.paid) {
            const answer = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'CASHREGISTER.ACTIVE_SALE.REMOVE_PAID_PAYMENT_CONFIRM'
                }
            });

            if (!answer) {
                return;
            }
        }

        this.activeSaleService.removePaymentFromSale(salePayments);
    }
}
