import angular from 'angular';

import {
    GetFoliosResponse,
    BedzzleCommonTransaction,
    BedzzleCommonFolio
} from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';

export type BeddyFolio = BedzzleCommonFolio &{
    checkInStatus?: number,
    checkOutStatus?: number
}

export class BeddyApiService {
    constructor(
        private restManager: any
    ) {
    }

    public setupShop(paymentMethodId: number,propertyId: string): Promise<any> {
        return this.restManager.post('beddy/setup', { propertyId: propertyId }, { tilbyPaymentId: paymentMethodId });
    }

    public getRoomFolios(paymentMethodId: number): Promise<GetFoliosResponse<BeddyFolio>> {
        return this.restManager.getList('beddy/room_folios', { tilbyPaymentId: paymentMethodId });
    }

    public createTransaction(paymentMethodId: number, transactionData: BedzzleCommonTransaction) {
        return this.restManager.post('beddy/transactions', transactionData, { tilbyPaymentId: paymentMethodId });
    }

    public rollbackTransaction(paymentMethodId: number, outletId: string, orderId: string) {
        return this.restManager.deleteOne('beddy/transactions', null, { orderId: orderId, outletId: outletId, tilbyPaymentId: paymentMethodId });
    }
};

BeddyApiService.$inject = ['restManager'];

angular.module('application').service('beddyApi', BeddyApiService);