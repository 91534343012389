import {
    Injectable,
    Injector,
    inject
} from "@angular/core";

import {
    ConfigurationManagerService,
    ConnectionService,
    EntityManagerService,
    OperatorManagerService,
    ProgressivesManagerService,
    UserActiveSessionManagerService
} from "src/app/core";

import {
    Departments,
    Items,
    ItemsCombinations,
    Sales,
    SalesItems,
    SalesItemsPriceChanges,
    SalesItemsVariations,
    SalesPriceChanges
} from "tilby-models";

import {
    keyBy,
    matches,
    MathUtils
} from "src/app/shared/utils";

import {
    $state,
    addVariationsDialog,
    FiscalProviders,
    fiscalUtils,
    kioskUtils,
    RootScope,
    saleUtils,
} from "app/ajs-upgraded-providers";

import { BookingUtils } from "src/app/shared/booking-utils.service";
import { PromotionEngineService } from "src/app/features/cashregister";
import { SalesCashregister } from "src/app/shared/model/cashregister.model";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
    v4 as generateUuid
} from 'uuid';

import {
    AlertDialogService,
    DocumentPrintersManagerDialogStateService,
    OpenDialogsService,
    SaleItemManagerDialogService
} from "src/app/dialogs";

import { groupBy } from "lodash";

import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

export type QuickCoupon = {
    name: string;
    value: number;
    department_id: number;
}

export type ActionReason = 'store_sale' | 'delete_sale' | 'delete_item';

export type CoverConfiguration = { type: 'none' } |
    { type: 'error', error: string } |
    { type: 'item', data: Items } |
    { type: 'price_change', data: Pick<SalesPriceChanges, 'type' | 'description' | 'value'> };

type GetSaleTemplateOptions = {
    skipUpdateProgressive?: boolean,
    skipFiscalProvider?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class SaleUtilsService {
    private readonly $state = inject($state);
    private readonly $rootScope = inject(RootScope);
    private readonly addVariationsDialog = inject(addVariationsDialog);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly connectionService = inject(ConnectionService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly documentPrintersManager = inject(DocumentPrintersManagerDialogStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly fiscalProviders = inject(FiscalProviders);
    private readonly injector = inject(Injector);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly progressivesManager = inject(ProgressivesManagerService);
    private readonly promotionEngine = inject(PromotionEngineService);
    private readonly operatorManager = inject(OperatorManagerService);
    private readonly saleItemManagerDialog = inject(SaleItemManagerDialogService);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);

    updateContextMenuEvent = new Subject<void>();

    private quantityOverridesMap?: Record<number, number>;

    private initQuantityOverrides() {
        //Load default quantity ovverrides
        const quantityOverrides = this.configurationManagerService.getPreference("cashregister.item_quantity_overrides");
        const quantityOverridesMap: Record<number, number> = {};

        if (quantityOverrides) {
            for (const row of quantityOverrides.split('\n')) {
                const [categoryId, quantity] = row.split('|');

                if (categoryId && quantity) {
                    quantityOverridesMap[parseInt(categoryId)] = parseInt(quantity);
                }
            }
        }

        return quantityOverridesMap;
    };


    private isKioskMode() {
        return this.$state.current.name?.startsWith('app.kiosk');
    }

    /**
     * Generates a new sale template.
     *
     * @param {GetSaleTemplateOptions} options - Options for customizing the sale template.
     * @param {boolean} [options.skipUpdateProgressive] - If true, skips updating the progressive sale number.
     * @param {boolean} [options.skipFiscalProvider] - If true, skips fiscal provider document handling.
     * @returns {Promise<Sales>} A promise that resolves to the new sale template object.
     */
    public async getSaleTemplate(options?: GetSaleTemplateOptions) {
        // Get the active operator session
        const operator = this.userActiveSession.getSession();

        // Get the progressive sale number
        const progressive = await this.progressivesManager.getProgressives();
        const isOnline = this.connectionService.isOnline();
        let saleNumber = isOnline ? progressive.sale_number : progressive.sale_number_offline;

        // Initialize the sale number if it's not set
        if (typeof saleNumber !== 'number') {
            saleNumber = 1;
        } else {
            saleNumber += 1;
        }

        // Generate the sale name based on the sale number and operator
        let saleName = this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + saleNumber;

        // Add offline suffix to the sale name if the sale is created offline
        if (!isOnline) {
            const nameString = (operator?.first_name && operator?.last_name)
                ? [operator.first_name?.[0] || '', operator.last_name?.[0] || ''].join('')
                : operator?.username?.slice(0, 3);

            saleName += this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.OFFLINE') + nameString;

            // Update the progressive sale number if not skippedx
            if (!options?.skipUpdateProgressive) {
                this.progressivesManager.updateProgressives({ sale_number_offline: saleNumber });
            }
        }

        // Update the sale name if the sale is created in kiosk mode
        if (this.isKioskMode()) {
            const kioskSaleData = this.injector.get(kioskUtils).getKioskSaleData();
            saleName = kioskSaleData.tableNumber ? `${this.translateService.instant('KIOSK.CATALOG.TABLE_SALE_NAME')} ${kioskSaleData.tableNumber}`.slice(0, 30) : saleName;
        }

        // Get the operator data
        const opData = this.operatorManager.getOperatorData();

        // Create the sale template object
        const sale: Sales = {
            name: saleName,
            status: 'open',
            channel: this.isKioskMode() ? 'kiosk' : 'pos',
            createdby_id: opData.id,
            seller_id: opData.id,
            seller_name: opData.full_name,
            currency: TilbyCurrencyPipe.currency.code,
            sale_number: isOnline ? saleNumber : undefined,
            order_type: "normal",
            //@ts-expect-error
            open_at: new Date().toISOString(),
            payments: [],
            price_changes: [],
            sale_documents: [],
            sale_items: [],
            uuid: generateUuid()
        };

        // Handle fiscal provider document if not skipped
        if (!options?.skipFiscalProvider) {
            try {
                let printerDocumentData;

                try {
                    printerDocumentData = await this.documentPrintersManager.getPrinterDocumentData('default', 'default');
                } catch (err) {
                    //Nothing to handle
                } finally {
                    const fiscalProvider = this.fiscalProviders.getFiscalProvider(printerDocumentData?.printer?.fiscal_provider);

                    if (typeof fiscalProvider?.openFiscalDocument === 'function') {
                        try {
                            const result = await fiscalProvider.openFiscalDocument();

                            sale.sale_documents!.push(Object.assign(result, {
                                printer_id: printerDocumentData?.printer.id,
                                printer_name: printerDocumentData?.printer.name
                            }));
                        } catch (error) {
                            if (fiscalProvider.getProviderError) {
                                throw fiscalProvider.getProviderError(error);
                            }

                            throw error;
                        }
                    }
                }
            } catch (err) {
                const error = 'CASHREGISTER.ACTIVE_SALE.FISCAL_PROVIDER_DOCUMENT_OPEN_FAILED';
                this.$rootScope.$broadcast('activeSale:sale-creation-failed', error);

                throw error;
            }
        }

        // Return the sale template object
        return sale;
    }

    /**
     * Generates a sale item template based on the provided parameters.
     *
     * @param {Items} sourceItem - The source item to create the sale item from.
     * @param {number} priceList - The price list number to use.
     * @param {number} [combinationId] - Optional combination ID for variations.
     * @param {number} [quantity] - Optional quantity of the sale item.
     * @param {string} [barcode] - Optional barcode for the sale item.
     * @param {Partial<SalesItems>} [overrides] - Optional overrides for sale item properties.
     * @return {Promise<SalesItems | undefined>} The generated sale item.
     */
    public async getSaleItemTemplate(sourceItem: Items, priceList: number, combinationId?: number, quantity?: number, barcode?: string, overrides?: Partial<SalesItems>){
        // Initialize quantity overrides map if not already initialized
        if (this.quantityOverridesMap == null) {
            this.quantityOverridesMap = this.initQuantityOverrides();
        }

        const opData = this.operatorManager.getOperatorData();
        const targetPrice = `price${priceList}` as (`price${number}` & keyof Items & keyof ItemsCombinations);
        const targetDepartment = Number(priceList) === 1 ? `department` : `department${priceList}` as (`department${number}` & keyof Items);
        const department = sourceItem[targetDepartment] || sourceItem['department'];
        const now = new Date().toISOString();

        // Create a sale item with default properties
        let saleItem: SalesItems = {
            //@ts-expect-error
            added_at: now,
            barcode: barcode || sourceItem.barcodes?.[0]?.barcode,
            category_id: sourceItem.category?.id || undefined,
            category_name: sourceItem.category?.name || undefined,
            cost: sourceItem.cost,
            //@ts-expect-error
            department: department,
            //@ts-expect-error
            department_id: department?.id || null,
            //@ts-expect-error
            department_name: department?.name || null,
            half_portion: false,
            ingredients: [],
            is_group_item: sourceItem.is_group_item || sourceItem.split_group_components,
            item_id: sourceItem.id,
            //@ts-expect-error
            lastupdate_at: now,
            lastupdate_by: opData.id,
            name: sourceItem.name,
            not_discountable: sourceItem.not_discountable,
            price: sourceItem[targetPrice]!,
            price_changes: [],
            quantity: quantity || this.quantityOverridesMap[sourceItem.category_id!] || 1,
            request_weighing: sourceItem.request_weighing && quantity == null,
            seller_id: opData.id,
            seller_name: opData.full_name,
            sku: sourceItem.sku,
            type: "sale",
            uuid: generateUuid(),
            variations: [],
            vat_perc: department?.vat?.value ?? sourceItem.vat_perc!,
        };

        // Apply any overrides to the sale item
        if (typeof overrides === 'object') {
            Object.assign(saleItem, overrides);
        }
        
        const variations = [...(structuredClone(sourceItem.variations || []))];
        
        // Fetch category variations if available
        if (sourceItem.category_id) {
            const category = await this.entityManagerService.categories.fetchOneOffline(sourceItem.category_id);
            variations.push(...(structuredClone(category?.variations || [])));
        }

        // Handle combination logic
        const combination = combinationId ? sourceItem.combinations?.find((combination) => combination.id === combinationId) : undefined;

        if (combination) {
            Object.assign(saleItem, {
                barcode: barcode || combination.barcodes?.[0]?.barcode || saleItem.barcode,
                combination_id: combination.id,
                combination_sku: combination.sku,
                price: combination[targetPrice] ?? saleItem.price,
                notes: combination ? combination.combination_values?.map((value) => value.variation_value || '-').join(', ') : null
            });
        } else if (variations.length) {
            // Handle variation selection based on configuration
            if (this.isKioskMode()) {
                const result = await this.addVariationsDialog.show({
                    name: sourceItem.name,
                    variations: variations
                });

                saleItem.variations = result?.variations || [];
            } else {
                //Check if the saleItem has a required variation and show saleItemManager if that's the case
                const hasRequiredVariation = this.configurationManagerService.getPreference('cashregister.disable_required_variations') !== true && variations.some((v) => v.required);

                if (hasRequiredVariation) {
                    let result;

                    result = await this.saleItemManagerDialog.openDialog({ rowItem: saleItem });

                    if (!result) {
                        return;
                    }

                    if (result) {
                        result.rowItem.request_weighing = saleItem.request_weighing;
                        saleItem = structuredClone(result.rowItem);
                    }
                } else {
                    const defaultVariations: SalesItemsVariations[] = [];

                    for (const variation of variations) {
                        variation.variation_values = variation.variation_values || [];

                        const found = variation.variation_values.find((v) => v.default_value);

                        if (found) {
                            defaultVariations.push({
                                name: variation.name,
                                value: found.value!,
                                price_difference: found.price_difference,
                                linked_item_uuid: found.linked_item_uuid,
                                linked_item_sku: found.linked_item_sku,
                                variation_id: variation.id!,
                                variation_value_id: found.id!
                            });
                        }
                    }

                    saleItem.variations = defaultVariations;
                }
            }
        }

        return saleItem;
    }

    /**
     * Generates a new dynamic sale item template for the given department and price.
     *
     * @param {Departments} department - The department object that the sale item will be related to.
     * @param {number} price - The price of the sale item.
     * @returns {SalesItems} A new dynamic sale item template.
     */
    public getDynamicSaleItemTemplate(department: Departments, price: number): SalesItems {
        const time = new Date();
        const opData = this.operatorManager.getOperatorData();

        return {
            //@ts-expect-error
            added_at: time.toISOString(),
            department: department,
            department_id: department.id!,
            department_name: department.name,
            half_portion: false,
            ingredients: [],
            name: department.name,
            not_discountable: department.not_discountable,
            price: price,
            price_changes: [],
            quantity: 1,
            seller_id: opData.id,
            seller_name: opData.full_name,
            type: 'sale',
            uuid: generateUuid(),
            variations: [],
            vat_perc: department.vat?.value!
        };
    }

    /**
     * Generates a new generic price change object for the given target.
     *
     * @param {T} target - The target object that the price change will be applied to.
     * @param {type} type - The type of the price change.
     * @param {number} value - The value of the price change.
     * @param {string} description - The description of the price change.
     * @param {number|undefined} index - The index of the price change. Optional.
     * @param {Partial<T['price_changes']>|undefined} overrides - The overrides for the price change. Optional.
     * @return {(SalesPriceChanges | SalesItemsPriceChanges)} - The newly generated price change object.
     */
    public getGenericPriceChange<T extends Sales | SalesItems>(target: T, type: (SalesPriceChanges | SalesItemsPriceChanges)['type'], value: number, description: string, index?: number, overrides?: Partial<SalesItemsPriceChanges | SalesPriceChanges>) {
        const currentPriceChanges = target.price_changes || [];

        let newIndex = index || 0;

        if (!newIndex) {
            newIndex = Math.max(...currentPriceChanges.map(pc => pc.index), 0) + 1;

            if (type === 'disc_perc_nd') {
                if (newIndex < 100) {
                    newIndex += 100;
                }
            } else if (newIndex >= 100) {
                newIndex -= 100;
            }
        }

        const priceChange = {
            index: newIndex,
            type: type,
            value: value,
            description: description
        } as (SalesPriceChanges & SalesItemsPriceChanges);

        if (overrides) {
            Object.assign(priceChange, overrides);
        }

        return priceChange;
    }

    /**
     * Returns the value of the half portion based on the shop configuration.
     *
     * @return {number} The value of a half portion.
     */
    public getHalfPortionValue() {
        const halfPortionPref = parseFloat(this.configurationManagerService.getPreference("orders.half_portion_discount_value") || '');
        return Number.isFinite(halfPortionPref) ? MathUtils.round(halfPortionPref / 100) : 0.5;
    }

    /**
     * Retrieves the list of configured quick coupons.
     *
     * @return {QuickCoupon[]} The list of configured quick coupons.
     */
    public getConfiguredQuickCoupons (): QuickCoupon[] {
        const quickCoupons = [];

        try {
            const configuredCoupons = JSON.parse(this.configurationManagerService.getPreference('cashregister.quick_coupons_list') || "[]");

            if(Array.isArray(configuredCoupons)) {
                for(const coupon of configuredCoupons) {
                    if(coupon?.name && coupon?.value != null && coupon?.department_id) {
                        quickCoupons.push(coupon);
                    }
                }
            }
        } catch(err) {
            //Do nothing
        }

        return quickCoupons;
    }

    /**
     * Determines if the given sale is a credit note.
     *
     * @param {Sales} sale - The sale object to be checked.
     * @return {boolean} Returns true if the sale is a credit note, false otherwise.
     */
    public isCreditNote(sale: Sales): boolean {
        return !!(sale?.sale_items?.length && sale.sale_items.every((saleItem) => saleItem.type === 'refund'));
    }

    /**
     * Retrieves the partial unit price (price + variations + ingredients) of the given sale item.
     *
     * @param {SalesItems} saleItem - The sale item object to be checked.
     * @param {object} options - options - if the options are not provided, the default values from the shop configuration will be used.
     * @param {boolean} [options.considerIngredientsRemoval] - If true, the price difference of ingredients with type 'removed' will be subtracted from the partial unit price.
     * @param {number} [options.halfPortionValue] - The value of the half portion.
     * @return {number} Returns the partial unit price of the given sale item.
     */
    public getItemPartialUnitPrice(saleItem: SalesItems, options?: { considerIngredientsRemoval?: boolean, halfPortionValue?: number }): number {
        const halfPortionValue = options?.halfPortionValue ?? this.getHalfPortionValue();
        const considerIngredientsRemoval = options?.considerIngredientsRemoval ?? !!this.configurationManagerService.getPreference("orders.ingredients_removal_affects_price");

        const ingredients = saleItem.ingredients || [];
        const variations = saleItem.variations || [];

        // Sum up the ingredients
        let ingredientsSum = 0;

        for (const ingredient of ingredients) {
            if (ingredient.price_difference) {
                switch (ingredient.type) {
                    case 'added':
                        ingredientsSum += ingredient.price_difference * (ingredient.quantity || 1);
                        break;
                    case 'removed':
                        if (considerIngredientsRemoval) {
                            ingredientsSum -= ingredient.price_difference;
                        }
                        break;
                }
            }
        }

        // Sum up the variations
        let variationsSum = 0;

        for (const variation of variations) {
            if (variation.price_difference) {
                variationsSum += variation.price_difference;
            }
        }

        // Apply half portion if enabled
        const price = (saleItem.half_portion) ? MathUtils.round(saleItem.price * (1 - halfPortionValue)) : saleItem.price;

        // Return the partial unit price
        return MathUtils.round(price + variationsSum + ingredientsSum);
    }

    public async repartitionSaleItemsByDepartment(sale: Sales, partitionType: 'by_amount' | 'by_covers', partitionTarget: number, department?: Departments, targetAmount?: number, options?: { singleQuantity?: boolean }): Promise<SalesItems[]> {
        //Calculate the number of items to create
        const originalAmount = sale.final_amount!;
        const amountScale = targetAmount != null ? MathUtils.round(targetAmount / originalAmount, 8) : 1;
        const saleItems: SalesItems[] = [];

        //Create an array of the percentages for each item group
        let partitions = (partitionType === 'by_covers') ? partitionTarget : Math.ceil(originalAmount / partitionTarget);

        if(options?.singleQuantity) {
            partitions = 1;
        }

        const percentages = Array(partitions);

        for(let i = 0; i < partitions - 1; i++) {
            percentages[i] = MathUtils.round(partitionType === 'by_amount' ? ((partitionTarget / originalAmount) * 100) : (100 / partitions), 8);
        }

        percentages[partitions - 1] = MathUtils.round(100 - percentages.reduce((s, v) => s + v, 0), 8);

        const items = [];

        //If a department is set, use it, otherwise split the sale based on the departments
        if(department) {
            for(let i = 0; i < partitions - 1; i++) {
                items.push([{ department: department, amount: MathUtils.round(originalAmount * (percentages[i] / 100)) * amountScale }]);
            }

            //To avoid rounding issues, calculate the last item amount based on the remaining amount
            items[partitions - 1] = [{ department: department, amount: MathUtils.round((originalAmount * amountScale) - items.reduce((s, v) => s + v[0].amount, 0)) }];
        } else {
            const departmentsById = await this.entityManagerService.departments.fetchCollectionOffline().then(departments => keyBy(departments, (d) => d.id));

            //Split the total by department
            const saleItemsByDepartment = groupBy(sale.sale_items, (saleItem) => saleItem.department_id);
            const amountsByDepartment: Record<string, number> = {};

            for(const depId in saleItemsByDepartment) {
                amountsByDepartment[depId] =  saleItemsByDepartment[depId].reduce((s, saleItem) => s + MathUtils.round(saleItem.final_price * saleItem.quantity), 0) * amountScale;
            }

            //Calculate the amount to split by each department, based on the percentages
            //The last item is always the remaining amount, so the last item is not included in the calculation
            const itemsByDepartment: Record<string, number[]> = {};

            for(const depId in amountsByDepartment) {
                if(departmentsById[depId]) {
                    const depItems = [];

                    for(let i = 0; i < partitions - 1; i++) {
                        depItems.push(MathUtils.round(amountsByDepartment[depId] * (percentages[i] / 100)));
                    }

                    depItems[partitions - 1] = MathUtils.round(amountsByDepartment[depId] - depItems.reduce((s, v) => s + v, 0));

                    itemsByDepartment[depId] = depItems;
                }
            }

            //Create the items groups, based on the items by department
            for(let i = 0; i < partitions; i++) {
                const itemsGroup = [];

                for(const depId in itemsByDepartment) {
                    const amount = itemsByDepartment[depId][i];
                    const department = departmentsById[depId];

                    itemsGroup.push({ department: department, amount: amount });
                }

                items.push(itemsGroup);
            }
        }

        const oldSaleUtils = this.injector.get(saleUtils);

        //Build the sale items
        for(const itemGroup of items) {
            const itemsToAdd = itemGroup.map((item) => oldSaleUtils.getDynamicSaleItemTemplate(item.department, item.amount));

            //If the group contains more than one item, set the parent sale item to the first item
            for(let i = 1; i < itemsToAdd.length; i++) {
                itemsToAdd[i].sale_item_parent_uuid = itemsToAdd[0].uuid;
            }

            saleItems.push(...itemsToAdd);
        }

        return saleItems;
    }

    /**
     * Calculates the sale prices for the target sale.
     * Modifies the target sale object in-place.
     *
     * @param {SalesCashregister} targetSale - The target sale object.
     */
    public calculateSalePrices(targetSale: SalesCashregister) {
        try {
            this.promotionEngine.applyPromotions(targetSale);
        } catch (e) {
            console.error("Error in promotions application");
        }

        const considerIngredientsRemoval = !!this.configurationManagerService.getPreference("orders.ingredients_removal_affects_price");
        const halfPortionValue = this.getHalfPortionValue();

        targetSale.amount = 0;
        targetSale.final_amount = 0;
        targetSale.final_net_amount = 0;

        //Make sure the sale_items array exists
        targetSale.sale_items = targetSale.sale_items || [];

        //STEP 1
        for (const saleItem of targetSale.sale_items) {
            //Calculate the partial unit price
            saleItem.$partialAmount = MathUtils.round(this.getItemPartialUnitPrice(saleItem, { considerIngredientsRemoval, halfPortionValue }) * saleItem.quantity);

            //Sort the price changes by index
            for (const priceChange of (saleItem.price_changes || []).sort((a, b) => a.index - b.index)) {
                let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, saleItem.$partialAmount);

                if (pcAmount != null) {
                    priceChange.amount = pcAmount;
                    saleItem.$partialAmount += pcAmount;
                }
            }

            targetSale.amount += MathUtils.round(saleItem.$partialAmount);
        }

        targetSale.amount = MathUtils.round(targetSale.amount);

        //STEP 2 split sale.price_changes
        targetSale.final_amount = targetSale.amount;

        let salePriceChangesAmount = 0;

        for (const priceChange of (targetSale.price_changes || []).sort((a, b) => a.index - b.index)) {
            const pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, targetSale.final_amount);

            if (pcAmount != null) {
                priceChange.amount = pcAmount;
                targetSale.final_amount += pcAmount;
                salePriceChangesAmount += pcAmount;
            }
        }

        targetSale.final_amount = MathUtils.round(targetSale.final_amount);
        salePriceChangesAmount = MathUtils.round(salePriceChangesAmount);

        // STEP 3 calculate final prices
        for (const saleItem of targetSale.sale_items) {
            const splitAmount = MathUtils.round((saleItem.$partialAmount! / targetSale.amount) * salePriceChangesAmount, 4) || 0;
            const finalRowPrice = MathUtils.round(saleItem.$partialAmount! + splitAmount, 4);

            saleItem.final_price = MathUtils.round(finalRowPrice / saleItem.quantity, 4) || 0;
            saleItem.final_net_price = MathUtils.round(saleItem.final_price / (1 + saleItem.vat_perc / 100), 4) || 0;

            targetSale.final_net_amount += MathUtils.round(saleItem.final_net_price * saleItem.quantity, 4);
        }

        targetSale.final_net_amount = MathUtils.round(targetSale.final_net_amount);
    }

    /**
     * Retrieves the cover configuration based on the shop preferences.
     *
     * @return {Promise<CoverConfiguration>} The cover configuration object
     */
    public async getCoverConfiguration(): Promise<CoverConfiguration> {
        let coverConfig: CoverConfiguration = { type: 'none' };

        if (this.configurationManagerService.getPreference('orders.automated_add_cover')) {
            try {
                switch (this.configurationManagerService.getPreference('orders.automated_add_cover.type')) {
                    case 'id':
                        const idCover = parseInt(this.configurationManagerService.getPreference('orders.automated_add_cover.value') || '') || 0;
                        const coverItem = await this.entityManagerService.items.fetchOneOffline(idCover);

                        if (coverItem) {
                            coverConfig = { type: 'item', data: coverItem };
                        } else {
                            throw 'MISSING_COVER_ITEM';
                        }
                        break;
                    case 'perc':
                        coverConfig = {
                            type: 'price_change',
                            data: {
                                type: "surcharge_perc",
                                description: this.translateService.instant('ORDERS.ACTIVE_ORDER.COVER_SURCHARGE'),
                                value: parseInt(this.configurationManagerService.getPreference('orders.automated_add_cover.value') || '') || 0
                            }
                        }
                        break;
                    default:
                        throw 'UNKNOWN_COVER_TYPE';
                }
            } catch (err) {
                coverConfig = { type: 'error', error: typeof err === 'string' ? err : 'UNKNOWN_ERROR' };
            }
        }

        return coverConfig;
    }

    /**
     * Applies a cover to a sale based on the given cover configuration.
     *
     * @param {Sales} targetSale - The sale to apply the cover to.
     * @param {CoverConfiguration} coverConfig - The configuration of the cover.
     */
    public async applyCoverToSale(targetSale: Sales, coverConfig: CoverConfiguration) {
        // Do nothing if the sale does not have a table or covers or if it's a child sale
        if (targetSale.sale_parent_uuid || !targetSale.table_id || !targetSale.covers) {
            return;
        }

        switch (coverConfig.type) {
            case 'item':
                const itemId = coverConfig.data.id;

                if (!itemId) {
                    return;
                }

                // Check if the cover item is already in the sale
                const currentCoverItem = targetSale.sale_items?.find((item) => item.item_id === itemId);

                // Add the cover item if it's not already in the sale
                if (!currentCoverItem) {
                    targetSale.sale_items = targetSale.sale_items || [];
                    const coverSaleItem = await this.getCoverSaleItem(targetSale, coverConfig.data, targetSale.covers);
                    targetSale.sale_items.push(coverSaleItem);
                }
                break;
            case 'price_change':
                const hasCoverPriceChange = targetSale.price_changes?.some((priceChange) => matches(priceChange, {
                    type: 'surcharge_perc',
                    description: coverConfig.data.description,
                    value: coverConfig.data.value
                }));

                if(!hasCoverPriceChange) {
                    const coverPriceChange = this.getGenericPriceChange(targetSale, 'surcharge_perc', coverConfig.data.value, coverConfig.data.description);
                    targetSale.price_changes = [...(targetSale.price_changes || []), coverPriceChange];
                }

                break;
            default:
                break;
        }
    }

    /**
     * Returns the current price list
     * @returns {number} the current price list
     */
    public getCurrentPriceList() {
        return this.configurationManagerService.getPreference('cashregister.enable_switch_price_list')
            ? parseInt(this.configurationManagerService.getPreference('price_list') || this.configurationManagerService.getSetting('price_list') || '1') || 1
            : parseInt(this.configurationManagerService.getSetting('price_list') || '1') || 1;
    }

    /**
     * Updates the current price list
     * @param {number} priceList - The new price list
     */
    public updateCurrentPriceList(priceList: number) {
        const currentPriceList = this.getCurrentPriceList();

        if(currentPriceList !== priceList) {
            this.configurationManagerService.setUserPreference("price_list", priceList);
        }
    }

    /**
     * Returns the default price list
     * @returns {number} the default price list
     */
    public getDefaultPriceList() {
        return parseInt(this.configurationManagerService.getSetting('price_list') || "1") || 1;
    }

    /**
     * Resets the price list
     *
     * @param {number} currentPriceList - The current price list.
     * @return {number} The default price list.
     */
    public resetPriceList(currentPriceList: number): number {
        const priceList = this.getDefaultPriceList();

        if(currentPriceList !== priceList) {
            this.configurationManagerService.setUserPreference("price_list", priceList);
        }

        return priceList;
    }

    /**
     * Retrieves the price list to use for a given sale.
     *
     * @param {Sales} [sale] - The sale object. If not provided, the function will return undefined.
     * @return {Promise<number | 'default'>} The price list to use, either a number or the string 'default'.
     */
    public async getSalePriceList(sale?: Sales) {
        if (!sale) {
            return;
        }

        // Priority 1: Customer pricelist
        if(sale.sale_customer?.default_pricelist) {
            return sale.sale_customer.default_pricelist;
        }

        // Priority 2: Room pricelist
        if (sale.room_id) {
            const saleRoom = await this.entityManagerService.rooms.fetchOneOffline(sale.room_id);

            if (saleRoom?.default_pricelist) {
                return saleRoom.default_pricelist;
            }
        }

        // Priority 3: Order type pricelist
        if(sale.order_type) {
            if ((sale.order_type === 'normal' && sale.table_id) || ['take_away', 'delivery'].includes(sale.order_type)) {
                const orderTypePriceList = parseInt(this.configurationManagerService.getPreference(`orders.${sale.order_type}_pricelist`) || '') || undefined;

                if (orderTypePriceList) {
                    return orderTypePriceList;
                }
            }
        }

        // Priority 4: Shift pricelist
        const bookingShifts = await this.entityManagerService.bookingShifts.fetchCollectionOffline();
        const suggestedShift = BookingUtils.getSuggestedShift(bookingShifts, sale.open_at, sale);

        if (suggestedShift?.default_pricelist) {
            return suggestedShift.default_pricelist;
        }

        // Default pricelist
        return 'default';
    };

    /**
     * Returns a cover sale item
     *
     * @param {Sales} sale - the sale object
     * @param {Items} coverItem - the cover item object
     * @param {number} [covers] - optional number of covers
     * @return {Promise<SalesItems>} A promise that resolves to the cover sale item
     */
    public async getCoverSaleItem(sale: Sales, coverItem: Items, covers?: number): Promise<SalesItems> {
        const timeNow = new Date().toISOString() as any;
        const opData = this.operatorManager.getOperatorData();

        const priceListCandidates = [this.getCurrentPriceList(), 1];
        const salePriceList = await this.getSalePriceList(sale);

        // If the sale has a specific price list, add it to the beginning of the price list candidates
        if (typeof salePriceList === 'number') {
            priceListCandidates.unshift(salePriceList);
        }

        const priceListToUse = priceListCandidates
            .map((priceList) => priceList ? `price${priceList}` as (`price${number}` & keyof Items) : null)
            .find((priceList) => priceList != null && coverItem[priceList] != null);

        const priceToUse = priceListToUse ? coverItem[priceListToUse]! : coverItem.price1;
        const vatPerc = coverItem.department?.vat?.value ?? coverItem.vat_perc!;

        return {
            added_at: timeNow,
            barcode: coverItem.barcodes?.[0]?.barcode,
            category_id: coverItem.category?.id,
            category_name: coverItem.category?.name,
            cost: coverItem.cost,
            department: coverItem.department!,
            department_id: coverItem.department?.id!,
            department_name: coverItem.department?.name,
            final_price: MathUtils.round(priceToUse),
            final_net_price: MathUtils.round(priceToUse / (1 + vatPerc / 100)),
            item_id: coverItem.id,
            lastupdate_at: timeNow,
            lastupdate_by: opData.id,
            name: coverItem.name,
            not_discountable: coverItem.not_discountable,
            price_changes: [],
            price: MathUtils.round(priceToUse),
            quantity: covers || sale.covers || 1,
            seller_id: opData.id,
            seller_name: opData.full_name,
            sku: coverItem.sku,
            type: "sale",
            uuid: generateUuid(),
            vat_perc: vatPerc,
        };
    }

    /**
     * Asks the user for a reason based on the given action.
     *
     * @param {ActionReason} action - the action for which the reason is being asked
     * @return {Promise<{ reason: string } | undefined>} the reason if selected, or undefined if no reason is selected or if no reasons are configured
     */
    public async askReason(action: ActionReason) {
        const settingName = action === 'store_sale' ? 'cashregister.store_sale_reasons' : 'cashregister.delete_sale_reasons';
        const notConfiguredMessage = action === 'store_sale' ? 'CASHREGISTER.ACTIVE_SALE.ARCHIVE_REASONS_NOT_CONFIGURED' : 'CASHREGISTER.ACTIVE_SALE.DELETE_REASONS_NOT_CONFIGURED';

        // get reasons from settings
        const reasonsSetting = this.configurationManagerService.getSetting(settingName) || '';

        const reasonsArr: { id: number, name: string }[] = reasonsSetting.trim().length > 0
            ? reasonsSetting.split('\n').map((reason, idx) => ({ id: idx + 1, name: reason.trim() }))
            : [];

        // if no reasons are configured, show an error
        if (!reasonsArr.length) {
            this.alertDialogService.openDialog({ data: { messageLabel: notConfiguredMessage } });
            return;
        }

        // ask for a reason
        const deleteReason = await this.openDialogsService.openRadioListSelectorDialog({ data: { elements: reasonsArr, label: 'CASHREGISTER.ACTIVE_SALE.REASON' } });

        return deleteReason ? { reason: deleteReason.name } : undefined;
    }

    /**
     * Returns a new array with the same elements as the input array, but with the properties
     * `id`, `sale_id`, `sale_item_id`, `created_at`, `updated_at`, and `deleted_at` removed from each element.
     *
     * @param {any[]} subEntity - The input array of objects.
     * @return {any[]} A new array with the same elements as the input array, but with the specified properties removed.
     */
    public getCleanSubEntity(subEntity: any[]) {
        return subEntity.map((subEntityItem) => {
            const { id, sale_id, sale_item_id, created_at, updated_at, deleted_at, ...cleanEntity } = subEntityItem;

            return cleanEntity;
        });
    }
}
