import {
	inject,
	LOCALE_ID,
	Provider
} from '@angular/core';

import {
	TranslateService
} from '@ngx-translate/core';

export class LocaleId extends String {
	private readonly translate = inject(TranslateService);

	toString(): string {
		return this.translate.currentLang;
	}

	valueOf(): string {
		return this.toString();
	}
}

export const LocaleProvider: Provider = {
	provide: LOCALE_ID,
	useClass: LocaleId,
	deps: [TranslateService],
};
