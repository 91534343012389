import {
    Component,
    Injectable,
    inject,
    signal
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport
} from "@angular/cdk/scrolling";

import {
    CommonModule
} from "@angular/common";

import {
    MatInputModule
} from "@angular/material/input";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    lastValueFrom
} from "rxjs";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    MatFormFieldModule
} from "@angular/material/form-field";

import {
    MatSelectModule
} from "@angular/material/select";

import {
    MatIconModule
} from "@angular/material/icon";

import {
    FormsModule,
    ReactiveFormsModule
} from "@angular/forms";

import {
    ConnectionService,
    EntityManagerService
} from "src/app/core";


import {
    KeypadPinComponent
} from "src/app/shared/components/keypad-pin/keypad-pin.component";

import {
    UppercaseFirstLetterPipe
} from "src/app/pipes/uppercase-firstletter.pipe";

import {
    mobileCheck
} from "@tilby/tilby-ui-lib/utilities";

import {
    User
} from "src/app/models";

type UserSessionsManagerDialogData = {
    options?: UserSessionsManagerDialogOptions
    users: User[],
    activeUser?: User
};

type UserSessionsManagerDialogInput = Omit<UserSessionsManagerDialogData, 'users' | 'activeUser'>;

type UserSessionsManagerDialogOptions = {
    disableNewUser?: boolean,
    canDismiss?: boolean,
    selectActiveUser?: boolean
};

@Component({
    selector: 'user-sessions-manager',
    templateUrl: './user-sessions-manager-dialog.component.html',
    styleUrls: ['./user-sessions-manager-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatInputModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        CdkVirtualScrollViewport,
        KeypadPinComponent,
        UppercaseFirstLetterPipe
    ],
})
export class UserSessionsManagerComponent {
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly connection = inject(ConnectionService);
    protected readonly data: UserSessionsManagerDialogData = inject(MAT_DIALOG_DATA);

    isMobile = mobileCheck();
    private users = this.data.users;

    protected pincode: string = '';
    protected userFilter: string = '';
    protected pinDigitClass: string[] = new Array(4).fill('');
    protected usersFiltered = this.users;
    protected userSelected?: User;
    protected hideUsers = false;
    protected openFilter = false;
    protected openPinKeypad = false;
    protected blockPinKeypad = false;
    protected canDismiss = !!this.data.options?.canDismiss;
    protected showNewUser = this.data.options?.disableNewUser ? signal(false) : this.connection.isOnline;

    async ngOnInit() {
        if(this.data.activeUser) {
            this.userSelected = this.data.activeUser;
            this.hideUsers = true;
        }
    }

    userFilterOpen() {
        this.openFilter = !this.openFilter;

        if (!this.openFilter) {
            this.userFilter = '';
        } else {
            setTimeout(() => {
                const userFilterInput = document.getElementById('userFilterInput') as HTMLElement;

                if (userFilterInput) {
                    userFilterInput.focus();
                }
            }, 100)
        }
    }

    clearUserFilter() {
        this.openFilter = false;
        this.userFilter = '';
        this.usersFiltered = this.users;
    }

    selectUserSession(user: User) {
        this.clearPinField();
        this.userSelected = user;

        if (!user.pin) {
            this.returnUser(user);
        } else {
            this.openPinKeypad = true;
            this.matDialogRef.updateSize('505px', '465px');
        }

        this.openFilter = false;
        this.userFilter = '';
        this.usersFiltered = this.users;
    };

    clearPinField() {
        this.pincode = '';
        this.pinDigitClass.fill('');
    };

    /**
     * Handle the change of the pincode input.
     * @param pin the current pincode
     */
    onDigitChange(pin: string) {
        if (!pin) {
            // If the pincode is empty, clear the pin digit
            return this.clearPinField();
        }

        // If the user is not selected or the pin keypad is blocked, clear the pincode
        if (!this.userSelected || this.blockPinKeypad) {
            this.pincode = '';
            return;
        }

        // Notify the user that the digits are being entered
        for (let i = 0; i < pin.length; i++) {
            this.pinDigitClass[i] = 'circle-pin-correct';
        }

        // Don't proceed if the pincode is not 4 digits
        if (pin.length !== 4) {
            return;
        }

        // If the pincode is incorrect, set the pin digit class to incorrect
        if (pin !== this.userSelected.pin) {
            this.pinDigitClass.fill('circle-pin-incorrect');

            // Block the pin keypad for 2 seconds
            this.blockPinKeypad = true;

            setTimeout(() => {
                this.clearPinField();
                this.blockPinKeypad = false;
            }, 2000);

            return;
        }

        // If the pincode is correct, return the user to the caller
        this.returnUser(this.userSelected);
    }

    onFilterChange(event: any) {
        this.userFilter = event.target.value;

        // Reset the filtered users
        this.usersFiltered = this.users;
        
        if(!this.userFilter) {
            return;
        }

        const lowercaseFilter = this.userFilter.toLowerCase().split(' ');

        for(const word of lowercaseFilter) {
            this.usersFiltered = this.usersFiltered.filter(user => user.first_name?.toLowerCase().includes(word) || user.last_name?.toLowerCase().includes(word));
        }
    };

    returnUser(user: User) {
        this.matDialogRef.close(user);
    };

    newLogin() {
        this.matDialogRef.close();
    };
}

@Injectable({
    providedIn: "root",
})
export class UserSessionsManagerDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    private readonly entityManager = inject(EntityManagerService);

    public async openDialog(config?: NonNullableConfigData<UserSessionsManagerDialogInput>): Promise<User | undefined | ''> {
        const users =  await this.entityManager.userSessions.getCollection();
        let activeUser: User | undefined;

        if(config?.data.options?.selectActiveUser) {
            // If user doesn't have the pin field, skip check
            activeUser = users.find(user => user.active);
    
            if(activeUser && !activeUser.pin) {
                return activeUser;
            }
        }

        const data: UserSessionsManagerDialogData = {
            options: config?.data.options || {
                disableNewUser: false,
                canDismiss: false,
                selectActiveUser: false
            },
            activeUser: activeUser,
            users: users
        };

        const configEdited: NonNullableConfigData<UserSessionsManagerDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ minWidth: '300px', height: '465px' }),
            disableClose: true,
            data,
        };

        return lastValueFrom(
            this.dialogRef.open(UserSessionsManagerComponent, configEdited).afterClosed()
        );
    }

    /**
     * @deprecated needed for backwards compatibility, use `openDialog` instead
     */
    public show(options: UserSessionsManagerDialogOptions): Promise<User | undefined> {
        return this.openDialog({
            data: {
                options
            }
        }).then((result) => result === '' ? Promise.reject() : result);
    }
}
