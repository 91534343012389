<tilby-dialog-toolbar title="{{message}}" [hideConfirm]="!multiple" (confirm)="confirmMultiple()"/>
<tilby-dialog-content>
    <mat-card>
        <mat-card-content>
            <section>
                <cdk-virtual-scroll-viewport appendOnly itemSize="50" [style.height]="calculatedHeight">
                    <div *cdkVirtualFor="let item of data.items">
                        <div class="tw-flex tw-py-3" (click)="selectPayment(item)">
                            @if(itemIcon){
                                <mat-icon alt="Menu Icon" class="item-list-selector-row-icon">{{itemIcon}}</mat-icon>
                            }
                            <span style="margin-left: 16px;" flex class="item-list-selector-row-name">{{item.name}}</span>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </cdk-virtual-scroll-viewport>
            </section>
            @if(canSetAsDefault){
                <section>
                    <mat-slide-toggle [checked]="setAsDefault" [(ngModel)]="setAsDefault"> {{ 'APPLICATION.ITEM_LIST_SELECTOR.SET_AS_DEFAULT' | translate }} </mat-slide-toggle>
                </section>
            }
        </mat-card-content>
    </mat-card>
</tilby-dialog-content>