import angular from 'angular';

import {
    Departments,
    Items,
    Stock
} from 'tilby-models';

import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';

import {Transition} from "angular-ui-router";

import {
    CashregisterStateService,
    parseItemsCollection,
    StockDictionaryEntry,
    StockDictionaryKey,
} from 'src/app/features';

import { pickBy } from 'src/app/shared/utils';

const stockFieldsToPick: Set<string> = new Set(['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity']);

angular.module('cashregister', ['application']).config(["$stateProvider", "$translatePartialLoaderProvider", "$mdThemingProvider", function($stateProvider: any, $translatePartialLoaderProvider: any, $mdThemingProvider: any) {
    $translatePartialLoaderProvider.addPart('cashregister');

    $stateProvider.state('app.new.cashregister', {
        url: '/cashregister',
        redirectTo: 'app.new.cashregister.content.showcase',
        template: '<cashregister-new-component [items]="$resolve.items" [stock-dictionary]="$resolve.stockDictionary" [categories]="$resolve.categories" [departments]="$resolve.departments" [delivery-channels]="$resolve.deliveryChannels"></cashregister-new-component>',
        resolve: {
            items: ["entityManager", (entityManager: EntityManagerService) => 
                entityManager.items.fetchCollectionOffline({ on_sale: true })
                    .then((items) => parseItemsCollection(items))
            ],
            stockDictionary: ["entityManager", async (entityManager: EntityManagerService) => {
                return entityManager.stock.fetchCollectionOffline().then(stockCollection => {
                    const result = new Map<StockDictionaryKey, StockDictionaryEntry>();

                    for(const stock of stockCollection) {
                        if (stock.combination_id) {
                            result.set(`combination_id_${stock.combination_id}`, pickBy(stock, (val, key) => stockFieldsToPick.has(key)));
                        } else if (stock.item_id) {
                            result.set(`item_id_${stock.item_id}`, pickBy(stock, (val, key) => stockFieldsToPick.has(key)));
                        }
                    }
    
                    return result;
                });
            }],
            categories: ["entityManager", (entityManager: EntityManagerService) =>
                entityManager.categories.fetchCollectionOffline()
            ],
            departments: ["entityManager", (entityManager: EntityManagerService) =>
                entityManager.departments.fetchCollectionOffline()
            ],
            deliveryChannels: ["entityManager", (entityManager: EntityManagerService) =>
                entityManager.channels.fetchCollectionOffline()
                    .then((channels) => channels.filter((channel) => channel.id !== 'pos'))
            ]
        },
        controller: ["$scope", "checkManager", "entityManager", "items", "departments", "stockDictionary", function($scope: any, checkManager: ConfigurationManagerService, entityManager: EntityManagerService, items: Record<string, Items[]>, departments: Departments[], stockDictionary: Map<StockDictionaryKey, StockDictionaryEntry>) {
            $scope.$on("storage-updated:departments", async (_event:unknown, _data:any) => {
                const newDepartments = await entityManager.departments.fetchCollectionOffline();
                angular.copy(newDepartments, departments);

                $scope.$broadcast("cashregister-showcase:update-departments");
            });

            $scope.$on("storage-updated:items", async (_event:unknown, data:{ action: string, id: number }) => {
                if (!data.id) {
                    if(data.action !== 'DELETED') { //DELETED happens when reloading the items, so wait for the UPDATED event instead
                        const result = await entityManager.items.fetchCollectionOffline({ on_sale: true });

                        for (const key in items) {
                            delete items[key];
                        }

                        Object.assign(items, parseItemsCollection(result));

                        $scope.$broadcast("cashregister-showcase:update-items");
                    }
                } else {
                    switch (data.action) {
                        case 'UPDATED': case 'CREATED':
                            const src = await entityManager.items.fetchOneOffline(data.id);

                            if (!src) {
                                return;
                            }

                            if (!src.favorite) {
                                items.favorite = items.favorite?.filter(item => item.id !== src.id) || [];
                            }

                            const { favorite, ...catToScan } = items;

                            let dst;

                            Object.entries(catToScan).find(([categoryId, itemsInCategory]) => {
                                const srcCatId = src.category_id ? src.category_id.toString() : "null";

                                if (srcCatId !== categoryId) {
                                    itemsInCategory.filter((item) => item.id !== src.id);
                                    return false;
                                }

                                const foundInCategory = itemsInCategory.find((item) => item.id === data.id);

                                if (foundInCategory) {
                                    dst = foundInCategory;
                                } else {
                                    if (srcCatId === categoryId) {
                                        itemsInCategory.push(src);
                                    }
                                }

                                return !!foundInCategory;
                            });

                            if (dst) {
                                if (src !== dst) {
                                    angular.copy(src, dst);
                                }
                            }

                            if (src.favorite) {
                                const isAlreadyFavorite = items.favorite?.find((item) => item.id === src.id);

                                if (!isAlreadyFavorite) {
                                    items.favorite?.push(dst || src);
                                }
                            }

                            $scope.$broadcast("cashregister-showcase:update-items");
                            break;
                        case 'DELETED':
                            Object.values(items).forEach(itemsInCategory => {
                                itemsInCategory.filter((item) => item.id !== data.id);
                            });

                            $scope.$broadcast("cashregister-showcase:update-items");
                            break;
                        default:
                            $scope.$broadcast("cashregister-showcase:update-items");
                            break;
                    }
                }
            });

            $scope.$on("stock-updated", function(_event: unknown, stockMessage: Stock) {
                if (!!checkManager.getPreference('cashregister.check_stock')) {
                    const dictIndex: StockDictionaryKey | undefined = stockMessage.combination_id
                        ? `combination_id_${stockMessage.combination_id}`
                        : stockMessage?.item_id
                            ? `item_id_${stockMessage.item_id}`
                            : undefined;

                    if(!dictIndex) {
                        return;
                    }

                    const prevStock = stockDictionary.get(dictIndex);
                    stockDictionary.set(dictIndex, pickBy(stockMessage, (val, key) => stockFieldsToPick.has(key)));

                    if (prevStock?.available !== stockMessage.available) {
                        CashregisterStateService.notifyStockSubject.next(stockMessage);
                    }
                }

                $scope.$broadcast('activeSale:updateSaleStock');
            });
        }],
        params: {
            action: { type: 'string', value: null, dynamic: true },
            advancedpayments: { type: 'bool', value: false, dynamic: true },
            autohide: { type: 'bool', value: false,  dynamic: true },
            from: null,
            id: null,
            saleType: null,
            room: null,
            table: null,
            booking: null,
            customer: null,
            longPress: false
        }
    });

    $stateProvider.state('app.new.cashregister.sale', {
        url: '/sale/:id?advancedpayments&autohide',
        params: {
            id: { type: 'int', value: null },
            advancedpayments: { type: 'bool', value: false,  dynamic: true },
            autohide: { type: 'bool', value: false, dynamic: true }
        },
        redirectTo: function(transition: Transition) {
            const params = transition.params();

            const pickedParams = {
                id: params.id,
                autohide: params.autohide,
                advancedpayments: params.advancedpayments,
                action: 'open-sale-id'
            };

            return transition.router.stateService.target('app.new.cashregister.content.showcase', pickedParams, { reload: 'app.new.cashregister' });
        }
    });

    $stateProvider.state('app.new.cashregister.daily-closing', {
        url: '/dailyclosing?autohide',
        params: {
            id: { type: 'int', value: null },
            autohide: { type: 'bool', value: false }
        },
        redirectTo: function(transition: Transition) {
            const params = transition.params();

            const pickedParams = {
                autohide: params.autohide,
                action: 'daily-closing'
            };

            return transition.router.stateService.target('app.new.cashregister.content.showcase', pickedParams, { reload: 'app.new.cashregister' });
        }
    });

    $stateProvider.state('app.new.cashregister.content', {
        abstract: true,
        views: {
            "two": {
                template: '<active-sale-component/>',
            }
        },

    });

    $stateProvider.state('app.new.cashregister.content.showcase', {
        url: '/showcase',
        views: {
            "one@app.new.cashregister": {
                template: '<cashregister-showcase-component/>'
            }
        },
    });

    $stateProvider.state('app.new.cashregister.content.payments', {
        url: '/payments',
        onEnter: ["$state", "ActiveSaleNew", function($state:any, ActiveSale:any) {
            if (!ActiveSale.isActiveSale()) {
                $state.go('app.new.cashregister.content.showcase');
            }
        }],
        views: {
            "one@app.new.cashregister": {
                template: `<cashregister-payments-component [payment-methods]="$resolve.paymentMethods" [tickets-collection]="$resolve.ticketsCollection"/>`
            }
        },
        resolve: {
            paymentMethods: ["entityManager", (entityManager:EntityManagerService) => entityManager.paymentMethods.fetchCollectionOffline()],
            ticketsCollection: ["entityManager", (entityManager:EntityManagerService) => entityManager.tickets.fetchCollectionOffline()]
        }
    });

    /* Add New States Above */
    $mdThemingProvider.theme('satispayTheme')
        .primaryPalette('red', {
            'default': '500'
        })
        .accentPalette('red', {
            'default': '50'
        });

    $mdThemingProvider.theme('eatsreadyTheme')
        .primaryPalette('red', {
            'default': 'A200'
        })
        .accentPalette('red', {
            'default': '50'
        });

    $mdThemingProvider.theme('spiaggeItTheme')
        .primaryPalette('amber', {
            'default': '500'
        })
        .accentPalette('amber', {
            'default': '50'
        });
}]);
