import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BookingUtils } from 'src/app/shared/booking-utils.service';

angular.module('bookings').factory('newBookingDialog', newBookingDialog);

newBookingDialog.$inject = ["$mdDialog", "$translate", "$state", "entityManager", "addSelectCustomer", "confirmDialog", "checkManager", "util", "saleUtils", "toast", "alertDialog"];

function newBookingDialog($mdDialog, $translate, $state, entityManager, addSelectCustomer, confirmDialog, checkManager, util, saleUtils, toast, alertDialog) {
    newBookingDialogController.$inject = ["$scope", "bookingShifts", "bookings", "rooms", "options"];

    function newBookingDialogController($scope, bookingShifts, bookings, rooms, options) {
        $scope.booking = _.isObject(options.booking) ? _.cloneDeep(options.booking) : {
            duration: _.toInteger(checkManager.getPreference('bookings.slot_duration') || 30),
            people: 1,
            status: 'confirmed'
        };

        $scope.customerByPmsReservationId = null;
        if($scope.booking.pms_reservation_id) {
            $scope.customerByPmsReservationId = `${$scope.booking.pms_reservation_name} (${$scope.booking.pms_reservation_id})`;
        }

        $scope.sendingBooking = false;

        //Init tables
        var tables = [];

        _.forEach(rooms, function(room) {
            //TODO: exclude disabled booking tables
            if(_.isArray(room.tables)) {
                _.forEach(room.tables, function(table) {
                    if(!table.name.startsWith('ph_')) {
                        tables.push({
                            room_name: room.name,
                            room_id: room.id,
                            table_name: table.name,
                            table_id: table.id,
                            covers: table.covers,
                            type: table.order_type,
                            selected: false,
                            available: true
                        });
                    }
                });
            }
        });

        var tablesById = _.keyBy(tables, 'table_id');

        var tablesOccupations = {};

        _.forEach(bookings, function(booking) {
            if(booking.id !== $scope.booking.id && booking.tables) {
                _.forEach(booking.tables, function(table) {
                    var dateStart = moment(booking.booked_for);

                    var tableOccupation = {
                        start: dateStart,
                        end: moment(dateStart).add(booking.duration, 'minutes')
                    };

                    if(_.isArray(tablesOccupations[table.table_id])) {
                        tablesOccupations[table.table_id].push(tableOccupation);
                    } else {
                        tablesOccupations[table.table_id] = [tableOccupation];
                    }
                });
            }
        });

        var orderTablesByAvailability = function() {
            var bookingStart = moment($scope.booking._bookedFor);
            var bookingEnd = moment(bookingStart).add($scope.booking.duration, 'minutes');

            var tablesByFitType = _.groupBy(tables, function(table) {
                if(tablesOccupations[table.table_id]) {
                    table.available = _.every(tablesOccupations[table.table_id], function(occupation) {
                        return !util.checkDateRangeOverlap(bookingStart, bookingEnd, occupation.start, occupation.end);
                    });

                    if(!table.available) {
                        return 'unavailable';
                    }
                }

                if(table.covers === $scope.booking.people) {
                    return 'fit_exactly';
                } else if(table.covers > $scope.booking.people) {
                    return 'fit';
                } else {
                    return 'dont_fit';
                }
            });

            $scope.availableTables = _.concat(tablesByFitType.fit_exactly || [], _.orderBy(tablesByFitType.fit || [], ['covers'], 'asc'), _.orderBy(tablesByFitType.dont_fit || [], ['covers'], 'desc'), _.orderBy(tablesByFitType.unavailable || [], ['covers'], 'desc'));
        };

        if($scope.booking.id) {
            $scope.dialogTitle = $translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.TITLE_EDIT');

            _.assign($scope.booking, {
                _bookedFor: moment($scope.booking.booked_for).toDate(),
                _customerName: util.getCustomerCaption($scope.booking.booking_customer)
            });
        } else {
            $scope.dialogTitle = $translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.TITLE_ADD');

            var bookingTable;

            if(options.tableId) {
                $scope.booking.tables = [{
                    table_id: options.tableId
                }];

                bookingTable = tablesById[options.tableId];
            }

            if(options.dateStart) {
                var dateStart = moment(options.dateStart);
                var dateEnd;

                $scope.booking._bookedFor = dateStart.toDate();

                if(options.dateEnd) {
                    dateEnd = moment(options.dateEnd);
                    $scope.booking.duration = moment.duration(dateEnd.diff(dateStart)).asMinutes();
                } else {
                    dateEnd = moment(dateStart).add($scope.booking.duration, 'minutes');
                }

                var suggestedShift = BookingUtils.getSuggestedShift(bookingShifts, dateStart, bookingTable);

                if(suggestedShift) {
                    $scope.booking.shift_id = suggestedShift.id;

                    if(!options.dateEnd) {
                        $scope.booking.duration = suggestedShift.default_duration;
                    }
                }
            }

            if (options.customer) {
                _.assign($scope.booking, {
                    customer_id: options.customer.id,
                    booking_customer: options.customer,
                    _customerName: util.getCustomerCaption(options.customer)
                });
            }
        }

                _.forEach($scope.booking.tables, function(table) {
            _.set(tablesById, [table.table_id, 'selected'], true);
        });

        $scope.bookingShifts = bookingShifts;
        var bookingShiftsById = _.keyBy(bookingShifts, 'id');

        //Init Statuses
        $scope.bookingStatuses = [];

        _.forEach(['provisional', 'confirmed', 'arrived', 'seated', 'departed', 'noshow'], function(status) {
            $scope.bookingStatuses.push({
                id: status,
                name: $translate.instant('BOOKINGS.STATUSES.' + _.toUpper(status))
            });
        });

        orderTablesByAvailability();

        $scope.onShiftChanged = function() {
            var bookingShift = bookingShiftsById[$scope.booking.shift_id];

            if(bookingShift) {
                var roomRestrictions = {};

                _.forEach(bookingShift.room_restrictions, function(roomRestr) {
                    roomRestrictions[roomRestr.room_id] = true;
                });

                $scope.availableTables = _.reject(tables, function(table) {
                    return roomRestrictions[table.room_id];
                });
            } else {
                $scope.availableTables = tables;
            }

            orderTablesByAvailability();
        };

        $scope.onBookedForChange = function() {
            var bookingStart = moment($scope.booking._bookedFor);
            var suggestedShift = BookingUtils.getSuggestedShift(bookingShifts, bookingStart, bookingTable);

            $scope.booking.shift_id = _.get(suggestedShift, 'id');
            $scope.onShiftChanged();
        };

        $scope.addEditCustomer = function() {
            addSelectCustomer.show({ customer_id: $scope.booking.customer_id }).then(function(customer) {
                _.assign($scope.booking, {
                    customer_id: customer.id,
                    _customerName: util.getCustomerCaption(customer),
                    booking_customer: _.pick(customer, ['id', 'first_name', 'last_name', 'company_name'])
                });
            });
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = async function () {
            if ($scope.sendingBooking) {
                return;
            }

            $scope.sendingBooking = true;

            try {
                Object.assign($scope.booking, {
                    tables: $scope.availableTables.filter((table) => table.selected).map((table) => ({ table_id: table.table_id })),
                    booked_for: $scope.booking._bookedFor.toISOString(),
                });

                const saveFunction = $scope.booking.id ? 'putOneOnline' : 'postOneOnline';
                const { _bookedFor, booking_customer, _customerName, ...bookingToSave } = structuredClone($scope.booking);

                const booking = await entityManager.bookings[saveFunction](bookingToSave);

                try {
                    await $mdDialog.hide(booking);
                } catch (err) {
                }

                //Create an order for the booking if the customer is arrived or seated
                const tableCreationTriggers = ['seated', 'arrived'];

                if (
                    _.isEmpty(booking.tables) ||
                    !tableCreationTriggers.includes(booking.status) ||
                    tableCreationTriggers.includes(options?.booking?.status)
                ) {
                    return;
                }

                const customer = await entityManager.customers.fetchOneOffline(booking.booking_customer.id);

                const sales = await entityManager.sales.fetchCollectionOffline();
                const targetTable = booking.tables.find((table) => tablesById[table.table_id].type === 'multiple' || !sales.some((sale) => sale.table_id === table.table_id));

                if (!targetTable) {
                    alertDialog.show($translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.NO_FREE_TABLE'));
                    return;
                }

                const saleCustomer = Object.assign(structuredClone(customer || {}), {
                    customer_id: customer.id,
                    id: null
                });

                const tableInfo = tablesById[targetTable.table_id];

                let sale = await saleUtils.getSaleTemplate();
                sale = { ...sale, "name": $translate.instant('TABLES_NEW.DIALOGS.SALE.NAME_TEMPLATE'), "order_type": "normal", "table_id": tableInfo.table_id, "table_name": tableInfo.table_name, "room_id": tableInfo.room_id, "room_name": tableInfo.room_name, "covers": booking.people, "booking_id": booking.id };
                sale.sale_customer = saleCustomer;
                const postedSale= await entityManager.sales.postOneOnline(sale);

                toast.show({
                    message: $translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.ORDER_CREATED'),
                    actions: [{
                        text: $translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.ORDER_DETAILS'),
                        action: 'details',
                        highlight: true
                    }],
                    hideDelay: 0
                }).then(function (action) {
                    if (action === 'details') {
                        $state.go('app.new.cashregister.content.showcase', {
                            action: 'open-sale-id',
                            id: postedSale.id,
                            saleType: postedSale.order_type
                        });
                    }
                });
            } finally {
                $scope.sendingBooking = false;
            }
        };

        $scope.deleteBooking = function() {
            confirmDialog.show($translate.instant('BOOKINGS.NEW_BOOKING_DIALOG.DELETE_CONFIRM')).then(function(answer) {
                if(answer) {
                    $scope.sendingBooking = true;
                    entityManager.bookings.deleteOneOnline($scope.booking.id).then(function() {
                        $mdDialog.hide();
                    }, function(error) {
                        $scope.sendingBooking = false;
                    });
                }
            });
        };

        $scope.selectTable = function(table) {
            table.selected = !table.selected;
        };

        $scope.increaseDuration = function() {
            $scope.booking.duration += 10;
            orderTablesByAvailability();
        };

        $scope.decreaseDuration = function() {
            if($scope.booking.duration >= 20) {
                $scope.booking.duration -= 10;
                orderTablesByAvailability();
            } else if($scope.booking.duration > 10) {
                $scope.booking.duration = 10;
                orderTablesByAvailability();
            }
        };

        $scope.increasePeople = function() {
            $scope.booking.people++;
            orderTablesByAvailability();
        };

        $scope.decreasePeople = function() {
            if($scope.booking.people > 1) {
                $scope.booking.people--;
                orderTablesByAvailability();
            }
        };
    }

    var newBookingDialog = {
        show: function(options) {
            if(!_.isObject(options)) {
                options = {};
            }

            return $mdDialog.show({
                controller: newBookingDialogController,
                template: require('./new-booking-dialog.html'),
                multiple: true,
                locals: {
                    options: options
                },
                resolve: {
                    bookingShifts: function() {
                        return entityManager.bookingShifts.fetchCollectionOffline();
                    },
                    bookings: function() {
                        return entityManager.bookings.fetchCollectionOffline();
                    },
                    rooms: function() {
                        return entityManager.rooms.fetchCollectionOffline();
                    }
                }
            });
        }
    };

    return newBookingDialog;
}
