import angular from 'angular';

import {
    GetFoliosResponse,
    BedzzleCommonTransaction
} from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';

export class SpiaggeItApiService {
    constructor(
        private restManager: any
    ) {
    }

    public setupShop(paymentMethodId: number, propertyId: string): Promise<any> {
        return this.restManager.post('spiagge_it/setup', { propertyId: propertyId }, { tilbyPaymentId: paymentMethodId });
    }

    public getRoomFolios(paymentMethodId: number): Promise<GetFoliosResponse> {
        return this.restManager.getList('spiagge_it/room_folios', { tilbyPaymentId: paymentMethodId });
    }

    public getMiscSalesFolios(paymentMethodId: number): Promise<GetFoliosResponse> {
        return this.restManager.getList('spiagge_it/misc_sales_folios', { tilbyPaymentId: paymentMethodId });
    }

    public createTransaction(paymentMethodId: number, transactionData: BedzzleCommonTransaction) {
        return this.restManager.post('spiagge_it/transactions', transactionData, { tilbyPaymentId: paymentMethodId });
    }

    public rollbackTransaction(paymentMethodId: number, outletId: string, orderId: string) {
        return this.restManager.deleteOne('spiagge_it/transactions', null, { orderId: orderId, outletId: outletId, tilbyPaymentId: paymentMethodId });
    }
};

SpiaggeItApiService.$inject = ["restManager"];

angular.module('application').factory('spiaggeItApi', SpiaggeItApiService);