import {
    Component,
    computed,
    inject,
    Injectable,
    signal
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
} from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";
import { DevLogger } from "../../../shared/dev-logger";
import { Categories, Departments, Items } from "tilby-models";
import {
    CashregisterStateService,
    StockDictionary
} from "../../../features";
import {
    CarouselImagesSliderComponent,
    CarouselSlide
} from "../../../shared/components/carousel-images-slider";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
    ConfigurationManagerService,
    ConnectionService,
    EntityManagerService
} from "src/app/core";

import {
    AlertDialogService,
    OpenDialogsService
} from "src/app/dialogs";

import { util } from "app/ajs-upgraded-providers";
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    KeyValueIconImage,
    TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { keyBy } from "@tilby/tilby-ui-lib/utilities";
import { Validators } from "@angular/forms";

export type CashregisterSaleItemDialogData = {
    item:Items;
    stockDictionary : StockDictionary | undefined;
    departments: Departments[],
    categories: Categories[]
};
type ItemFormValuePartialPrices= Partial<Pick<Items, 'price1'|'price2'|'price3'|'price4'|'price5'|'price6'|'price7'|'price8'|'price9'|'price10'>>;
type ItemFormValuePartialDepartments= Partial<Pick<Items, 'department'|'department2'|'department3'|'department4'|'department5'|'department6'|'department7'|'department8'|'department9'|'department10'>>;
type SaleItemFormValue =
    Pick<Items, 'favorite'|'on_sale'|'index'|'name'|'category'|'description'>
    & ItemFormValuePartialPrices
    & ItemFormValuePartialDepartments

/**
 * Gestisce l'utilizzo della nuova dialog di Tilby.
 *

 * @description
 * Questa funzione guida l'utente attraverso i passaggi necessari per integrare e utilizzare la nuova dialog di Tilby.
 * Segui i seguenti passaggi:
 * 1. Copia la cartella [tilby-dialog](../tilby-dialog) nel tuo progetto.
 * 2. Attiva i tag HTML necessari da [tilby-dialog.component.html](./tilby-dialog.component.html) e incollali in una nuova cartella.
 * 3. Rinomina il file TypeScript seguendo le tue specifiche.
 * Assicurati di gestire eventuali eccezioni durante l'esecuzione.
 * Per ulteriori dettagli, consulta i link forniti per la cartella e il file HTML.
 */

@Component({
    selector: "app-cashregister-sale-item-dialog",
    standalone: true,
    imports: [
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        CarouselImagesSliderComponent,
        TranslateModule,
        TilbyMagicFormComponent
    ],
    templateUrl: "./cashregister-item-dialog.component.html",
    styleUrls: ["./cashregister-item-dialog.component.scss"],
})
export class CashregisterItemDialogComponent {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly connectionService = inject(ConnectionService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);
    private readonly utilService = inject(util);
    protected readonly data: CashregisterSaleItemDialogData = inject(MAT_DIALOG_DATA);

    protected readonly isItemInStock = !!this.configurationManagerService.getPreference('cashregister.show_stock_on_showcase') && this.data.item.stock_type != null;
    private departmentMap = keyBy(this.data.departments,(department)=>department.id);
    private categoryMap = keyBy(this.data.categories,(category)=>category.id);

    protected optionNames:(string|null)[]=[];
    protected customActions: GeneralInputButton[] = [
        {
            isIt: () => !this.isEditMode(),
            isDisable: ()=>this.connectionService.isOffline(),
            name: "",
            icon:()=>"edit",
            click: () => this.goToEditMode(),
        },
        {
            isIt: () => this.isEditMode(),
            isDisable: ()=>this.connectionService.isOffline(),
            name: "DIALOG.ITEM.EDIT.SAVE",
            class:'tilby-body-primary-color disabled:tw-opacity-50 tw-mr-6',
            click: async () => await this.save(),
        }
    ];
    protected carouselImages: CarouselSlide[] = this.data.item.images?.length
        ? this.data.item.images?.map(({image_url}) => ({image:image_url}))    // USE BIG IMAGES
        : (this.data.item.thumbnail
            ?[{image: this.data.item.thumbnail}]        // FALLBACK ON THUMBNAIL IMAGE
            :[]
        );

    protected isEditMode=signal(false);
    protected savingLoader=signal(false);
    private itemInStock=signal(this.data.item.id?this.data.stockDictionary?.get(`item_id_${this.data.item.id}`):undefined);
    protected itemStockQuantity= computed(()=>this.itemInStock()? this.utilService.round(this.itemInStock()?.stock_quantity, 4)||'-' : '-');
    protected quantity=computed(()=>`${this.itemStockQuantity()} ${this.data.item.unit||''}`);
    protected supplierName?:string;
    protected itemForm: CustomFormGroup<CustomForm<SaleItemFormValue>> = this.createItemForm(this.data.item);
    isCheckStockEnabled = this.configurationManagerService.getPreference('cashregister.check_stock');

    constructor() {
        for(let i=1;i<=4;i++) {
            this.optionNames[i] = this.configurationManagerService.getPreference('items.option1_name');
        }
    }

    async ngOnInit() {
        this.log('INIT', {data:this.data});
        if(this.data.item.default_supplier_id){
            this.supplierName = (await this.entityManagerService.suppliers.fetchOneOnline(this.data.item.default_supplier_id))?.name;
        }
    }

    private createItemForm(item:SaleItemFormValue){
        const categoriesChoices:KeyValueIconImage<string>[] = Object.entries(this.categoryMap||{}).map(([k,v])=>({key:v.name,value:k}));
        return new CustomFormGroup<CustomForm<SaleItemFormValue>>({
            favorite: new CustomFormControl(item.favorite,{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.FAVORITE',class:"tw-w-1/4", inputType:"slideToggle"}),
            on_sale: new CustomFormControl(item.on_sale,{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.ON_SALE',class:"tw-w-1/4", inputType:"slideToggle"}),
            index: new CustomFormControl(item.index,{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.INDEX',class:"tw-w-1/4",inputType:"number"}),
            name: new CustomFormControl(item.name,{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.NAME',class:"tw-w-full"}),
            category: new CustomFormControl(item.category?.id?.toString(),{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.CATEGORY',class:"tw-w-full", inputType:"select", inputChoices:categoriesChoices}),
            description: new CustomFormControl(item.description,{},{...new CustomFormControlProps(),label:'DIALOG.ITEM.EDIT.DESCRIPTION',class:"tw-w-full",inputType:"textarea"}),
            ...this.createShowPriceForm(item),
        });
    }

    private createShowPriceForm(item:SaleItemFormValue){
        const departmentsChoices:KeyValueIconImage<string>[] = Object.entries(this.departmentMap||{}).map(([k,v])=>({key:v.name,value:k}));
        const showPriceArray=Array.from({length:10},(_,i)=>this.configurationManagerService.getPreference(`price_list_${i+1}_hide`));
        const priceNameArray=Array.from({length:10},(_,i)=>this.configurationManagerService.getPreference(`price_list_${i+1}_name`) || `${this.translateService.instant('ITEMS.DETAILS.PRICE_LIST_START')} ${i+1}`);
        return showPriceArray.reduce((state,hide,i)=>({
                ...state,
                ...(!hide && {
                    [`price${i+1}`]: new CustomFormControl(item[`price${i+1}` as keyof ItemFormValuePartialPrices],{validators:i?undefined:Validators.required},{...new CustomFormControlProps(),label:this.translateService.instant('ITEMS.DETAILS.PRICE',{value:priceNameArray[i]}),class:"tw-w-1/3", inputType:"number"}),
                    [`department${i?i+1:''}`]: new CustomFormControl(item[`department${i?i+1:''}` as keyof ItemFormValuePartialDepartments]?.id?.toString(),{validators:i?undefined:Validators.required},{...new CustomFormControlProps(),label:`${this.translateService.instant('ITEMS.DETAILS.DEPARTMENT')} ${priceNameArray[i]}`,class:"tw-w-1/3", inputType:"select", inputChoices:departmentsChoices}),
                }),
            }),{}
        );

    }

    private async save() {
        if(!this.itemForm.valid || this.connectionService.isOffline()) return;
        this.savingLoader.set(true);
        try{
            const updatedItem = {
                ...this.data.item,
                ...this.itemForm.value,
                ...(this.categoryControl?.value && {category_id:this.categoryControl?.value}),
                ...(this.departmentControl?.value && {department_id:this.departmentControl?.value}),
                ...(this.department2Control?.value && {department2_id:this.department2Control?.value}),
                ...(this.department3Control?.value && {department3_id:this.department3Control?.value}),
                ...(this.department4Control?.value && {department4_id:this.department4Control?.value}),
                ...(this.department5Control?.value && {department5_id:this.department5Control?.value}),
                ...(this.department6Control?.value && {department6_id:this.department6Control?.value}),
                ...(this.department7Control?.value && {department7_id:this.department7Control?.value}),
                ...(this.department8Control?.value && {department8_id:this.department8Control?.value}),
                ...(this.department9Control?.value && {department9_id:this.department9Control?.value}),
                ...(this.department10Control?.value && {department10_id:this.department10Control?.value})
            }
            this.data.item=await this.entityManagerService.items.putOneOnline(updatedItem);
            this.log('SAVE', {form:this.itemForm.value, updatedItem,response:this.data.item});
        }
        catch (err){
            throw await this.alertDialogService.openDialog({data:{messageLabel: 'DIALOG.ITEM.EDIT.OFFLINE_ALERT'}})
        }
        finally{
            this.savingLoader.set(false);
        }

        this.isEditMode.set(false);
    }
    private goToEditMode() {
        this.itemForm=this.createItemForm(this.data.item);
        this.isEditMode.set(true);
    }

    private log(...args: any[]) {
        DevLogger.debug('CASHREGISTER_ITEM_DIALOG_COMPONENT', ...args);
    }

    get categoryControl(){return this.itemForm.controls.category;}
    get departmentControl(){return this.itemForm.controls.department;}
    get department2Control(){return this.itemForm.controls.department2;}
    get department3Control(){return this.itemForm.controls.department3;}
    get department4Control(){return this.itemForm.controls.department4;}
    get department5Control(){return this.itemForm.controls.department5;}
    get department6Control(){return this.itemForm.controls.department6;}
    get department7Control(){return this.itemForm.controls.department7;}
    get department8Control(){return this.itemForm.controls.department8;}
    get department9Control(){return this.itemForm.controls.department9;}
    get department10Control(){return this.itemForm.controls.department10;}

    protected openStockDialog() {
        this.openDialogsService.openStockDialog({
                data: {
                    title: 'Open Stock',
                    form: this.cashregisterStateService.createCashregisterStockForm({sku: this.data.item.sku}),
                    searchOnInit: true,
                }
            });
    }


}

@Injectable({
    providedIn: "root",
})
export class CashregisterItemDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<CashregisterSaleItemDialogData>) {
        const data: CashregisterSaleItemDialogData = config.data;
        const configEdited: NonNullableConfigData<CashregisterSaleItemDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            panelClass: "md:tw-max-w-[80vw]",
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(CashregisterItemDialogComponent, configEdited).afterClosed()
        );
    }
}
