import {
    inject,
    Injectable
} from "@angular/core";

import {
    TranslateService
} from "@ngx-translate/core";

import {
    TilbyCurrencyPipe
} from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import {
    groupBy,
    MathUtils
} from "src/app/shared/utils";

import {
    Sales
} from "tilby-models";

@Injectable({
    providedIn: 'root'
})
export class OccupiedTablesReportGeneratorService {
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translate = inject(TranslateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);

    public generateReport(sales: Sales[], options?: { columns?: number }) {
        const printerColumns = options?.columns || 46;
        const linesToPrint: String[] = [];
        const saleItems = [];

        const addLine = (line: string, indentation: number = 0) => linesToPrint.push((' '.repeat(indentation * 2) + line).slice(0, printerColumns));
        const addBorder = () => linesToPrint.push('-'.repeat(printerColumns));
        const addInfoLine = (line: string, value: string) => linesToPrint.push(line + value.padStart(printerColumns - line.length, ' '));

        // Print header
        addLine(this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.TITLE', { date: this.tilbyDatePipe.transform(new Date()) }));

        // Print sales
        for (const sale of sales) {
            if (!sale.sale_items?.length) {
                continue;
            }

            addLine('');
            addBorder();
            addLine('');
            const status = this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.' + (sale.bill_lock ? 'TABLE_CHECKOUT' : 'TABLE_OPEN'));

            addInfoLine(`${sale.room_name} - ${sale.table_name} (${status})`, this.tilbyCurrencyPipe.transform(sale.final_amount!));
            addLine('');
            addLine(this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.OPEN_AT', { date: this.tilbyDatePipe.transform(sale.open_at, 'dd/MM/yyyy HH:mm') }));

            for (const saleItem of sale.sale_items) {
                addLine(`${saleItem.quantity} ${saleItem.name}`, 3);
            }

            saleItems.push(...sale.sale_items);
        }

        // Print items summary
        addLine('');
        addBorder();
        addLine('');
        addLine(this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.DETAILS_SUMMARY'));

        const saleItemsById = groupBy(saleItems, si => si.item_id || 'other');

        for (const [id, items] of Object.entries(saleItemsById)) {
            if (id === 'other') {
                for (const item of items) {
                    addInfoLine(`      ${item.quantity} ${item.name}`, this.tilbyCurrencyPipe.transform(item.final_price * item.quantity));
                }
            } else {
                const item = items[0];
                const quantity = items.reduce((a, b) => a + b.quantity, 0);
                const price = items.reduce((a, b) => a + (b.final_price * b.quantity), 0);

                addInfoLine(`      ${MathUtils.round(quantity)} ${item.name}`, this.tilbyCurrencyPipe.transform(price));
            }
        }

        addLine('');
        addBorder();
        addLine('');

        //Print total covers
        const totalCovers = sales.reduce((a, b) => a + (b.covers || 0), 0);

        addInfoLine(this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.ROOM_COVERS'), totalCovers.toString());

        addLine('');
        addBorder();
        addLine('');

        //Print total amount
        const totalAmount = sales.reduce((a, b) => a + (b.final_amount || 0), 0);

        addInfoLine(this.translate.instant('TABLES.OCCUPIED_TABLES_REPORT.TABLES_AMOUNT'), this.tilbyCurrencyPipe.transform(totalAmount));

        return linesToPrint.join('\n');
    }
}