import {
    Injectable,
    inject
} from "@angular/core";

import {
    ConfigurationManagerService,
    EntityManagerService
} from "src/app/core";

import {
    TilbySoapClient
} from "src/app/shared/net/soap-client";

import {
    XMLNode
} from "src/app/shared/XmlNode";

import {
    keyBy
} from "@tilby/tilby-ui-lib/utilities";

import {
    PaymentMethods
} from "tilby-models";


export type WellbyCardsResponse = {
    esito: string,
    accounts: WellbyCardResponse[]
}

export type WellbyCardResponse = {
    accountId: string,
    accountType: string,
    residualAmount: string,
    description: string,
    userId: string,
    tagNumber?: string
}

export type WellbyCustomersResponse = {
    esito: string,
    idCustomers: string[]
}


export type WellbyLoginCredentials = {
    serverAddress: string,
    port: string,
    plantCode: string,
    username: string,
    password: string,
    userType: string
}

export type WellbyApiConfig = {
    server_address: string,
    port: string,
    plant_code: string,
    username: string,
    password: string,
    user_type: string
}

export type LogoutCredentialsRequestType = {
    plantCode: string,
    sessionId: string
}

export class WellbyPmsApi {
    constructor(
        private serverAddress: string,
        private port: string,
        private plantCode: string,
        private username: string,
        private password: string,
        private userType: string
    ) {
    }

    private static async performLogin(data?: WellbyLoginCredentials, defaults?: WellbyLoginCredentials) {
        const client = new TilbySoapClient(`${defaults?.serverAddress || data?.serverAddress}:${defaults?.port || data?.port}/wsAccesso.svc`);

        const requestPayload = XMLNode.fromObject({
            name: 'ns:Login',
            attributes: {
                "xmlns:ns": "http://zitaca.com/gridservices/001",
                "xmlns:grid": "http://zitaca.com/gridservices"
            },
            children: [
                {
                    name: 'ns:base',
                    children: [
                        {
                            name: 'grid:Impianto', children: [{ content: defaults?.plantCode || data?.plantCode }]
                        }]
                },
                {
                    name: 'ns:user',
                    children: [
                        { name: 'grid:Password', children: [{ content: defaults?.password || data?.password }] },
                        { name: 'grid:UserName', children: [{ content: defaults?.username || data?.username }] },
                        { name: 'grid:UserType', children: [{ content: defaults?.userType || data?.userType }] },
                    ]
                }
            ]
        });

        const response = await client.sendRequest('http://zitaca.com/gridservices/001/iAccess_001/Login', requestPayload);

        if (!response?.body) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'LoginResponse');

        if (!openResponseNode) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const loginResult = openResponseNode.children?.find((node) => node.name === 'LoginResult');

        if (!loginResult) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const nodesByName = keyBy(loginResult.children || [], (node) => node?.name || '');

        return {
            esitoCodice: nodesByName['EsitoCodice'].content!,
            sessionId: nodesByName['SessionId'].content!,
            client
        };
    }

    /**
     *
     * Check login credentials SOAP call
     *
     * @param data
     * @returns
    */
    public static async checkLoginCredentials(data: WellbyLoginCredentials) {
        return await this.performLogin(data);
    }

    /**
     * Login SOAP call
     */
    public async login(data?: WellbyLoginCredentials) {
        return WellbyPmsApi.performLogin(data, {
            password: this.password,
            plantCode: this.plantCode,
            port: this.port,
            serverAddress: this.serverAddress,
            username: this.username,
            userType: this.userType,
        });
    }

    /**
     * Logout SOAP call
    */
    async logout(data?: LogoutCredentialsRequestType) {
        const client = new TilbySoapClient(`${this.serverAddress}:${this.port}/wsAccesso.svc`);

        const requestPayload = XMLNode.fromObject({
            name: 'ns:Logout',
            attributes: {
                "xmlns:ns": "http://zitaca.com/gridservices/001",
                "xmlns:grid": "http://zitaca.com/gridservices"
            },
            children: [
                {
                    name: 'ns:base',
                    children: [
                        { name: 'grid:Impianto', children: [{ content: data?.plantCode }] },
                        { name: 'grid:SessionId', children: [{ content: data?.sessionId }] }
                    ]
                },
            ]
        });

        const response = await client.sendRequest('http://zitaca.com/gridservices/001/iAccess_001/Logout', requestPayload);

        if (!response?.body) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'LogoutResponse');

        if (!openResponseNode) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const logoutResult = openResponseNode.children?.find((node) => node.name === 'LogoutResult');

        if (!logoutResult) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const nodesByName = keyBy(logoutResult.children || [], (node) => node?.name || '');

        return {
            esitoCodice: nodesByName['EsitoCodice'].content!,
            client
        };
    }

    /**
     *
     * Personal data information SOAP call
     *
     * @param sessionId
     * @param surname
     * @returns
     */
    async getIdPersonalData(sessionId: string, surname: string) {
        const obj: WellbyCustomersResponse = {
            esito: '',
            idCustomers: []
        };

        const client = new TilbySoapClient(`${this.serverAddress}:${this.port}/wsAnagrafica.svc`);

        const requestPayload = XMLNode.fromObject({
            name: 'ns:GetAnagrafiche',
            attributes: {
                "xmlns:ns": "http://zitaca.com/gridservices/001",
                "xmlns:grid": "http://zitaca.com/gridservices"
            },
            children: [
                {
                    name: 'ns:base',
                    children: [
                        { name: 'grid:Impianto', children: [{ content: this.plantCode }] },
                        { name: 'grid:SessionId', children: [{ content: sessionId }] }
                    ]
                },
                {
                    name: 'ns:filter',
                    children: [
                        { name: 'grid:Cognome', children: [{ content: surname }] },
                        { name: 'grid:TipoRicercaCliente', children: [{ content: '0' }] }
                    ]
                }
            ]
        });

        const response = await client.sendRequest('http://zitaca.com/gridservices/001/iElenchi_001/GetAnagrafiche', requestPayload);

        if (!response?.body) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'GetAnagraficheResponse');

        if (!openResponseNode) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const personalDataResult = openResponseNode.children?.find((node) => node.name === 'GetAnagraficheResult');

        if (!personalDataResult) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const nodesByName = keyBy(personalDataResult.children || [], (node) => node?.name || '');

        obj.esito = nodesByName['EsitoCodice'].content!;

        const list = nodesByName['Elenco'].children!;

        list.forEach(customer => {

            const data = customer.children?.find((node) => node.name === 'Id');
            const idCustomer = data?.children?.find((node) => node.name === 'Id');

            obj.idCustomers.push(idCustomer?.content!);

        });

        return obj;
    }

    /**
     *
     * List of accounts by card SOAP call
     *
     * @param sessionId
     * @param card
     * @param accountType
     * @returns
     */
    async getListOfAccountsCard(sessionId: string, card: string, accountType?: string) {
        const identityFilter = {
            name: 'grid:Identita',
            children: [
                { name: 'grid:ControlloTagNumero', children: [{ content: '1' }] },
                { name: 'grid:TagNumero', children: [{ content: card }] },
                { name: 'grid:TipoRiferimento', children: [{ content: '0' }] }
            ]
        };

        const filter = [identityFilter, accountType ? { name: 'grid:TipoConto', children: [{ content: accountType }] } : []];

        return await this.getAccountsFromSoapRequest(sessionId, filter);
    }

    /**
     *
     *  List of accounts by personal data SOAP call
     *
     * @param sessionId
     * @param idCustomer
     * @param accountType
     * @returns
     */
    async getListOfAccountsPersonalData(sessionId: string, idCustomer: string, accountType?: string) {
        const identityFilter = {
            name: 'grid:Identita',
            children: [
                { name: 'grid:ControlloTagNumero', children: [{ content: '1' }] },
                { name: 'grid:IdRiferimento', children: [{ name: 'grid:Id', children: [{ content: idCustomer }] }] },
                { name: 'grid:TipoRiferimento', children: [{ content: '0' }] }
            ]
        };

        const filter = [identityFilter, accountType ? { name: 'grid:TipoConto', children: [{ content: accountType }] } : []];

        return await this.getAccountsFromSoapRequest(sessionId, filter);
    }

    async getPaymentInfoData(sessionId: string, accountId: string) {
        const identityFilter = {
            name: 'grid:Identita',
            children: []
        };

        const accountFilter = {
            name: 'grid:IdConto',
            children: [
                { name: 'grid:Id', children: [{ content: accountId }] },
            ]
        }

        const filter = [accountFilter, identityFilter];

        return await this.getAccountsFromSoapRequest(sessionId, filter);
    }

    /**
     *
     * Common request methos for get list of accounts
     *
     * @param sessionId
     * @param requestFilter
     * @returns
     */
    private async getAccountsFromSoapRequest(sessionId: string, requestFilter: any) {
        const obj: WellbyCardsResponse = {
            esito: '',
            accounts: []
        };

        const client = new TilbySoapClient(`${this.serverAddress}:${this.port}/wsDenaro.svc`);

        const requestPayload = XMLNode.fromObject({
            name: 'ns:GetContiInfo',
            attributes: {
                "xmlns:ns": "http://zitaca.com/gridservices/001",
                "xmlns:grid": "http://zitaca.com/gridservices"
            },
            children: [
                {
                    name: 'ns:base',
                    children: [
                        { name: 'grid:Impianto', children: [{ content: this.plantCode }] },
                        { name: 'grid:SessionId', children: [{ content: sessionId }] }
                    ]
                },
                { name: 'ns:filter', children: requestFilter }
            ]
        });

        const response = await client.sendRequest('http://zitaca.com/gridservices/001/iDenaro_001/GetContiInfo', requestPayload);

        if (!response?.body) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'GetContiInfoResponse');

        if (!openResponseNode) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const accountsResult = openResponseNode.children?.find((node) => node.name === 'GetContiInfoResult');

        if (!accountsResult) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const nodesByName = keyBy(accountsResult.children || [], (node) => node?.name || '');

        obj.esito = nodesByName['EsitoCodice'].content!;

        const list = nodesByName['Elenco'].children!;

        list.forEach(account => {
            const rif = account.children?.find((node) => node.name === 'Riferimento');
            const description = rif?.children?.find((node) => node.name === 'Descrizione');
            const residualAmount = account.children?.find((node) => node.name === 'ImportoResiduo');
            const accountObject = account.children?.find((node) => node.name === 'IdConto');
            const accountId = accountObject?.children?.find((node) => node.name === 'Id');
            const accountType = account.children?.find((node) => node.name === 'TipoConto');
            const referenceObject = account.children?.find((node) => node.name === 'Riferimento');
            const userId = referenceObject?.children?.find((node) => node.name === 'Id');

            let accountData = {
                description: description ? description.content! : '',
                residualAmount: residualAmount ? residualAmount.content! : '',
                accountId: accountId ? accountId.content! : '',
                accountType: accountType ? accountType.content! : '',
                userId: userId ? userId.content! : ''
            };

            // CHECK ACCOUNT TYPE (ONLY CREDIT & DEBIT)
            if (accountData.accountType === "0" || accountData.accountType === "1") {
                obj.accounts.push(accountData);
            }

        });

        return obj;
    }

    /**
     *
     * Add movement
     *
     * @param sessionId
     * @param movement
     * @returns
     */
    async addMovement(sessionId: string, movement: any) {
        const client = new TilbySoapClient(`${this.serverAddress}:${this.port}/wsDenaro.svc`);

        const requestPayload = XMLNode.fromObject({
            name: 'ns:AddMovimento',
            attributes: {
                "xmlns:ns": "http://zitaca.com/gridservices/001",
                "xmlns:grid": "http://zitaca.com/gridservices"
            },
            children: [
                {
                    name: 'ns:base',
                    children: [
                        { name: 'grid:Impianto', children: [{ content: this.plantCode }] },
                        { name: 'grid:SessionId', children: [{ content: sessionId }] }
                    ]
                },
                {
                    name: 'ns:movimento',
                    children: [
                        { name: 'grid:CostoUnitario', children: [{ content: movement.unitCost }] },
                        { name: 'grid:Descrizione', children: [{ content: movement.description }] },
                        {
                            name: 'grid:IdListino',
                            children: [
                                { name: 'grid:Id', children: [{ content: movement.priceListId }] }
                            ]
                        },
                        { name: 'grid:Importo', children: [{ content: movement.amount }] },
                        { name: 'grid:Quantita', children: [{ content: movement.quantity }] },
                        { name: 'grid:Sconto', children: [{ content: movement.discount }] },
                        {
                            name: 'grid:Identita',
                            children: [
                                { name: 'grid:ControlloTagNumero', children: [{ content: '1' }] },
                                {
                                    name: 'grid:IdRiferimento',
                                    children: [
                                        { name: 'grid:Id', children: [{ content: movement.idUser }] }
                                    ]
                                },
                                ...(movement.idTypeAccount === "1" && !movement.idUser ? [{ name: 'grid:TagNumero', children: [{ content: movement.tagNumero }] }] : [])
                            ]
                        },
                        { name: 'grid:TipoMovimento', children: [{ content: movement.idTypeAccount === "0" ? '61' : '51' }] }
                    ]
                }
            ]
        });

        const response = await client.sendRequest('http://zitaca.com/gridservices/001/iDenaro_001/AddMovimento', requestPayload);

        if (!response?.body) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'AddMovimentoResponse');

        if (!openResponseNode) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const addMovimentoResult = openResponseNode.children?.find((node) => node.name === 'AddMovimentoResult');

        if (!addMovimentoResult) {
            throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.PARSE_ERROR';
        }

        const nodesByName = keyBy(addMovimentoResult.children || [], (node) => node?.name || '');

        return {
            esitoCodice: nodesByName['EsitoCodice'].content!,
            client
        };

    }
}

const configAttributes: Array<keyof WellbyApiConfig> = ['server_address', 'port', 'plant_code', 'username', 'password', 'user_type'];

@Injectable({
    providedIn: 'root'
})
export class WellbyPmsApiService {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);

    private getLegacyConfig(): WellbyApiConfig {
        const config: any = {};
        
        for (const key of configAttributes) {
            config[key] = this.configurationManagerService.getPreference(`digital_payments.wellby.${key}`) || '';
        }

        return config;
    }

    private checkConfiguration(config: WellbyApiConfig) {
        for (const key of configAttributes) {
            if (!config[key]) {
                throw `MISSING_${key.toUpperCase()}`;
            }
        }
    }

    public async setupApi(paymentMethod: PaymentMethods, config: WellbyApiConfig) {
        Object.assign(paymentMethod, {
            configuration: {
                ...config
            }
        });

        try {
            await this.entityManagerService.paymentMethods.putOneOnline(paymentMethod);
        } catch (err) {
            throw 'SETUP_FAILED';
        }

        return this.getApiInstance(paymentMethod);
    }

    public checkLoginCredentials(config: WellbyLoginCredentials) {
        return WellbyPmsApi.checkLoginCredentials(config);
    }

    public async getApiInstance(paymentMethod: PaymentMethods) {
        let config = paymentMethod.configuration as WellbyApiConfig;

        try {
            this.checkConfiguration(config || {});
        } catch (err) {
            //TODO: Migration logic, remove this in the future
            const campgestPaymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 41 });
            const currentPayment = campgestPaymentMethods.find((method) => method.id === paymentMethod.id);

            //If the current payment method is not the first campgest payment method, throw and don't migrate from legacy config    
            if (currentPayment !== campgestPaymentMethods[0]) {
                throw err;
            }

            config = this.getLegacyConfig();

            this.checkConfiguration(config);

            await this.setupApi(paymentMethod, config);
        }

        return new WellbyPmsApi(
            config.server_address,
            config.port,
            config.plant_code,
            config.username,
            config.password,
            config.user_type
        );
    }

    /**
     *
     * Method for card conversion for compatibility with X1 readers
     *
     * @param read
     * @returns
     */
    getCodificaUid(read: string) {
        let retValue = "";
        if (read && read.length === 8) {
            retValue += read.substring(6, 8);
            retValue += read.substring(4, 6);
            retValue += read.substring(2, 4);
            retValue += read.substring(0, 2);
        } else {
            retValue = read || '';
        }
        return retValue.toUpperCase().padEnd(14, "0");
    }
}
