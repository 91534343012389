import angular from 'angular';

import {
    OpenDialogsService,
    WaitDialogService
} from 'src/app/dialogs';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import { MathUtils } from 'src/app/shared/utils';

type ZPayPaymentType =
    'Auto' |
    'Debit' |
    'Credit' |
    'Other' |
    'Alternative' |
    'Ticket';

type ZPayChannelType =
    'Jiffy' |
    'Satispay' |
    'Bitcoin' |
    'SatispayQrcode' |
    'ConadPay' |
    'Tinaba' |
    'Alipay' |
    'Wechat' |
    'Postepay' |
    'Fierapay' |
    'Scalapay' |
    'BancomatPay' |
    'CibusPay' |
    'Cashmatic';

type ZPayPOS = {
    id: string
    name: string
    ip: string
    port: number
    created_at: string
    created_by: string
    last_modified_at: string
    last_modified_by: string
    deleted_at: string | null
    deleted_by: string | null
}

type ZPayTransactionRequest = {
    terminal_id?: string;
    cashier_id?: string;
    payment_type?: ZPayPaymentType;
    value?: number;
    additional_data?: string;
    channel_type?: ZPayChannelType;
    fidelity_code?: string;
    type?: 'Payment' | 'Refund' | 'Cancel';
    pos_id?: string;
}

type ZPayTransaction = {
    request: ZPayTransactionRequest;
    success: boolean;
    id_acquirer: string;
    stan: string;
    online_progressive: number;
    pan: string;
    host_auth_code: string;
    date: Date;
    error: string;
    action_code: string;
    is_currency_change: boolean;
    conversion_rate: number;
    currency_code: string;
    outcome_description: string;
    voucher_code: string;
    detail_voucher: string;
    description_voucher: string;
    provider_code: string;
    provider_name: string;
    additional_info: string;
    id: string;
    created_at: Date;
    created_by: string;
    last_modified_at: Date;
    last_modified_by: string;
    deleted_at: Date;
    deleted_by: string;
    receipt?: string;
}

const availableMethods = [
    'Auto',
    'Ticket'
];

export class ZPayBridge implements DigitalPaymentHandler {
    constructor(
        private readonly openDialogsService: OpenDialogsService,
        private readonly waitDialogService: WaitDialogService
    ) {
    }

    private sendTransactionRequest(apiUrl: string, transactionRequest: ZPayTransactionRequest): Promise<ZPayTransaction> {
        return fetch(`http://${apiUrl}/api/transaction/start`, {
            method: 'POST',
            body: JSON.stringify(transactionRequest),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                throw res.json();
            }

            return res.json();
        });
    }

    private getPOSList(apiUrl: string): Promise<ZPayPOS[]> {
        return fetch(`http://${apiUrl}/api/pos`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                throw res.json();
            }

            return res.json();
        });
    }

    
    /**
     * Given a detail voucher, parse it and extract the actual amount from it.
     * The detail voucher is a string with the following format:
     * <number of pairs>|<quantity1>|<value1>|<quantity2>|<value2>|...
     * The function will return the actual amount, divided by 100 and rounded to the nearest integer.
     * If the detail voucher is invalid, the function will return undefined.
     * @param detailVoucher The detail voucher to parse.
     * @returns The actual amount, or undefined if the detail voucher is invalid.
     */
    private parseDetailVoucher(detailVoucher: string): number | undefined {
        try {
            let actualAmount = 0;

            const detailVoucherArr = detailVoucher.split('|');
            const numPairs = Number(detailVoucherArr.shift());

            for (let i = 0; i < numPairs; i++) {
                const quantity = Number(detailVoucherArr.shift());
                const value = Number(detailVoucherArr.shift());

                actualAmount += quantity * value;
            }

            return MathUtils.round(actualAmount / 100) || undefined;
        } catch (error) {
            //Do nothing
        }
    }

    public async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const paymentMethod = options.paymentMethod;
        const apiUrl = paymentMethod.schema_name!;

        const posList = await this.getPOSList(apiUrl).then(pList => pList.map((pos) => ({
            name: pos.name,
            nameValue: pos.id,
            isSelected: false
        })));

        let selectedPos;

        switch (posList.length) {
            case 0:
                throw 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.NO_POS_AVAILABLE';
            case 1:
                selectedPos = posList[0];
                break;
            default:
                selectedPos = await this.openDialogsService.openGenericListDialog({
                    data: {
                        title: { label: 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.SELECT_POS' },
                        list: posList
                    }
                });
                break;
        }

        if (!selectedPos) {
            throw 'CANCELED';
        }

        const methods = availableMethods.map((type) => ({
            name: `DIGITAL_PAYMENTS.ZPAY_BRIDGE.PAYMENT_TYPE.${type.toUpperCase()}`,
            nameValue: type,
            isSelected: false
        }));

        let selectedMethod: ZPayPaymentType | undefined;

        if(options.sale.payments?.some(payment => payment.payment_method_type_id === 42 && payment.unclaimed)) {
            selectedMethod = 'Auto';
        }

        if(!selectedMethod) {
            selectedMethod = await this.openDialogsService.openGenericListDialog({
               data: {
                   title: { label: 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.SELECT_PAYMENT_TYPE' },
                   list: methods
               }
           }).then(answer => answer?.nameValue) as ZPayPaymentType | undefined;
        }

        if (!selectedMethod) {
            throw 'CANCELED';
        }

        const transactionRequest: ZPayTransactionRequest = {
            payment_type: selectedMethod,
            type: 'Payment',
            value: Math.round(amount * 100),
            pos_id: selectedPos.nameValue,
            terminal_id: paymentMethod.bundle_name,
        };

        const paymentPromise = this.sendTransactionRequest(apiUrl, transactionRequest);

        const response = await this.waitDialogService.openDialog({
            data: {
                message: 'DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_POS',
                promise: paymentPromise,
            }
        }) as ZPayTransaction;

        if (!response.success) {
            throw response.error || response.outcome_description || response.receipt || 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.UNKNOWN_ERROR';
        }

        const result: DigitalPaymentHandlerResult = {
            acquirer_id: Number(response.id_acquirer) || undefined,
            payment_data: JSON.stringify(response),
            tail: this.getPaymentTail(response),
            unclaimed: false
        };

        if(['Ticket'].includes(selectedMethod)) {
            result.unclaimed = true;

            //Parse detail voucher in order to get the amount of the used vouchers
            if(response.detail_voucher) {
                const actualAmount = this.parseDetailVoucher(response.detail_voucher);

                if(actualAmount) {
                    result.amount = actualAmount;
                }
            }
        }

        return result;
    }

    getPaymentTail(response: ZPayTransaction): string | undefined {
        if (response.receipt) {
            return response.receipt;
        }

        return undefined;
    }
}

ZPayBridge.$inject = [
    'openDialogsService',
    'newWaitDialog'
];

angular.module('digitalPayments').service('ZPayBridge', ZPayBridge);