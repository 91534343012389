<tilby-dialog-toolbar mat-dialog-title
    title={{title()}}
    [hideConfirm]="status() !=='confirm-card'"
    (confirm)="confirm()"
/>
<tilby-dialog-content>
    @switch(status()) {
        @case ('initialize') {
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="tw-flex tw-flex-col">
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('base_url')?.hasError('required') && loginForm.get('base_url')?.touched}">
                    <mat-label>{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.BASE_URL' | translate}}</mat-label>
                    <input matInput type="text" formControlName="base_url" required>
                    @if(loginForm.get('base_url')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.BASE_URL_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('username')?.hasError('required') && loginForm.get('username')?.touched}">
                    <mat-label>{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.USERNAME' | translate}}</mat-label>
                    <input matInput type="text" formControlName="username" required>
                    @if(loginForm.get('username')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.USERNAME_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched}">
                    <mat-label>{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.PASSWORD' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password" required>
                    @if(loginForm.get('password')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.PASSWORD_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('shop_code')?.hasError('required') && loginForm.get('shop_code')?.touched}">
                    <mat-label>{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.SHOP_CODE' | translate}}</mat-label>
                    <input matInput type="text" formControlName="shop_code" required>
                    @if(loginForm.get('shop_code')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.SHOP_CODE_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                @if(!credentialsOk) {
                    <div class="tw-text-red-500 tw-pl-3 tw-pb-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.ERROR_CHECK_CREDENTIALS' | translate}}</div>
                }
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.SAVE_CREDENTIALS_ACTION' | translate}}</button>
            </form>
        }
        @case ('search-card') {
            <form [formGroup]="searchForm" (ngSubmit)="onSearchCard()" class="tw-flex tw-w-[600px] tw-gap-5">
                <mat-form-field appearance="fill" class="tw-w-2/3">
                    <mat-label>{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.CARD' | translate}}</mat-label>
                    <input matInput type="text" formControlName="cardNumber" required (input)="onCardNumberChange()">
                    @if(searchForm.get('cardNumber')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.CARD_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <button mat-raised-button color="primary" type="submit" class="tw-w-1/3 tw-h-[54px]" [disabled]="!searchForm.valid">{{'CASHREGISTER.PMS_COMMON.SEARCH' | translate}}</button>
            </form>
            @if(!cardOk && searchForm.get('cardNumber')?.hasError('required')) {
                <div class="tw-text-red-500 tw-pl-3">{{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.NO_CARD_FOUND' | translate}}</div>
            }
        }
        @case ('confirm-card') {
            <div class="tw-flex">
                <div class="tw-pl-[2.7rem]">
                    <mat-icon [inline]="true" class="material-symbols-outlined tw-text-[48px] tw-leading-normal">camping</mat-icon>
                </div>
                <div class="tw-flex tw-flex-col tw-pl-8 tw-pt-[9px]">
                    <div>
                        {{this.card?.account_id}}
                    </div>
                    <div class="tw-text-[1.8rem] tw-pt-[1px]">
                        {{this.card?.card_owner}}
                    </div>
                </div>
            </div>
            <div class="tw-pl-[7.7rem] tw-pt-2">
                <div class="tw-text-[1.5rem] tw-pt-[4px]">
                    {{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.AVAILABLE_BALANCE' | translate}} : {{this.card?.card_balance || 0 | tilbyCurrency}}
                </div>
                <div class="tw-text-[1.5rem] tw-pt-[4px]">
                    {{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.EXTRA_CREDIT' | translate}} : {{this.card?.credit || 0 | tilbyCurrency}}
                </div>
            </div>
            <div class="tw-pl-[7.7rem] tw-pt-5">
                <div class="tw-text-[1.2rem] tw-pt-[4px]">
                    {{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.DAILY_SPENDING_LIMIT' | translate}} : {{this.card?.daily_limit || 0 | tilbyCurrency}}
                </div>
                <div class="tw-text-[1.2rem] tw-pt-[4px]">
                    {{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.DAILY_BALANCE' | translate}} : {{this.card?.daily_balance || 0 | tilbyCurrency}}
                </div>
                <div class="tw-text-[1.2rem] tw-pt-[4px]">
                    {{'CASHREGISTER.CAMPGEST_PMS_PAYMENTS.VALIDITY' | translate}} : {{this.card?.checkin_day | tilbyDate:'dd/MM/yyyy'}} - {{this.card?.expiration_day | tilbyDate:'dd/MM/yyyy'}}
                </div>
            </div>
        }
    }
</tilby-dialog-content>


