import {
    Injectable,
    inject
} from "@angular/core";

import {
    ConfigurationManagerService,
    EntityManagerService
} from "src/app/core";

import {
    PaymentMethods
} from "tilby-models";

export class CampgestApiError extends Error {
    constructor(public readonly message: string) {
        super(message);
    }
}

export type CampgestLoginCredentials = {
    baseUrl: string,
    aUserName: string,
    aPassword: string
}

export type CheckCardRequestType = {
    aCardCode: string
}

export type NewTransactionRequestType = {
    aShopCode: string,
    aCardCode: string | number
}

export type OperationRequestType = {
    aTrID: number,
    aCardCode: string | number,
    AnAmount: number | undefined,
    ACause: string,
    ATransactionType: string,
    AComments: string,
}

export type CloseTransactionRequestType = {
    aTrID: number
}

export type CampgestApiConfig = {
    base_url: string,
    username: string,
    password: string,
    shop_code: string
}

export class CampgestApi {
    constructor(
        private apiUrl: string,
        private username: string,
        private password: string,
        private shopCode: string
    ) {
    }

    private async sendApiRequest(method: string, url: string, data: any, params: any): Promise<any> {
        const headers = new Headers({
            'Authorization': `Basic ${Buffer.from(`${this.username}:${this.password}`).toString('base64')}`,
            'Content-Type': 'application/json'
        });

        const urlWithParams = new URL(`${this.apiUrl}${url}`);

        if (params) {
            Object.keys(params).forEach(key => urlWithParams.searchParams.append(key, params[key]));
        }

        const options: RequestInit = {
            method: method,
            headers: headers,
            body: data ? JSON.stringify(data) : null,
            signal: AbortSignal.timeout(5000),
        };

        try {
            const response = await fetch(urlWithParams.toString(), options);

            if (!response.ok) {
                let errorBody;

                try {
                    errorBody = await response.json()
                } catch (jsonError) {
                    errorBody = await response.text()
                }

                throw new Error(`${response.status} - ${response.statusText}: ${JSON.stringify(errorBody)}`)
            }

            return await response.json();
        } catch (error: any) {
            throw error;
        }
    }

    public getShopCode() {
        return this.shopCode;
    }

    public async checkCard(body: CheckCardRequestType) {
        const response = await this.sendApiRequest('POST', 'PosCardCheck', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async openNewTransaction(body: NewTransactionRequestType) {
        const response = await this.sendApiRequest('POST', 'PosNewTrID', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async callOperation(body: OperationRequestType) {
        const response = await this.sendApiRequest('POST', 'PosOperationTr', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async closeTransaction(body: CloseTransactionRequestType) {
        const response = await this.sendApiRequest('POST', 'PosConfirmTrId', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }
}

const configAttributes: Array<keyof CampgestApiConfig> = ['base_url', 'username', 'password', 'shop_code'];

@Injectable({
    providedIn: 'root'
})
export class CampgestPmsApiService {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);

    public async checkLoginCredentials(body: { baseUrl: string, aUserName: string, aPassword: string }): Promise<any> {
        const headers = new Headers({
            'Content-Type': 'application/json'
        });

        const options: RequestInit = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
            signal: AbortSignal.timeout(5000),
        };

        try {
            const response = await fetch(body.baseUrl + 'LoginCredentials', options);

            if (!response.ok) {
                return { Result: false };
            }

            return await response.json();
        } catch (error: any) {
            return { Result: false };
        }
    }

    public async setupApi(paymentMethod: PaymentMethods, config: CampgestApiConfig) {
        Object.assign(paymentMethod, {
            configuration: {
                ...config
            }
        });

        try {
            await this.entityManagerService.paymentMethods.putOneOnline(paymentMethod);
        } catch (err) {
            throw 'SETUP_FAILED';
        }

        return this.getApiInstance(paymentMethod);
    }

    private getLegacyConfig(): CampgestApiConfig {
        const config: any = {};

        for (const key of configAttributes) {
            config[key] = this.configurationManagerService.getPreference(`digital_payments.campgest.${key}`) || '';
        }

        return <CampgestApiConfig>config;
    }

    private checkConfiguration(config: CampgestApiConfig) {
        for (const key of configAttributes) {
            if (!config[key]) {
                throw `MISSING_${key.toUpperCase()}`;
            }
        }
    }

    public async getApiInstance(paymentMethod: PaymentMethods) {
        let config = paymentMethod.configuration as CampgestApiConfig;

        try {
            this.checkConfiguration(config || {});
        } catch (err) {
            //TODO: Migration logic, remove this in the future
            const campgestPaymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 39 });
            const currentPayment = campgestPaymentMethods.find((method) => method.id === paymentMethod.id);

            //If the current payment method is not the first campgest payment method, throw and don't migrate from legacy config    
            if (currentPayment !== campgestPaymentMethods[0]) {
                throw err;
            }

            config = this.getLegacyConfig();

            this.checkConfiguration(config);

            await this.setupApi(paymentMethod, config);
        }

        return new CampgestApi(
            config.base_url,
            config.username,
            config.password,
            config.shop_code
        );
    }
}