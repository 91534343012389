import {
    CommonModule
} from "@angular/common";

import {
    Component,
    Injectable,
    OnInit,
    WritableSignal,
    computed,
    inject,
    signal
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef
} from "@angular/material/dialog";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    lastValueFrom
} from "rxjs";

import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";

import {
    MatIconModule
} from "@angular/material/icon";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import {
    MatFormFieldModule
} from "@angular/material/form-field";

import {
    TranslateModule,
    TranslateService
} from "@ngx-translate/core";

import {
    MatInputModule
} from "@angular/material/input";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    WellbyCardResponse,
    WellbyLoginCredentials,
    WellbyPmsApi,
    WellbyPmsApiService
} from "src/app/shared/services/digital-payments/wellby/wellby-pms-api.service";

import {
    MatListModule
} from "@angular/material/list";

import {
    MatRadioModule
} from "@angular/material/radio";

import {
    TilbyCurrencyPipe
} from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import {
    MatDividerModule
} from "@angular/material/divider";

import {
    PaymentMethods
} from "tilby-models";

type DialogStatus = 'initialize' | 'search-card' | null;

@Component({
    selector: 'app-wellby-pms-payments-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        MatIconModule,
        TilbyDatePipe,
        MatFormFieldModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatRadioModule,
        TilbyCurrencyPipe,
        MatDividerModule,
    ],
    templateUrl: './wellby-pms-payments-dialog.component.html',
    styleUrls: ['./wellby-pms-payments-dialog.component.scss']
})
class WellbyPmsPaymentsDialogComponent implements OnInit {
    private readonly wellbyPmsApiService = inject(WellbyPmsApiService);
    private readonly translateService = inject(TranslateService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly fb = inject(FormBuilder);

    private apiHandler?: WellbyPmsApi;

    protected readonly data: DataDialogData = inject(MAT_DIALOG_DATA);

    loginForm!: FormGroup;
    searchForm!: FormGroup;

    credentialsOk = true;
    credentialsErrorMessage: string = '';
    cardOk = true;
    searchError: string | undefined = undefined;

    sessionId: string | undefined = undefined;

    cards: WellbyCardResponse[] = [];
    cardSelected: WellbyCardResponse | undefined = undefined;

    status: WritableSignal<DialogStatus> = signal(null);

    async ngOnInit() {
        this.loginForm = this.fb.group({
            serverAddress: ['', Validators.required],
            port: ['', Validators.required],
            plantCode: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            userType: ['', Validators.required]
        });

        this.searchForm = this.fb.group({
            cardNumber: [''],
            surname: ['']
        });

        try {
            this.apiHandler = await this.wellbyPmsApiService.getApiInstance(this.data.paymentMethod);
        } catch (err) {
            //Do nothing
        } finally {
            this.updateStatus('search-card');
        }
    }

    ngAfterViewInit(): void {
        this.changeSearchCardInput();
    }

    protected title = computed(() => {
        switch (this.status()) {
            case 'initialize':
            case 'search-card':
                return this.translateService.instant('CASHREGISTER.WELLBY_PMS_PAYMENTS.TITLE');
            default:
                return '';
        }
    });

    private updateStatus(updatedStatus: DialogStatus) {
        this.status.set(this.apiHandler ? updatedStatus : 'initialize');
    }

    async confirm() {
        if (!this.sessionId || !this.apiHandler) {
            return;
        }

        const response = await this.apiHandler.logout({
            plantCode: this.loginForm.value.plantCode,
            sessionId: this.sessionId
        });

        if (response.esitoCodice === '0') {
            this.matDialogRef.close(this.cardSelected);
        } else {
            console.error('ERROR_DURING_CLOSE_SESSION');
        }
    }

    async onLogin() {
        if (!this.loginForm.valid) {
            return;
        }

        const config: WellbyLoginCredentials = {
            ...this.loginForm.value
        }

        try {
            let response;

            try {
                response = await this.wellbyPmsApiService.checkLoginCredentials(config);
            } catch(err) {
                throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.ERROR_CHECK_CREDENTIALS_HTTP';
            }

            if (response?.esitoCodice !== '0') {
                throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.ERROR_CHECK_CREDENTIALS';
            }

            this.apiHandler = await this.wellbyPmsApiService.setupApi(this.data.paymentMethod, {
                password: config.password,
                plant_code: config.plantCode,
                port: config.port,
                server_address: config.serverAddress,
                username: config.username,
                user_type: config.userType
            });

            this.updateStatus('search-card');
            this.sessionId = response.sessionId;
        } catch (err: any) {
            this.credentialsErrorMessage = err;
            this.credentialsOk = false;
        }
    }

    async onSearchCustomer() {
        if (!this.searchForm.valid || !this.apiHandler) {
            return;
        }

        this.searchError = undefined;
        this.cards = [];
        this.cardSelected = undefined;

        try {
            let responseCard = undefined;

            if (!this.sessionId) {
                const response = await this.apiHandler.login();

                if (response?.esitoCodice !== '0') {
                    throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.SESSION_ID_NOT_FOUND';
                }

                this.sessionId = response.sessionId;
            }

            if (!this.sessionId) {
                return;
            }

            const cardNumber = this.searchForm.get('cardNumber')?.value;
            const surname = this.searchForm.get('surname')?.value;

            if (cardNumber && !surname) {
                const card = this.wellbyPmsApiService.getCodificaUid(cardNumber);
                responseCard = await this.apiHandler.getListOfAccountsCard(this.sessionId, card);
            } else if (surname && !cardNumber) {
                const responsePersonalData = await this.apiHandler.getIdPersonalData(this.sessionId, surname);

                for (let i = 0; i < responsePersonalData.idCustomers.length; i++) {
                    const tempResponseCard = await this.apiHandler.getListOfAccountsPersonalData(this.sessionId, responsePersonalData.idCustomers[i]);
                    i === 0 ? responseCard = tempResponseCard : responseCard!.accounts = responseCard!.accounts.concat(tempResponseCard.accounts);
                }
            }

            if (responseCard?.esito === '0') {
                this.cards = responseCard.accounts;

                if (cardNumber) {
                    const card = this.wellbyPmsApiService.getCodificaUid(cardNumber);
                    this.cards = this.updateCardsWithTagNumber(this.cards, card);
                }

                this.cardOk = true;
            } else {
                this.cardOk = false;
            }
        } catch (e: any) {
            this.searchError = e;
        }
    }

    onSearchCardChange() {
        this.searchForm.get('surname')?.setValue('');
    }

    onSearchSurnameChange() {
        this.searchForm.get('cardNumber')?.setValue('');
    }

    changeSearchCardInput() {
        const formElements = document.getElementsByClassName("parent-hide-bottom-div");

        if (!formElements.length) {
            return;
        }

        for (let i = 0; i < formElements.length; i++) {
            const matForm = formElements[i];

            const targetDivs = matForm.querySelectorAll(
                '.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align'
            );

            targetDivs.forEach((div) => div.classList.add('hide-bottom-div'));
        }
    }

    /**
     * Update card only accountType is debit and userId has not value
     *
     */
    updateCardsWithTagNumber(cardsResponse: WellbyCardResponse[], tagNumber: string): WellbyCardResponse[] {
        cardsResponse.forEach((cardResponse: WellbyCardResponse) => {
            if (cardResponse.accountType === "1" && !cardResponse.userId) {
                cardResponse.tagNumber = tagNumber;
            }
        });
        return cardsResponse;
    }
}

export type DataDialogData = {
    amount: number
    paymentMethod: PaymentMethods
};

@Injectable({
    providedIn: 'root'
})
export class WellbyPmsPaymentsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: DataDialogData) {
        const config: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data
        };

        return lastValueFrom(this.dialogRef.open(WellbyPmsPaymentsDialogComponent, config).afterClosed()).then((res: WellbyCardResponse) => {
            return res ? { cardCode: res } : undefined;
        });
    }
}
