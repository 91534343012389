import angular from "angular";

import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from "src/app/core";

import {
    CampgestPmsPaymentsDialogService
} from "src/app/dialogs/cashregister/campgest-pms-payments-dialog";

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from "src/app/shared/model/digital-payments.model";

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from "src/app/shared/model/document-printer.model";

import {
    CampgestApiError,
    CampgestPmsApiService
} from "src/app/shared/services/digital-payments/campgest/campgest-pms-api.service";

import { Sales } from "tilby-models";

export type CampgestPaymentDataType = {
    cardCode: string,
    balance: number,
    balance_day: number,
    server_time: number,
}

export class CampgestPMSPayment implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private campgestPmsApiService: CampgestPmsApiService,
        private userActiveSession: UserActiveSessionManagerService,
        private campgestPmsPaymentsDialog: CampgestPmsPaymentsDialogService,
        private $filter: any,
        private configurationManagerService: ConfigurationManagerService,
        private entityManagerService: EntityManagerService
    ) {
    }

    async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const currentSale = options.sale;
        const paymentMethod = options.paymentMethod;

        try {
            const result = await this.campgestPmsPaymentsDialog.openDialog({
                amount: amount,
                paymentMethod: paymentMethod,
            });

            if (!result) {
                throw 'CANCELED';
            }

            const generalShopName = this.configurationManagerService.getPreference('general.shopname') || '';
            const campgestApi = await this.campgestPmsApiService.getApiInstance(paymentMethod);

            // PosNewTrID : Open new transaction with code shop and code card
            const openTransaction = await campgestApi.openNewTransaction({
                aShopCode: campgestApi.getShopCode(),
                aCardCode:
                result.cardCode
            });

            const idTransaction = parseInt(openTransaction);

            // PosOperationTr : Pay request
            const paymentData = await campgestApi.callOperation({
                aTrID: idTransaction,
                aCardCode: result.cardCode,
                AnAmount: amount,
                ACause: `Vendita ${generalShopName}`,
                ATransactionType: 'A',
                AComments: `${this.userActiveSession.getSession()?.shop.name || ''} ${currentSale.uuid}`
            });

            // PosConfirmTrId : Close transaction (automatic 60 s)
            await campgestApi.closeTransaction({
                aTrID: idTransaction
            });

            let responseString = '';

            try {
                paymentData.cardCode = result.cardCode;
                responseString = JSON.stringify(paymentData);
            } catch (err) {
                //Nothing to do
            } finally {

            return {
                acquirer_name: 'Campgest PMS',
                payment_data: responseString,
                tail: this.createTail(paymentData),
                unclaimed: false
            };
        }

        } catch (error: any) {
            if (error instanceof CampgestApiError) {
                throw error.message;
            } else {
                throw 'UNKNOWN_ERROR';
            }
        }
    }

    createTail(paymentData : CampgestPaymentDataType) {
        const card = paymentData.cardCode.replace(/^.{0,4}/, '****');
        const balance = paymentData.balance;
        const balance_day = paymentData.balance_day;
        const server_time = paymentData.server_time;

        const tailLines = [
            "",
            `*** ACQUISTO CAMPGEST ***`,
            `Card: ${card}`,
            `Residuo sulla carta: ${this.$filter('sclCurrency')(balance)}`,
            `Residuo giornaliero: ${this.$filter('sclCurrency')(balance_day)}`,
            `Ora server: ${server_time}`
        ];

        return tailLines.join('\n');
    }

    public isEnabled() {
        return true;
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const payment = sale.payments?.find((payment) => payment.payment_method_type_id == 39);

        if(!payment) {
            return;
        }

        const paymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(payment.payment_method_id);

        if(!paymentMethod) {
            return;
        }

        let result = 'KO';

        try {
            let paymentData = this.getSaleCampgestTransactionData(sale);

            const campgestApi = await this.campgestPmsApiService.getApiInstance(paymentMethod);

            // PosNewTrID : Open new transaction with code shop and code card
            const openTransaction = await campgestApi.openNewTransaction({
                aShopCode: campgestApi.getShopCode(),
                aCardCode: paymentData!.cardCode
            });

            const idTransaction = parseInt(openTransaction);

            // PosOperationTr : Failover request
            await campgestApi.callOperation({
                aTrID: idTransaction,
                aCardCode: paymentData!.cardCode,
                AnAmount: sale.amount,
                ACause: 'Storno per errore di stampa',
                ATransactionType: 'S',
                AComments: (this.userActiveSession.getSession()?.shop.name || '') + ' ' + sale.uuid
            });

            // PosConfirmTrId : Close transaction (automatic 60 s
            await campgestApi.closeTransaction({
                aTrID: idTransaction
            });

            result = 'OK';
        } catch (error) {
            //Nothing to do
        } finally {
            throw `CAMPGEST_PMS_ROLLBACK_${result}`;
        }
    }

    private getSaleCampgestTransactionData(sale: Sales): CampgestPaymentDataType | undefined {
        const campgestPMSPayment = sale.payments?.find((payment) => payment.payment_method_type_id == 39);

        if (!campgestPMSPayment) {
            return;
        }

        return JSON.parse(campgestPMSPayment.payment_data!);
    }

}

CampgestPMSPayment.$inject = [
  'campgestPmsApiService',
  'userActiveSession',
  'campgestPmsPaymentsDialog',
  '$filter',
  'checkManager',
  'entityManager',
];

angular.module('digitalPayments').service('campgestPMS', CampgestPMSPayment);

