import {
    Injectable,
    Injector,
    effect,
    inject,
    signal
} from '@angular/core';

import {
    errorsLogger,
    util
} from "app/ajs-upgraded-providers";

import {
    EntityBase
} from "./entity-base";

import {
    Allergens,
    BookingShifts,
    Bookings,
    CashMovements,
    Categories,
    ChainCampaigns,
    ChainPrizes,
    ChainPromotions,
    Channels,
    Components,
    Customers,
    DailyClosingSchema,
    Departments,
    Dfge,
    EntitySchema,
    FidelitiesPoints,
    Items,
    NonFiscalDocuments,
    PaymentMethods,
    Printers,
    RawMaterials,
    Rooms,
    Sales,
    SaleTransactions,
    ShopPreferences,
    ShopSettings,
    Stock,
    Suppliers,
    Tickets,
    UserSetting,
    Vat
} from 'tilby-models';

import {
    ConfigurationManagerService,
    ConnectionService,
    DevicePreferences,
    Entity,
    EntityName,
    OfflineFirstEntity,
    ShopSessionEntity,
    UserActiveSessionManagerService,
    UserSessionsEntity
} from 'src/app/core';

import {
    pickBy
} from 'src/app/shared/utils';

import {
    camelCase
} from 'src/app/shared/string-utils';

import {
    PriorityTaskQueue
} from 'src/app/shared/priority-task-queue';

import {
    Subject
} from 'rxjs';


type EntityQueueData = {
    task: EntitySyncTask;
    priority: number;
}

type EntitySyncTask = {
    entityName: EntityName;
    entityId: number | string;
}

@Injectable({
    providedIn: 'root'
})
export class EntityManagerService {
    //Injections
    private injector = inject(Injector);
    private errorsLoggerService = inject(errorsLogger);
    private utilService = inject(util);
    private userActiveSessionManagerService = inject(UserActiveSessionManagerService);

    private syncTimeout?: number | null;
    private syncPromise: Promise<void> | null = null;
    private syncCounter = 0;
    private logDebug = false;
    private useSyncQueue = false;

    public shopSession = inject(ShopSessionEntity);
    public userSessions = inject(UserSessionsEntity);

    //Create entityBase wrappers for each entity
    public activityCodes = new EntityBase<any>('activity_codes');
    public allergens = new EntityBase<Allergens>('allergens');
    public applicationReports = new EntityBase<any>('application_reports');
    public bookings = new EntityBase<Bookings>('bookings');
    public bookingShifts = new EntityBase<BookingShifts>('booking_shifts');
    public campaigns = new EntityBase<ChainCampaigns>('campaigns');
    public cashMovements = new EntityBase<CashMovements>('cash_movements');
    public categories = new EntityBase<Categories>('categories');
    public channels = new EntityBase<Channels>('channels');
    public components = new EntityBase<Components>('components');
    public customers = new EntityBase<Customers>('customers');
    public dailyClosings = new EntityBase<DailyClosingSchema>('daily_closings');
    public departments = new EntityBase<Departments>('departments');
    public dgfe = new EntityBase<Dfge>('dgfe');
    public entitySchemas = new EntityBase<EntitySchema>('entity_schemas');
    public fidelitiesPoints = new EntityBase<FidelitiesPoints>('fidelities_points');
    public items = new EntityBase<Items>('items');
    public nonfiscalDocuments = new EntityBase<NonFiscalDocuments>('nonfiscal_documents');
    public paymentMethods = new EntityBase<PaymentMethods>('payment_methods');
    public printers = new EntityBase<Printers>('printers');
    public prizes = new EntityBase<ChainPrizes>('prizes');
    public promotions = new EntityBase<ChainPromotions>('promotions');
    public rawMaterials = new EntityBase<RawMaterials>('raw_materials');
    public rooms = new EntityBase<Rooms>('rooms');
    public sales = new EntityBase<Sales>('sales');
    public saleTransactions = new EntityBase<SaleTransactions>('sale_transactions');
    public shopPreferences = new EntityBase<ShopPreferences>('shop_preferences');
    public devicePreferences = new EntityBase<DevicePreferences>('device_preferences');
    public shopSettings = new EntityBase<ShopSettings>('shop_settings');
    public slaveDevices = new EntityBase<any>('slave_devices');
    public stock = new EntityBase<Stock>('stock');
    public suppliers = new EntityBase<Suppliers>('suppliers');
    public tickets = new EntityBase<Tickets>('tickets');
    public userPreferences = new EntityBase<UserSetting>('user_preferences');
    public userSettings = new EntityBase<UserSetting>('user_settings');
    public vat = new EntityBase<Vat>('vat');
    //--------------------------------------------------------------------//

    public offlineFirstEntities: EntityBase<any>[];

    constructor(
    ) {
        EntityManagerService.queueEntryPoint.subscribe((data) => {
            if(!this.useSyncQueue) {
                const entityHandler = this[camelCase(data.entityName) as keyof EntityManagerService];

                if (entityHandler instanceof EntityBase) {
                    entityHandler.syncOfflineFirst(data.entity.id!);
                }
                return;
            }

            if(!this.queueIsActive()) {
                return;
            }

            const queueEntry = EntityManagerService.entityToTask(data.entityName, data.entity);

            switch(queueEntry.task.entityName) {
                case 'sales':
                case 'sale_transactions':
                    this.salesQueue.add(queueEntry.task, queueEntry.priority);
                    break;
                default:
                    this.genericEntitiesQueue.add(queueEntry.task, queueEntry.priority);
                    break;               
            }
        });

        this.salesQueue.queueFinished$.subscribe(() => {
            this.logDebugEvent('[ SyncQueue ]', 'Sales queue finished');
            this.onQueueFinished();
        });

        this.genericEntitiesQueue.queueFinished$.subscribe(() => {
            this.logDebugEvent('[ SyncQueue ]', 'Generic entities queue finished');
            this.onQueueFinished();
        });

        ConnectionService.connectionStatus$.subscribe((status) => {
            if (status.online) {
                this.setWorkerTimeout(3000);
            } else {
                this.stopSyncAll();
            }
        });

        effect(() => {
            this.logDebugEvent('[ SyncQueue ]', 'Sync queue active', this.queueIsActive());
        });

        //Create a list of all offline-first entities
        this.offlineFirstEntities = Object.values(pickBy(this, (entity) => entity instanceof EntityBase && entity.isOfflineFirst));
    }

    private logDebugEvent(...args: any[]) {
        if (this.logDebug) {
            this.errorsLoggerService.debug('[ entityManager ]', ...args);
        }
    };

    private logWarnEvent(...args: any[]) {
        this.errorsLoggerService.warn('[ entityManager ]', ...args);
    }

    /**
     * Returns a promise that is resolved as soon as there isn't a sync task running
     */
    private async waitForPendingSync() {
        if (this.syncPromise) {
            try {
                await this.syncPromise;
            } catch (err) {
                //Nothing to do
            }
        }
    };

    /**
     * Attempts to send all the pending entity changes to the API
     */
    private async doSyncAll() {
        try {
            for (const entity of this.offlineFirstEntities) {
                await entity.syncEntityCollection();
            }
        } finally {
            //TODO: notify the sync completion with a subject
        }
    }

    /**
     * Clean-up function that removes the entities that are no longer required on the device storage
     */
    private async doCleanAll() {
        try {
            const configurationManagerService = this.injector.get(ConfigurationManagerService);
            const dayStartTime = this.utilService.getDayStartTime().toISOString();

            if(configurationManagerService.isModuleAngular('bookings')) {
                await this.bookings.cleanupEntity((booking) => booking.booked_for < dayStartTime);
            }

            await this.sales.cleanupEntity((sale) => ['closed', 'stored'].includes(sale.status));
            
            const openSales = (await this.sales.fetchCollectionOffline()).map((sale) => sale.uuid);

            await this.saleTransactions.cleanupEntity((saleTransaction) => !openSales.includes(saleTransaction.sale_uuid));

            await this.dailyClosings.deleteAllSynced();
            await this.dgfe.deleteAllSynced();
            await this.cashMovements.deleteAllSynced();
            await this.applicationReports.deleteAllSynced();
        } finally {
            //TODO: notify the cleanup completion with a subject
        }
    };

    /**
     * Cancels the current sync timeout if present
     */
    private clearWorkerTimeout() {
        if (this.syncTimeout) {
            window.clearTimeout(this.syncTimeout);
            this.syncTimeout = null;
        }
    };

    /**
     * Sets the worker timeout
     */
    private setWorkerTimeout = (timeout?: number) => {
        this.clearWorkerTimeout();
        this.syncTimeout = window.setTimeout(() => this.executeSyncTask('syncAllWorker'), timeout || 15000, false);
    };

    /**
     * Runs the desired task. If a sync is present waits for completion before starting the task
     * @param {*} targetTask The task to execute
     */
    private async executeSyncTask(targetTask: ('syncAllWorker' | 'doSyncAll')) {
        //If there is a sync in progress wait for it to finish before launching another sync
        await this.waitForPendingSync();
        this.clearWorkerTimeout();

        try {
            this.syncPromise = this[targetTask]();
            await this.syncPromise;
        } finally {
            this.syncPromise = null;
            this.setWorkerTimeout();
        }
    };

    /**
     * Worker task that runs both syncAll and cleanAll.
     */
    private async syncAllWorker() {
        //TODO: Legacy sync, should be removed in the future
        if(!this.useSyncQueue) {
            try {
                this.logDebugEvent('syncAll started');
                await this.doSyncAll();
                this.logDebugEvent('syncAll completed');
            } catch (err) {
                this.logWarnEvent('syncAll failed');
            }
        } else {
            this.startQueue();
        }

        //Execute cleanAll once every 4 syncAlls (1 per minute)
        this.syncCounter = ((this.syncCounter + 1) % 4);

        if (this.syncCounter === 0) {
            try {
                this.logDebugEvent('cleanAll started');
                await this.doCleanAll();
                this.logDebugEvent('cleanAll completed');
            } catch (err) {
                this.logWarnEvent('cleanAll failed');
            }
        }
    };

    /**
     * executes an entity sync immediately
     * @return null
     */
    public async syncAll() {
        return this.executeSyncTask('doSyncAll');
    };

    /**
     * Resets the synchronizing flags of all offline-first entities.
     *
     * @return {Promise<void>} A promise that resolves when the sync status is reset.
     */
    public async resetSyncStatus() {
        for (const entity of this.offlineFirstEntities) {
            await entity.resetSyncStatus();
        }
    };

    async loadSettings(entities: (keyof EntityManagerService)[], forceReload: boolean) {
        for (const entityName of entities) {
            const isUserEntity = entityName.startsWith('user');
            const statusPath = `initEntity::${entityName}${(isUserEntity ? `::${this.userActiveSessionManagerService.getSession()?.id}` : '')}`;
            const entityStatus = localStorage.getItem(statusPath);

            if ((entityStatus && ['Pending', 'Fetching'].includes(entityStatus)) || forceReload) {
                try {
                    const entity = this[entityName];

                    if ('reloadEntityCollection' in entity) {
                        await entity.reloadEntityCollection({ avoidSync: true });
                    }

                    localStorage.setItem(statusPath, "OK");
                } catch (err) {
                    //Nothing to do
                }
            }
        }
    };

    /**
     * Starts the sync/cleanup routines.
     */
    public startAsyncRoutines() {
        const configurationManagerService = this.injector.get(ConfigurationManagerService);

        this.logDebug = !!(configurationManagerService.getSetting('logging.enable_entity_manager_debug'));
        this.useSyncQueue = !!(configurationManagerService.getSetting('entity_manager.use_sync_queue'));

        this.setWorkerTimeout();
    }

    /**
     * Stops the synchronization of all tasks.
     *
     * @return {Promise<void>} Promise that resolves when synchronization is stopped.
     */
    private async stopSyncAll() {
        await this.waitForPendingSync();
        this.clearWorkerTimeout();
        this.stopQueue();
    };

    /**
     *
     * -- Sync queue management --
     * 
     */

    private taskDispatcher = async (task: EntitySyncTask) => {
        const entityHandler = this[camelCase(task.entityName) as keyof EntityManagerService];

        if (entityHandler instanceof EntityBase) {
            this.logDebugEvent('[ SyncQueue ]','Syncing entity', task.entityName, task.entityId);
            await entityHandler.syncOfflineFirst(task.entityId);
        }
    }

    private salesQueue = new PriorityTaskQueue<EntitySyncTask>(this.taskDispatcher);
    private genericEntitiesQueue = new PriorityTaskQueue<EntitySyncTask>(this.taskDispatcher);
    private queueIsActive = signal(false);
    private reinitTimeout?: number | null;

    public static queueEntryPoint = new Subject<{ entityName: EntityName; entity: OfflineFirstEntity<Entity> }>();

    private static getSalePriority(sale: Sales): number {
        let priority = 0;
                
        // If sale is closed, priority is 0 (max), otherwise it's the last update date
        if (sale.status !== 'closed') {
            priority = new Date(sale.lastupdate_at!).getTime();
        }

        return priority;
    }

    private static getSaleTransactionPriority(saleTransaction: any): number {
        //TODO: reflect on waiter APP
        return new Date(saleTransaction.sale_details?.open_at || saleTransaction.sale_details?.lastupdate_at || Date.now()).getTime();
    }

    private static getGenericEntityPriority(entity: OfflineFirstEntity<Entity>): number {
        return new Date(entity.lastupdate_at || Date.now()).getTime();
    }

    private static entityToTask(entityName: EntityName, entity: OfflineFirstEntity<Entity>): EntityQueueData {
        const task = {
            entityName: entityName,
            entityId: entity.id!
        }

        let priority = 0;

        switch(entityName) {
            case 'sales': {
                priority = this.getSalePriority(<Sales>entity);
                break;
            }
            case 'sale_transactions': {
                priority = this.getSaleTransactionPriority(entity);
                break;
            }
            default: {
                priority = this.getGenericEntityPriority(entity);
                break;
            }
        }

        return {
            task: task,
            priority: priority
        }
    }

    private async initQueues() {
        const saleTasks: EntityQueueData[] = [];
        const genericTasks: EntityQueueData[] = [];

        this.logDebugEvent('[ SyncQueue ]', 'initQueues started');

        for(const entityHandler of this.offlineFirstEntities) {
            const dirtyEntities = await entityHandler.getDirtyEntities();

            switch(entityHandler.entityName) {
                case 'sales':
                case 'sale_transactions': {
                    saleTasks.push(...dirtyEntities.map((entity) => EntityManagerService.entityToTask(entityHandler.entityName, entity)));
                    break;
                }
                default: {
                    genericTasks.push(...dirtyEntities.map((entity) => EntityManagerService.entityToTask(entityHandler.entityName, entity)));
                    break;
                }
            }
        }

        this.salesQueue.reinitialize(saleTasks);
        this.genericEntitiesQueue.reinitialize(genericTasks);
    }

    private stopQueues() {
        this.salesQueue.stop();
        this.genericEntitiesQueue.stop();
    }


    private clearInitQueueTimeout() {
        if(this.reinitTimeout) {
            window.clearTimeout(this.reinitTimeout);
            this.reinitTimeout = null;
        }
    }

    private setInitQueueTimeout() {
        this.clearInitQueueTimeout();
        this.reinitTimeout = window.setTimeout(() => this.initQueues(), 15000);
    }

    private onQueueFinished() {
        const queuesToCheck: PriorityTaskQueue<EntitySyncTask>[] = [this.salesQueue, this.genericEntitiesQueue];

        for(const queue of queuesToCheck) {
            if(!queue.isEmpty()) {
                return;
            }
        }

        // After 15 seconds, reinitialize the queues to check again for dirty entities
        this.setInitQueueTimeout();
    }

    private stopQueue() {
        if(!this.useSyncQueue || !this.queueIsActive()) {
            return;
        }

        this.clearInitQueueTimeout();
        this.queueIsActive.set(false);
        this.stopQueues();
    }

    private startQueue() {
        if(!this.useSyncQueue || this.queueIsActive()) {
            return;
        }

        this.clearInitQueueTimeout();
        this.queueIsActive.set(true);
        this.initQueues();
    }
}