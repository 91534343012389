import {
    Injectable,
    inject
} from "@angular/core";

import {
    SaleBase,
    SaleItemsTransactions,
    SaleTransactions,
    Sales,
    SalesItems,
    SalesItemsIngredients,
    SalesItemsPriceChanges,
    SalesItemsVariations,
    SalesPriceChanges
} from "tilby-models";

import {
    MathUtils,
    compareObjects,
    groupBy,
    isEqual,
    keyBy,
    pickBy
} from "src/app/shared/utils";

import {
    SalesItemsCashregister,
    VariationSale,
    VariationSaleItem
} from "src/app/shared/model/cashregister.model";

import {
    EntityManagerService,
    OperatorManagerService,
    OperatorUser
} from "src/app/core";

import {
    v4 as generateUuid,
    validate as validateUuid
} from 'uuid';

import {
    CoverConfiguration,
    SaleUtilsService
} from "src/app/features/cashregister";

export type BuildVariationsSalesOptions = {
    coverConfig?: CoverConfiguration
    mergeTransactions?: boolean,
    includeAllTransactions?: boolean
    includeDeletedComponents?: boolean
}

export type SaveTransactionOptions = {
    awaitOnline?: boolean
    onlineFirstMode?: boolean
    offlineMode?: boolean
}

type FetchSalesOptions = {
    applyCoverToSales?: boolean
    prepareSales?: boolean
}

const saleItemRequiredFields: (keyof SalesItems)[] = [
    'uuid',
    'type',
    'price',
    'vat_perc',
    'quantity',
    'seller_id',
    'seller_name'
];

const saleItemVariationRequiredFields: (keyof SalesItemsVariations)[] = [
    'name',
    'value',
    'variation_id',
    'variation_value_id'
];

const saleItemIngredientRequiredFields: (keyof SalesItemsIngredients)[] = [
    'name',
    'ingredient_id'
];

const priceChangeRequiredFields: (keyof (SalesPriceChanges | SalesItemsPriceChanges))[] = [
    'index',
    'type',
    'value',
    'description',
];

@Injectable({
    providedIn: 'root'
})
export class SaleTransactionUtilsService {
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly operatorManagerService = inject(OperatorManagerService);
    private readonly saleUtilsService = inject(SaleUtilsService);

    /**
     * Returns an identifier for the given variation (composing the variation id and variation value).
     *
     * @param {SalesItemsVariations} variation - The sales item variation object.
     * @return {string} The variation identifier.
     */
    public getVariationKey(variation: SalesItemsVariations): string {
        return `${variation.variation_id}_${variation.variation_value_id}`;
    }

    /**
     * Compares two arrays of sales item variations and returns an array of updated variations.
     *
     * This function identifies variations that have been deleted, added, or restored, and
     * updates the list accordingly. Deleted variations are marked with a `deleted_at` timestamp, 
     * and new or restored variations are added to the updated list.
     *
     * @param {SalesItemsVariations[]} currentVariations - The current list of sales item variations.
     * @param {SalesItemsVariations[]} previousVariations - The previous list of sales item variations for comparison.
     * @returns {SalesItemsVariations[]} The updated list of variations, including additions, deletions, and restorations.
     */
    public compareVariations(currentVariations: SalesItemsVariations[] = [], previousVariations: SalesItemsVariations[] = []): SalesItemsVariations[] {
        const updatedVariations: SalesItemsVariations[] = [];

        const newVariations = keyBy(currentVariations, this.getVariationKey, { firstMatchOnly: true });
        const pristineVariations = keyBy(structuredClone(previousVariations), this.getVariationKey, { firstMatchOnly: true });

        //Remove deleted variations
        for (const [key, variation] of Object.entries(pristineVariations)) {

            if (!newVariations[key]) {
                updatedVariations.push(Object.assign(variation, { deleted_at: new Date().toISOString() }));
            }
        }

        //Add new variations
        for (const [key, newVariation] of Object.entries(newVariations)) {
            if (
                !pristineVariations[key] // New variation
                || (newVariation.deleted_at !== pristineVariations[key].deleted_at) // Restoring a deleted variation
            ) {
                updatedVariations.push(structuredClone(newVariation));
            }
        }

        return updatedVariations;
    }

    /**
     * Returns an identifier for the given ingredient (using the ingredient id).
     *
     * @param {SalesItemsIngredients} ingredient - The ingredient object.
     * @return {string} The ingredient identifier.
     */
    public getIngredientKey(ingredient: SalesItemsIngredients): string {
        return `${ingredient.ingredient_id}`;
    }

    /**
     * Compares two arrays of sale item ingredients and returns a new array with the differences.
     *
     * @param {SalesItemsIngredients[]} currentIngredients - the current array of sale item ingredients
     * @param {SalesItemsIngredients[]} previousIngredients - the previous array of sale item ingredients
     * @return {SalesItemsIngredients[]} the updated array of sale item ingredients, including additions, deletions, and restorations
     */
    public compareIngredients(currentIngredients: SalesItemsIngredients[] = [], previousIngredients: SalesItemsIngredients[] = []): SalesItemsIngredients[] {
        const updatedIngredients: SalesItemsIngredients[] = [];

        const newIngredients = keyBy(currentIngredients, this.getIngredientKey, { firstMatchOnly: true });
        const pristineIngredients = keyBy(previousIngredients, this.getIngredientKey, { firstMatchOnly: true });

        //Remove deleted ingredients
        for (const [key, ingredient] of Object.entries(pristineIngredients)) {
            if (!newIngredients[key]) {
                updatedIngredients.push(Object.assign(ingredient, { quantity: 0 }));
            }
        }

        //Add new/updated ingredients
        for (const [key, newIngredient] of Object.entries(newIngredients)) {
            const pristineIngredient = pristineIngredients[key] || {};

            // Compare cleaned-up ingredients
            const { created_at: oldCreatedAt, updated_at: oldUpdatedAt, deleted_at: oldDeletedAt, id: oldId, sale_item_id: oldSaleItemId, ...ingredientA } = pristineIngredient; 
            const { created_at: newCreatedAt, updated_at: newUpdatedAt, deleted_at: newDeletedAt, id: newId, sale_item_id: newSaleItemId, ...ingredientB } = newIngredient;

            if (!isEqual(ingredientA, ingredientB)) {
                updatedIngredients.push(structuredClone(newIngredient));
            }
        }

        return updatedIngredients;
    }


    /**
     * Compares two arrays of price changes and returns a boolean indicating if they are different.
     *
     * @param {T[]} currentPriceChanges - the current array of price changes
     * @param {T[]} previousPriceChanges - the previous array of price changes
     * @return {boolean} true if the arrays are different, false otherwise
     */
    public comparePriceChanges<T extends SalesPriceChanges | SalesItemsPriceChanges>(currentPriceChanges?: T[], previousPriceChanges?: T[]): boolean {
        if (currentPriceChanges?.length !== previousPriceChanges?.length) {
            return true;
        }

        const sortedCurrentPriceChanges = currentPriceChanges?.sort((a, b) => a.index - b.index) || [];
        const sortedPreviousPriceChanges = previousPriceChanges?.sort((a, b) => a.index - b.index) || [];

        for (const [index, currentPriceChange] of sortedCurrentPriceChanges.entries()) {
            if(!sortedPreviousPriceChanges[index]) {
                return true;
            }

            const { created_at: currCreatedAt, updated_at: currUpdatedAt, deleted_at: currDeletedAt, ...cPC } = currentPriceChange;
            const { created_at: prevCreatedAt, updated_at: prevUpdatedAt, deleted_at: prevDeletedAt, ...pPC } = sortedPreviousPriceChanges[index];

            if(!isEqual(cPC, pPC)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Creates an empty sale transaction object for the provided sale.
     *
     * @param {Sales} sale - the sales data for the transaction
     * @param {OperatorUser} operatorData - the operator data for the transaction
     * @return {SaleTransactions} the created sale transaction object
     */
    public createSaleTransaction(sale: Sales, operatorData: OperatorUser): SaleTransactions {
        return {
            operator_id: operatorData.id,
            operator_name: operatorData.full_name,
            sale_items_transactions: [],
            sale_uuid: sale.uuid!,
            uuid: generateUuid()
        };
    }

    /**
     * Creates an empty sale item transaction object for the provided sale item.
     *
     * @param {SalesItems} saleItem - the sales item data for the transaction
     * @return {SaleItemsTransactions} the created sale item transaction object
     */
    public createSaleItemTransaction(saleItem: SalesItems): SaleItemsTransactions {
        return {
            quantity_difference: 0,
            sale_item_details: {},
            sale_item_uuid: saleItem.uuid
        };
    }

    /**
     * Updates the sale object by filtering out sale items with quantity 0 and
     * removing price changes if the sale is in credit note mode. It also
     * updates the menu indexes and calculates the sale prices.
     *
     * @param {Sales} sale - The sale object to be updated (will be mutated).
     * @return {Sales} - The updated sale object.
     */
    public prepareSale(sale: Sales) {
        sale.sale_items = sale.sale_items?.filter((saleItem) => {
            return !(
                saleItemRequiredFields.some(field => saleItem[field] == null) ||
                saleItem.quantity === 0 ||
                (saleItem.quantity < 0 && ['sale', 'gift'].includes(saleItem.type)) ||
                (saleItem.quantity > 0 && ['refund', 'coupon', 'deposit_cancellation'].includes(saleItem.type))
            );
        }) || [];

        let saleItems: SalesItemsCashregister[] = sale.sale_items || [];

        //Drop price changes if we are in credit note mode
        if (this.saleUtilsService.isCreditNote(sale)) {
            sale.price_changes = [];

            for (const saleItem of saleItems) {
                saleItem.price_changes = [];
            }
        } else {
            sale.price_changes = sale.price_changes?.filter((pc) =>
                priceChangeRequiredFields.every(field => pc[field] != null)
            ) || [];
        }

        const menuItemsUuids = saleItems
            .filter(saleItem => saleItem.is_group_item)
            .reduce((acc, saleItem, index) => {
                acc[saleItem.uuid] = { value: saleItem.uuid, index: index + 1 };

                return acc;
            }, {} as Record<string, { value: string, index: number }>);

        for (let [index, saleItem] of saleItems.entries()) {
            //Check if variations and ingredients are deleted and remove the ones that are or don't have mandatory values
            saleItem.variations = saleItem.variations?.filter((variation) =>
                !variation.deleted_at &&
                saleItemVariationRequiredFields.every(field => variation[field] != null)
            ) || [];

            saleItem.ingredients = saleItem.ingredients?.filter((ingredient) =>
                ingredient.quantity !== 0 &&
                saleItemIngredientRequiredFields.every(field => ingredient[field] != null)
            ) || [];

            //Move sale items right below the parent item
            if (saleItem.is_group_item) {
                let groupItems: SalesItemsCashregister[] = [];

                [saleItems, groupItems] = saleItems.reduce((acc, si) => {
                    if (si.sale_item_parent_uuid === saleItem.uuid) {
                        acc[1].push(si);
                    } else {
                        acc[0].push(si);
                    }

                    return acc;
                }, [[] as SalesItemsCashregister[], [] as SalesItemsCashregister[]]);

                if (groupItems.length) {
                    saleItems.splice(index + 1, 0, ...groupItems);
                }
            }

            //Update menu indexes
            const menuRef = menuItemsUuids[saleItem.uuid] || menuItemsUuids[saleItem.sale_item_parent_uuid!];

            saleItem.$menuIndex = menuRef?.index;
        }

        //Handle not discountable items
        const notDiscountablePriceChanges = (sale.price_changes || []).filter((pc) => pc.type === 'disc_perc_nd');
        const notDiscountableIndexes = notDiscountablePriceChanges.map((pc) => pc.index);

        for (const saleItem of saleItems) {
            if ((!saleItem.not_discountable && saleItem.type === 'sale')) {
                //Convert sale discount placeholders to sale item discounts
                const ndPriceChanges: SalesItemsPriceChanges[] = notDiscountablePriceChanges.map(({ id, sale_id, ...pc }) => ({
                    ...pc,
                    type: "discount_perc"
                }));

                //Cleanup sale item price changes
                const currentPriceChanges = (saleItem.price_changes || []).filter((pc) => !notDiscountableIndexes.includes(pc.index));

                //Apply not discountable price changes
                saleItem.price_changes = [...currentPriceChanges, ...ndPriceChanges];
            }

            saleItem.price_changes = saleItem.price_changes?.filter((pc) =>
                priceChangeRequiredFields.every(field => pc[field] != null)
            ) || [];
        }

        //Update sale items
        sale.sale_items = [...(saleItems?.sort((a, b) => Number(a.exit || 0) - Number(b.exit || 0)) || [])];

        //Finally, calculate sale prices
        this.saleUtilsService.calculateSalePrices(sale);

        return sale;
    }

    /**
     * Retrieves sales building them from the transactions, based on the given query.
     *
     * @param {any} query - the query to filter the sales
     * @param {object} options - options for preparing the sales
     * @param {boolean} options.prepareSales - whether to prepare the sales (eg. remove deleted items, calculate prices, etc.)
     * @param {boolean} options.applyCoverToSales - whether to apply the cover to the sales
     * @return {Sales[]} an array of sales built from the transactions
     */
    public async fetchSalesFromTransactions(query: any, options?: FetchSalesOptions): Promise<Sales[]> {
        const saleUuids = (await this.entityManagerService.sales.fetchCollectionOffline(query)).map((sale) => sale.uuid);
        const salesTransactions = await this.fetchTransactions({ sale_uuid_in: saleUuids });
        const saleTransactionsBySaleUuid = groupBy(salesTransactions, (saleTransaction) => saleTransaction.sale_uuid);

        const sales: Sales[] = [];

        for (const [_, sT] of Object.entries(saleTransactionsBySaleUuid)) {
            const sale = this.buildSaleFromTransactions(sT);

            if (sale) {
                sales.push(sale);
            }
        }

        if (options?.applyCoverToSales) {
            const coverConfig = await this.saleUtilsService.getCoverConfiguration();

            if (['item', 'price_change'].includes(coverConfig.type)) {
                for (const sale of sales) {
                    await this.saleUtilsService.applyCoverToSale(sale, coverConfig);
                }
            }
        }

        return options?.prepareSales ? sales.map(sale => this.prepareSale(sale)) : sales;
    }

    /**
     * Returns all open sales. Executes a filter in both the sale table and in the sales obtained from the transactions.
     * 
     * @param {object} options - options for preparing the sales
     * @param {boolean} options.prepareSales - whether to prepare the sales (eg. remove deleted items, calculate prices, etc.)
     * @param {boolean} options.applyCoverToSales - whether to apply the cover to the sales
     * @returns {Promise<Sales[]>} all open sales
     */
    public async fetchOpenSales(options?: FetchSalesOptions): Promise<Sales[]> {
        const openSales = await this.fetchSalesFromTransactions({ status: 'open' }, options)
            .then(sales => sales.filter(sale => sale.status === 'open'));

        return openSales;
    }

    /**
     * Adds a transaction to the store.
     * If an already existing transaction with the same uuid is found, it is replaced unless it is printed/skipped and the new transaction is not.
     *
     * @param {SaleTransactions} transaction - The transaction to add.
     */
    public addTransactionToStore(store: Map<string, SaleTransactions>, transaction: SaleTransactions) {
        const previousTransaction = store.get(transaction.uuid);

        // Avoid regressing an already printed/skipped transaction
        if (previousTransaction && (previousTransaction.printed || previousTransaction.skip_printing) && (!transaction.printed && !transaction.skip_printing)) {
            return;
        }

        store.set(transaction.uuid, transaction);
    }

    /**
     * Retrieves transactions based on the provided query.
     * Note: This method de-duplicates the transactions to avoid possible glitches related to offline-first logic
     *
     * @param {any} query - the query used to filter the transactions
     * @return {Array} an array of deduplicated sales transactions
     */
    public async fetchTransactions(query: any): Promise<SaleTransactions[]> {
        const transactions = await this.entityManagerService.saleTransactions.fetchCollectionOffline(query);
        const transactionStore = new Map<string, SaleTransactions>();

        for (const transaction of transactions) {
            this.addTransactionToStore(transactionStore, transaction);
        }

        return [...transactionStore.values()];
    }

    /**
     * Saves a transaction to the storage.
     *
     * @param {SaleTransactions} transaction - The transaction to be saved.
     * @param {Object} options - The options to use when saving the transaction.
     * @param {boolean} options.onlineFirstMode - Whether to save the transaction online first.
     * @param {boolean} options.offlineMode - Whether to save the transaction offline.
     * @param {boolean} options.awaitOnline - Whether to wait for the transaction to be saved online.
     * @return {Promise<void>} - A promise that resolves when the transaction is saved.
     */
    public async saveTransaction(transaction: SaleTransactions, options?: SaveTransactionOptions) {
        if (options?.offlineMode) {
            await this.entityManagerService.saleTransactions.saveOneOffline(Object.assign(transaction, { id: transaction.uuid }));
        } else if (transaction.id && !validateUuid(transaction.id as any)) {
            await (
                options?.onlineFirstMode ?
                    this.entityManagerService.saleTransactions.putOneOnline(transaction) :
                    this.entityManagerService.saleTransactions.putOneOfflineFirst(transaction, { awaitOnline: options?.awaitOnline })
            );
        } else {
            await (
                options?.onlineFirstMode ?
                    this.entityManagerService.saleTransactions.postOneOnline(transaction) :
                    this.entityManagerService.saleTransactions.postOneOfflineFirst(transaction, { awaitOnline: options?.awaitOnline })
            );
        }
    }

    /**
     * Returns an array of pending transactions.
     *
     * @param {SaleTransactions[]} transactions - The array of transactions to filter.
     * @return {SaleTransactions[]} The array of pending transactions.
     */
    public getPendingTransactions(transations: SaleTransactions[]): SaleTransactions[] {
        return transations.filter((transaction) => !transaction.printed && !transaction.skip_printing);
    }

    /**
     * Updates pending transactions in bulk with the provided flags.
     * 
     * @param {string} saleUuid - The UUID of the sale to update.
     * @param {Pick<SaleTransactions, 'printed' | 'skip_printing'>} updateData - The data to update the pending transactions with.
     * @return {Promise<void>} - A promise that resolves when the update is complete.
     */
    public async bulkUpdateSalePendingTransactions(saleUuid: string, updateData: Pick<SaleTransactions, 'printed' | 'skip_printing'>) {
        const transactions = await this.entityManagerService.saleTransactions.fetchCollectionOffline({ sale_uuid: saleUuid });

        return this.bulkUpdatePendingTransactions(transactions, updateData);
    }

    /**
     * Updates pending transactions in bulk with the provided flags.
     *
     * @param {Pick<SaleTransactions, 'printed' | 'skip_printing'>} updateData - The data to update the pending transactions with.
     * @return {Promise<void>} - A promise that resolves when the update is complete.
     */
    public async bulkUpdatePendingTransactions(transations: SaleTransactions[], updateData: Pick<SaleTransactions, 'printed' | 'skip_printing'>) {
        const pendingTransactions = this.getPendingTransactions(transations);

        for (const transaction of pendingTransactions) {
            Object.assign(transaction, updateData);
            await this.saveTransaction(transaction);
        }
    }

    /**
     * Sorts an array of sale transactions from the oldest to the newest.
     *
     * @param {SaleTransactions[]} transactions - The array of sale transactions to be sorted.
     * @return {SaleTransactions[]} - The sorted array of sale transactions.
     */
    public sortTransactions(transactions: SaleTransactions[]) {
        return transactions.sort((a, b) => a.created_at && b.created_at ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime() : 1);
    }

    /**
     * Converts a sale object into a transaction object.
     * It is intended to be used when a sale is being created from scratch
     *
     * @param {Sales} sale - the sale object to be converted
     * @return {SaleTransactions} - the transaction object generated from the sale
     */
    public newSaleToTransaction(sale: Sales): SaleTransactions {
        const saleUuid = sale.uuid! as any;

        return {
            ...this.saleToTransaction(sale),
            id: saleUuid,
            autogenerated: true,
            uuid: saleUuid
        };
    }

    /**
     * Converts a sale item object into a sale item transaction object.
     *
     * @param {SalesItems} saleItem - the sale item object to be converted
     * @return {SaleItemsTransactions} - the sale item transaction object generated from the sale item
     */
    private saleItemToTransactionItem(saleItem: SalesItems): SaleItemsTransactions {
            const { quantity, id, uuid, ...item_details } = saleItem;

            return {
                sale_item_id: id,
                sale_item_uuid: uuid,
                quantity_difference: quantity,
                sale_item_details: {
                    ...item_details
                }
            }
    }

    /**
     * Converts a sale object into a transaction object.
     *
     * @param {Sales} sale - the sale object to be converted
     * @return {SaleTransactions} - the transaction object generated from the sale
     */
    public saleToTransaction(sale: Sales): SaleTransactions {
        const { sale_items, ...saleDetails } = sale;

        const SaleItemsTransactions = (sale_items || []).map((saleItem) => this.saleItemToTransactionItem(saleItem));

        return {
            autogenerated: false,
            operator_id: sale.seller_id,
            operator_name: sale.seller_name,
            printed: false,
            sale_details: saleDetails,
            sale_items_transactions: SaleItemsTransactions,
            sale_uuid: sale.uuid!,
            skip_printing: false,
            uuid: generateUuid()
        }
    }

    /**
     * Builds a sale object from an array of sale transactions.
     *
     * @param {SaleTransactions[]} transactions - The array of sale transactions.
     * @return {Sales | null} - The built sale object or null if transactions array is empty.
     */
    public buildSaleFromTransactions(transactions: SaleTransactions[]) {
        if (!transactions.length) {
            return null;
        }

        let sale = {} as Sales;

        for (const saleTransaction of this.sortTransactions(transactions)) {
            this.applyTransactionToSale(sale, saleTransaction);
        }

        return sale;
    }

    /**
     * Applies a transaction to an existing sale.
     *
     * @param {Sales} sale - The sale object to which the transaction is applied.
     * @param {SaleTransactions} transaction - The transaction to apply.
     */
    public applyTransactionToSale(sale: Sales, transaction: SaleTransactions) {
        // Update sale details
        const { sale_customer, e_invoice, ...saleDetails } = transaction.sale_details || {};

        if (sale_customer) {
            sale.sale_customer = sale_customer.deleted_at ? undefined : sale_customer;
        }

        if (e_invoice) {
            sale.e_invoice = e_invoice.deleted_at ? undefined : e_invoice;
        }

        Object.assign(sale, saleDetails);

        if (!sale.sale_items) {
            sale.sale_items = [];
        }

        const saleItemsByUuid = keyBy(sale.sale_items, (si) => si.uuid);

        for (const saleItemTransaction of transaction.sale_items_transactions || []) {
            const saleItem = saleItemsByUuid[saleItemTransaction.sale_item_uuid];

            if (!saleItem) {
                // Create new sale item and add it to the sale
                const newSaleItem = {
                    ...saleItemTransaction.sale_item_details as SalesItems,
                    quantity: saleItemTransaction.quantity_difference,
                    uuid: saleItemTransaction.sale_item_uuid,
                };

                sale.sale_items.push(newSaleItem);

                // Update saleItemsByUuid with the new sale item
                saleItemsByUuid[saleItemTransaction.sale_item_uuid] = newSaleItem;
            } else {
                // Update existing sale item
                const { ingredients, variations, quantity, ...saleItemDetails } = saleItemTransaction.sale_item_details;

                Object.assign(saleItem, saleItemDetails);

                // Update ingredients and variations
                saleItem.ingredients = this.mergeSaleItemIngredients(saleItem.ingredients, ingredients);
                saleItem.variations = this.mergeSaleItemVariations(saleItem.variations, variations);

                if (saleItemTransaction.quantity_difference) {
                    saleItem.quantity = MathUtils.round(saleItem.quantity + saleItemTransaction.quantity_difference, 3);
                }
            }
        }
    }

    private mergeSaleItemVariations(variationsA?: SalesItemsVariations[], variationsB?: SalesItemsVariations[]): SalesItemsVariations[] {
        const updatedVariations = structuredClone(variationsA || []);

        if (variationsB) {
            const saleItemVariations = keyBy(updatedVariations, this.getVariationKey);

            for (const variation of structuredClone(variationsB)) {
                const variationKey = this.getVariationKey(variation);

                if (!saleItemVariations[variationKey]) {
                    updatedVariations.push(variation);
                    saleItemVariations[variationKey] = variation;
                } else {
                    const { deleted_at, ...variationData } = variation;

                    Object.assign(saleItemVariations[variationKey], variationData, { deleted_at: deleted_at || undefined });
                }
            }
        }

        return updatedVariations;
    }

    /**
     * Merges two sets of sale item ingredients.
     *
     * @param {SalesItemsIngredients[] | undefined} ingredientsA - The first set of ingredients.
     * @param {SalesItemsIngredients[] | undefined} ingredientsB - The second set of ingredients.
     * @returns {SalesItemsIngredients[]} The merged set of ingredients.
     */
    private mergeSaleItemIngredients(ingredientsA?: SalesItemsIngredients[], ingredientsB?: SalesItemsIngredients[]): SalesItemsIngredients[] {
        const pristineIngredients = structuredClone(ingredientsA || []);

        if (!ingredientsB) {
            return pristineIngredients;
        }

        // Merge the ingredients, new ingredients have higher priority
        const saleItemIngredients = {
            ...keyBy(pristineIngredients, this.getIngredientKey),
            ...keyBy(structuredClone(ingredientsB), this.getIngredientKey),
        }

        // Filter out ingredients with quantity 0
        return Object.values(saleItemIngredients).filter((ingredient) => ingredient.quantity);
    }

    private mergeTransactions(transactionA: SaleTransactions, transactionB: SaleTransactions): SaleTransactions {
        const opData = this.operatorManagerService.getOperatorData()

        const result: SaleTransactions = {
            autogenerated: false,
            created_at: transactionA.created_at! > transactionB.created_at! ? transactionA.created_at : transactionB.created_at,
            operator_id: opData.id,
            operator_name: opData.full_name,
            printed: transactionA.printed && transactionB.printed,
            sale_details: {
                ...transactionA.sale_details,
                ...transactionB.sale_details,
            },
            sale_id: transactionA.sale_id || transactionB.sale_id,
            sale_items_transactions: [],
            sale_uuid: transactionA.sale_uuid,
            skip_printing: transactionA.skip_printing && transactionB.skip_printing,
            updated_at: transactionA.updated_at! > transactionB.updated_at! ? transactionA.updated_at : transactionB.updated_at,
            uuid: generateUuid(),
        }

        const saleItemsByUuid = groupBy([...(transactionA.sale_items_transactions || []), ...(transactionB.sale_items_transactions || [])], (siTransaction) => siTransaction.sale_item_uuid);

        for (const [saleItemUuid, [siTransactionA, siTransactionB]] of Object.entries(saleItemsByUuid)) {
            if (siTransactionA && siTransactionB) {
                // Sale item is in multiple transactions, merge them
                const saleItemTransaction: SaleItemsTransactions = {
                    sale_item_uuid: saleItemUuid,
                    quantity_difference: MathUtils.round(siTransactionA.quantity_difference + siTransactionB.quantity_difference, 4),
                    sale_item_details: {
                        ...siTransactionA.sale_item_details,
                        ...siTransactionB.sale_item_details,
                    },
                    sale_item_id: siTransactionA.sale_item_id || siTransactionB.sale_item_id,
                    created_at: siTransactionA.created_at! > siTransactionB.created_at! ? siTransactionA.created_at : siTransactionB.created_at,
                    updated_at: siTransactionA.updated_at! > siTransactionB.updated_at! ? siTransactionA.updated_at : siTransactionB.updated_at,
                }

                if (siTransactionA.sale_item_details.ingredients || siTransactionB.sale_item_details.ingredients) {
                    saleItemTransaction.sale_item_details.ingredients = this.mergeSaleItemIngredients(siTransactionA.sale_item_details.ingredients, siTransactionB.sale_item_details.ingredients);
                }

                if (siTransactionA.sale_item_details.variations || siTransactionB.sale_item_details.variations) {
                    saleItemTransaction.sale_item_details.variations = this.mergeSaleItemVariations(siTransactionA.sale_item_details.variations, siTransactionB.sale_item_details.variations);
                }

                result.sale_items_transactions!.push(saleItemTransaction);
            } else if (siTransactionA) {
                // Sale item is only in one transaction, no need to merge
                result.sale_items_transactions!.push(siTransactionA);
            }
        }

        return result;
    }

    private createVariationSale(baseSale: Sales, transaction: SaleTransactions, options?: { includeDeletedComponents?: boolean }): VariationSale {
        const variationSale: VariationSale = {
            bill_lock: baseSale.bill_lock,
            channel: baseSale.channel,
            covers: baseSale.covers,
            date: transaction.created_at,
            deliver_at: baseSale.deliver_at,
            external_id: baseSale.external_id,
            name: baseSale.name,
            notes: baseSale.notes,
            open_at: baseSale.open_at,
            printed_prebills: baseSale.printed_prebills,
            room_id: baseSale.room_id,
            room_name: baseSale.room_name,
            table_name: baseSale.table_name,
            order_type: baseSale.order_type || 'normal',
            sale_number: baseSale.sale_number,
            status: baseSale.status,
            seller_name: transaction.operator_name || baseSale.seller_name,
            sent_exits: baseSale.sent_exits,
            sale_customer: baseSale.sale_customer,
            variation: !(transaction.sale_details?.open_at),
            variation_items: [],
        }

        // Index current sale items by uuid
        const saleItemsByUuid = keyBy(baseSale.sale_items || [], (si) => si.uuid);

        for (const transactionItem of transaction.sale_items_transactions || []) {
            const saleItem = saleItemsByUuid[transactionItem.sale_item_uuid] || ({} as SalesItems);

            // Skip auto tip items
            if (saleItem.sku === 'auto-tip') {
                continue;
            }

            const siDetails = (transactionItem.sale_item_details || {}) as Partial<SalesItems>;

            /*
                type:
                - addition: added_at is in the current transaction
                - removal: post-transaction quantity is 0 or negative
                - edit: added_at not in current transaction, but post-transaction quantity is positive
            */
            const variationType = 'added_at' in siDetails ? 'addition' : (saleItem?.quantity > 0 ? 'edit' : 'removal');

            const variationSaleItem: VariationSaleItem = {
                category_id: saleItem.category_id,
                exit_difference: 'exit' in siDetails,
                exit: saleItem.exit,
                half_portion_difference: 'half_portion' in siDetails,
                half_portion: saleItem.half_portion,
                ingredients_differences: 'ingredients' in siDetails,
                ingredients: options?.includeDeletedComponents ? saleItem.ingredients : saleItem.ingredients?.filter((ing) => ing.quantity) || [],
                item_id: saleItem.item_id,
                name: saleItem.name,
                notes_difference: 'notes' in siDetails,
                notes: saleItem.notes,
                price: saleItem.price,
                quantity_difference: transactionItem.quantity_difference,
                quantity: saleItem.quantity,
                type: variationType,
                uuid: transactionItem.sale_item_uuid,
                variations_differences: 'variations' in siDetails,
                variations: options?.includeDeletedComponents ? saleItem.variations : saleItem.variations?.filter((variation) => !variation.deleted_at) || []
            }

            variationSale.variation_items!.push(variationSaleItem);
        }

        return variationSale;
    }

    /**
     * Clean up a list of variation sale items by removing items that are not changes.
     *
     * The following items are removed:
     * - Additions with no quantity difference (i.e. no actual addition)
     * - Items with no differences in: exit, half portion, ingredients, notes, or quantity.
     *
     * @param {VariationSaleItem[]} variationItems - The list of variation sale items to clean up.
     * @returns {VariationSaleItem[]} The cleaned up list of variation sale items.
     */
    private cleanupVariationItems(variationItems: VariationSaleItem[], options?: { coverConfig?: CoverConfiguration }): VariationSaleItem[] {
        //Remove variation items with no difference flags and items that have been added and removed in the pending transactions (quantity delta is 0 in this case)
        let result = variationItems.filter((vi) =>
            !(vi.type === 'addition' && vi.quantity_difference === 0) &&
            (vi.exit_difference || vi.half_portion_difference || vi.ingredients_differences || vi.notes_difference || !!vi.quantity_difference || vi.variations_differences)
        );

        //Remove cover item from variation
        const coverConfig = options?.coverConfig;

        if (coverConfig?.type === 'item') {
            result = result.filter((vi) => vi.item_id !== coverConfig.data.id);
        }

        return result;
    }

    /**
     * Builds a list of variation sales from a list of sale transactions.
     *
     * @param {Sales} currentSale - The current sale.
     * @param {SaleTransactions[]} transactions - The list of sale transactions.
     * @param {BuildVariationsSalesOptions} [options] - The options for building the variation sales.
     * @returns {VariationSale[]} The list of variation sales.
     */
    public buildVariationSalesFromTransactions(currentSale: Sales, transactions: SaleTransactions[], options?: BuildVariationsSalesOptions): VariationSale[] {
        const variations: VariationSale[] = [];

        if (options?.mergeTransactions) {
            const sale = currentSale;

            let [masterTransaction, ...otherTransactions] = this.sortTransactions(transactions.filter((transaction) => (!transaction.skip_printing && !transaction.printed) || options?.includeAllTransactions));

            if (!masterTransaction) {
                return [];
            }

            for (const transaction of otherTransactions) {
                masterTransaction = this.mergeTransactions(masterTransaction, transaction);
            }

            variations.push(this.createVariationSale(sale, masterTransaction, options));
        } else {
            const sale = {} as Sales;

            for (const transaction of transactions) {
                this.applyTransactionToSale(sale, transaction);

                //Add transaction to variation sale only if it is in pending state (both printed and skip_printing are false)
                if ((!transaction.skip_printing && !transaction.printed) || options?.includeAllTransactions) {
                    variations.push({
                        ...this.createVariationSale(sale, transaction, options),
                        printed: transaction.printed,
                        skip_printing: transaction.skip_printing
                    });
                }
            }
        }

        for(const variation of variations) {
            variation.variation_items = this.cleanupVariationItems(variation.variation_items || [], options);
        }

        return variations;
    }

    /**
     * Creates a transaction from a sales comparison.
     *
     * @param {Sales} currentSale - The current sale.
     * @param {Sales} previousSale - The previous sale.
     * @returns {SaleTransactions} The transaction object generated from the comparison.
     */
    public createTransactionFromSalesComparison(currentSale: Sales, previousSale: Sales) {
        const removeUnknownFields = <T>(obj: T) => pickBy(obj, (value, key) => !String(key).startsWith('$'));

        const transaction: SaleTransactions = {
            autogenerated: true,
            operator_id: currentSale.seller_id,
            operator_name: currentSale.seller_name,
            printed: false,
            sale_details: {},
            sale_id: currentSale.id,
            sale_items_transactions: [],
            sale_uuid: currentSale.uuid!,
            skip_printing: false,
            uuid: generateUuid(),
            //@ts-expect-error
            created_at: currentSale.lastupdate_at,
            //@ts-expect-error
            updated_at: currentSale.lastupdate_at
        };

        const saleItemTransactions: SaleItemsTransactions[] = [];

        //Extract sale sub-entities
        const {
            sale_items: saleItemsA,
            price_changes: priceChangesA,
            amount: AmountA,
            final_amount: finalAmountA,
            final_net_amount: finalNetAmountA,
            ...saleDetailsA
        } = currentSale;

        const {
            sale_items: saleItemsB,
            price_changes: priceChangesB,
            amount: AmountB,
            final_amount: finalAmountB,
            final_net_amount: finalNetAmountB,
            ...saleDetailsB
        } = previousSale;

        //Compare sale fields
        const saleDetails: SaleBase = {
            ...compareObjects(removeUnknownFields(saleDetailsA), removeUnknownFields(saleDetailsB), null),
        }

        if(this.comparePriceChanges(priceChangesA, priceChangesB)) {
            saleDetails.price_changes = priceChangesA;
        }

        //Compare sale items
        const saleItemsByUuid = groupBy([...(saleItemsA || []), ...(saleItemsB || [])], (si) => si.uuid);

        for (const [saleItemUuid, [siA, siB]] of Object.entries(saleItemsByUuid)) {
            if (siA && siB) {
                // Sale item is in both sales, compare them
                const {
                    id: idA,
                    department: departmentA,
                    ingredients: ingredientsA,
                    variations: variationsA,
                    price_changes: priceChangesA,
                    final_price: final_priceA,
                    final_net_price: final_net_priceA,
                    quantity: quantityA,
                    created_at: createdAtA,
                    updated_at: updatedAtA,
                    deleted_at: deletedAtA,
                    ...siDetailsA
                } = siA;

                const {
                    id: idB,
                    department: departmentB,
                    ingredients: ingredientsB,
                    variations: variationsB,
                    price_changes: priceChangesB,
                    final_price: final_priceB,
                    final_net_price: final_net_priceB,
                    quantity: quantityB,
                    created_at: createdAtB,
                    updated_at: updatedAtB,
                    deleted_at: deletedAtB,
                    ...siDetailsB
                } = siB;

                //Compare sale item details
                const siDetails = compareObjects(removeUnknownFields(siDetailsA), removeUnknownFields(siDetailsB), null);

                if(siDetails.department_id) {
                    Object.assign(siDetails, { department: departmentA });
                }

                //Compare ingredients
                const updatedIngredients = this.compareIngredients(ingredientsA, ingredientsB);

                //Compare variations
                const updatedVariations = this.compareVariations(variationsA, variationsB);

                const saleItemDetails = {
                    ...siDetails,
                    ingredients: updatedIngredients.length ? updatedIngredients : undefined,
                    variations: updatedVariations?.length ? updatedVariations : undefined,
                    price_changes: this.comparePriceChanges(priceChangesA, priceChangesB) ? priceChangesA : undefined
                }

                const quantityDifference = MathUtils.round(quantityA - quantityB, 4);

                if(Object.values(saleItemDetails).filter((value) => value != null).length || quantityDifference) {
                    saleItemTransactions.push({
                        sale_item_uuid: saleItemUuid,
                        quantity_difference: quantityDifference,
                        sale_item_details: saleItemDetails,
                        sale_item_id: siA.id || siB.id,
                    });
                }
            } else if (siA) {
                // Sale item is only in one sale, no need to compare
                saleItemTransactions.push(this.saleItemToTransactionItem(siA));
            }
        }

        return {
            ...transaction,
            sale_details: saleDetails,
            sale_items_transactions: saleItemTransactions
        };
    }
}