<tilby-dialog-toolbar mat-dialog-title
    [title]="title()"
    [hideConfirm]="true"
    [customActions]="customActions">
    <div toolbar-content class="tw-flex tw-flex-row tw-items-center">
        &nbsp;
        <mat-icon>group</mat-icon>
        &nbsp;
        <strong>{{totalCovers()}}</strong>
    </div>
</tilby-dialog-toolbar>
<tilby-dialog-content class="tw-max-h-[90vh] tw-gap-4 tw-overflow-hidden">
    @if(isMobile) {
        <!-- mobile -->
        <div class="tw-flex tw-flex-col tw-overflow-hidden tw-h-full">
            <mat-form-field>
                <mat-label translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.ROOMS</mat-label>
                <mat-select [formControl]="selectedRoomsMobile" multiple (selectionChange)="onRoomMobileSelect($event)">
                    <mat-select-trigger>
                        {{selectedRoomsMobile.value?.[0]?.name || ''}}
                        @if ((selectedRoomsMobile.value?.length || 0) > 1) {
                        <span class="additional-selection">
                            (+{{(selectedRoomsMobile.value?.length || 0) - 1}} {{selectedRoomsMobile.value?.length === 2 ? otherLabel : othersLabel}})
                        </span>
                        }
                    </mat-select-trigger>
                    <mat-option value="select-all" selectAll [allValues]="rooms">{{'DIALOG.SUMMARY_OF_OCCUPIED_TABLES.ALL_ROOMS' | translate}}</mat-option>
                    @for (room of rooms; track room.id) {
                        <mat-option [value]="room">{{room.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.SELLERS</mat-label>
                <mat-select [formControl]="selectedSellersMobile" multiple (selectionChange)="onSellerMobileSelect($event)">
                    <mat-select-trigger>
                        {{selectedSellersMobile.value?.[0]?.name || ''}}
                        @if ((selectedSellersMobile.value?.length || 0) > 1) {
                        <span class="additional-selection">
                            (+{{(selectedSellersMobile.value?.length || 0) - 1}} {{selectedSellersMobile.value?.length === 2 ? otherLabel : othersLabel}})
                        </span>
                        }
                    </mat-select-trigger>
                @for (seller of sellers; track seller.id) {
                    <mat-option [value]="seller">{{seller.name}}</mat-option>
                }
                </mat-select>
            </mat-form-field>

            <h3 class="tw-mt-0" translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.TABLES</h3>
            <div class="tw-flex tw-flex-col tw-overflow-auto">
                @for (s of summary; track s) {
                    <div class="tw-flex tw-flex-row" style="border-bottom: 1px solid #a5f3fc">
                        <div class="tw-flex tw-flex-row tw-items-center tw-w-8/12">
                            <div class="tw-p-2"><mat-icon>table_bar</mat-icon></div>
                            <div class="tw-flex tw-flex-col">
                                <div><small><strong>{{ s.sale.room_name }} - {{ s.sale.table_name }}</strong></small></div>
                                <div><small>{{ s.sale.open_at | tilbyDate : "HH:mm" }}{{ getDataOpenAt(s.sale.open_at) }} <strong>{{s.sale.name}}</strong></small></div>
                            </div>
                        </div>
            
                        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-4/12">
                            <strong>{{s.amount | tilbyCurrency}}</strong>
                        </div>
                    </div>
                }
            </div>
        </div>
    } @else {
        <div class="tw-flex tw-flex-row tw-justify-between tw-overflow-hidden tw-gap-2 tw-h-full">
            <div class="tw-flex tw-flex-col tw-w-1/3">
                <div class="tw-flex tw-flex-col tw-h-1/3">
                    <h3 class="tw-mt-0" translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.ROOMS</h3>
                    <mat-selection-list multiple class="summary-selection-list tw-overflow-auto" #roomsList (selectionChange)="onRoomSelect($event)" [hideSingleSelectionIndicator]="true">
                        <mat-list-option value="null" [selected]="hasSelectedAllRooms" class="selected-all-rooms" [ngClass]="{ 'has-selected-but-not-all-rooms': !hasSelectedAllRooms }">{{'DIALOG.SUMMARY_OF_OCCUPIED_TABLES.ALL_ROOMS' | translate}}</mat-list-option>
                        @for (room of rooms; track room.id) {
                            <mat-list-option [value]="room" [ngClass]="{ 'has-selected-all-rooms': hasSelectedAllRooms }">{{room.name}}</mat-list-option>
                        }
                    </mat-selection-list>
                </div>

                <div class="tw-flex tw-flex-col tw-h-2/3">
                    <h3 translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.SELLERS</h3>
                    <mat-selection-list multiple class="summary-selection-list tw-overflow-auto" (selectionChange)="onSellerSelect($event)">
                        @for (seller of sellers; track seller.id) {
                            <mat-list-option [value]="seller" disableRipple="true">{{seller.name}}</mat-list-option>
                        }
                    </mat-selection-list>
                </div>
            </div>

            <div class="tw-flex tw-flex-col tw-w-2/3">
                <h3 class="tw-mt-0" translate>DIALOG.SUMMARY_OF_OCCUPIED_TABLES.TABLES</h3>
                <div class="tw-flex tw-flex-col tw-overflow-auto">
                    @for (s of summary; track s) {
                        <div class="tw-flex tw-flex-row" style="border-bottom: 1px solid #a5f3fc">
                            <div class="tw-flex tw-flex-row tw-items-center tw-w-6/12">
                                <div class="tw-p-2"><mat-icon>table_bar</mat-icon></div>
                                <div class="tw-flex tw-flex-col">
                                    <div><small><strong>{{ s.sale.room_name }} - {{ s.sale.table_name }}</strong></small></div>
                                    <div><small>{{ s.sale.open_at | tilbyDate : "HH:mm" }}{{ getDataOpenAt(s.sale.open_at) }} <strong>{{s.sale.name}}</strong></small></div>
                                </div>
                            </div>
                
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-2/12">
                                <strong>{{s.sentExits}}/{{s.exits}}</strong>
                            </div>
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-2/12">
                                <mat-icon>group</mat-icon> <strong>{{s.sale.covers}}</strong>
                            </div>
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-2/12">
                                <strong>{{s.amount | tilbyCurrency}}</strong>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</tilby-dialog-content>

