<tilby-dialog-toolbar mat-dialog-title
    title={{title()}}
    [hideConfirm]="status() !=='search-card' || cardSelected === undefined"
    (confirm)="confirm()"
/>
<tilby-dialog-content>
    @switch (status()) {
        @case ('initialize') {
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="tw-flex tw-flex-col">
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('serverAddress')?.hasError('required') && loginForm.get('serverAddress')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.SERVER_ADDRESS' | translate}}</mat-label>
                    <input matInput type="text" formControlName="serverAddress" required>
                    @if(loginForm.get('serverAddress')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.SERVER_ADDRESS_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('port')?.hasError('required') && loginForm.get('port')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PORT' | translate}}</mat-label>
                    <input matInput type="text" formControlName="port" required>
                    @if(loginForm.get('port')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PORT_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('plantCode')?.hasError('required') && loginForm.get('plantCode')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PLANT_CODE' | translate}}</mat-label>
                    <input matInput type="text" formControlName="plantCode" required>
                    @if(loginForm.get('plantCode')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PLANT_CODE_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('username')?.hasError('required') && loginForm.get('username')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.USERNAME' | translate}}</mat-label>
                    <input matInput type="text" formControlName="username" required>
                    @if(loginForm.get('username')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.USERNAME_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PASSWORD' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password" required>
                    @if(loginForm.get('password')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.PASSWORD_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="fill" [ngClass]="{'tw-pb-4': loginForm.get('userType')?.hasError('required') && loginForm.get('userType')?.touched}">
                    <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.USER_TYPE' | translate}}</mat-label>
                    <input matInput type="text" formControlName="userType" required>
                    @if(loginForm.get('userType')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.USER_TYPE_OBBLIGATORY' | translate}}</mat-error>
                    }
                </mat-form-field>
                @if(!credentialsOk) {
                    <div class="tw-text-red-500 tw-pl-3 tw-pb-2">{{credentialsErrorMessage | translate}}</div>
                }
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.SAVE_CREDENTIALS_ACTION' | translate}}</button>
            </form>
        }
        @case ('search-card') {
            <div class="tw-flex tw-flex-row">
                <form [formGroup]="searchForm" (ngSubmit)="onSearchCustomer()" class="tw-flex tw-w-full">
                    <div class="tw-flex tw-flex-col tw-w-2/3">
                        <mat-form-field appearance="fill" class="tw-pb-2 parent-hide-bottom-div">
                            <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.CARD_ID' | translate}}</mat-label>
                            <input matInput type="text" formControlName="cardNumber" (input)="onSearchCardChange()">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="tw-pt-2 parent-hide-bottom-div">
                            <mat-label>{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.SURNAME' | translate}}</mat-label>
                            <input matInput type="text" formControlName="surname" (input)="onSearchSurnameChange()">
                        </mat-form-field>
                    </div>
                    <div class="tw-w-1/3 tw-pl-6 tw-flex tw-items-center">
                        <button mat-raised-button color="primary" type="submit" class="tw-w-full tw-h-[54px]" [disabled]="this.searchForm.value.cardNumber == '' && this.searchForm.value.surname == ''">{{'CASHREGISTER.PMS_COMMON.SEARCH' | translate}}</button>
                    </div>
                </form>
            </div>
            <mat-list role="list" class="tw-overflow-y-auto tw-w-full tw-max-h-[180px] tw-pt-5">
                <mat-list-item role="listitem" *ngFor="let card of cards; let i = index" class="tw-w-full tw-px-0 tw-h-[70px]">
                    <div class="tw-flex tw-flex-col">
                        <mat-radio-button color="primary" [value]="card" (change)="cardSelected = $event.value" class="tw-w-full tw-block tw-pb-2">
                            <div class="tw-flex tw-gap-4">
                                <div class="tw-flex tw-flex-col tw-pr-3 min-[600px]:tw-min-w-[250px] tw-min-w-[130px]">
                                    <div class="min-[600px]:tw-text-[16px] tw-text-[13px]">
                                        {{card.description}}
                                    </div>
                                    <div class="min-[600px]:tw-text-[13px] tw-text-[10px]">
                                        {{card.accountId}}
                                    </div>
                                </div>
                                <div class="tw-flex tw-flex-col">
                                    <div class="min-[600px]:tw-text-[16px] tw-text-[13px]" [style.color]="+card.residualAmount === 0 && +card.accountType === 1 ? 'red' : ''">
                                        @if (+card.residualAmount >= 100000 && +card.accountType === 1) {
                                            {{'CASHREGISTER.WELLBY_PMS_PAYMENTS.UNLIMITED_CHARGES' | translate}}
                                        }
                                        @else if(+card.residualAmount === 0 && +card.accountType === 1) {
                                            {{'CASHREGISTER.WELLBY_PMS_PAYMENTS.REMAINING_AMOUNT_DEBT_EXHAUSTED' | translate}}
                                        }
                                        @else {
                                            {{'CASHREGISTER.WELLBY_PMS_PAYMENTS.' + (card.accountType === '0' ? 'REMAINING_AMOUNT' : 'REMAINING_AMOUNT_DEBT') | translate}}: {{+card.residualAmount | tilbyCurrency}}
                                        }
                                    </div>
                                    <div class="min-[600px]:tw-text-[16px] tw-text-[13px]">
                                        {{'CASHREGISTER.WELLBY_PMS_PAYMENTS.TIPO_ACCOUNT' | translate}}: {{ ('CASHREGISTER.WELLBY_PMS_PAYMENTS.' + (card.accountType === '0' ? 'CREDIT' : 'DEBT')) | translate}}
                                    </div>
                                </div>
                            </div>
                        </mat-radio-button>
                        @if (cards.length-1 !== i) {
                        <mat-divider class="tw-w-full"></mat-divider>
                        }
                    </div>
                </mat-list-item>
            </mat-list>
            @if(!cardOk) {
                <div class="tw-text-red-500 tw-pl-3">{{'CASHREGISTER.WELLBY_PMS_PAYMENTS.CARD_NOT_FOUND' | translate}}</div>
            }
            @if(searchError) {
                <div class="tw-text-red-500 tw-pl-3">{{searchError | translate}}</div>
            }
        }
    }
</tilby-dialog-content>
