import {
    CommonModule
} from "@angular/common";

import {
    Component,
    Injectable,
    OnInit,
    WritableSignal,
    computed,
    inject,
    signal,
} from "@angular/core";

import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef
} from "@angular/material/dialog";

import {
    MatFormFieldModule
} from "@angular/material/form-field";

import {
    MatIconModule
} from "@angular/material/icon";

import {
    MatInputModule
} from "@angular/material/input";

import {
    TranslateModule,
    TranslateService
} from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    TilbyCurrencyPipe
} from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import {
    lastValueFrom
} from "rxjs";

import {
    CampgestApi,
    CampgestApiError,
    CampgestPmsApiService
} from "src/app/shared/services/digital-payments/campgest/campgest-pms-api.service";

import {
    AlertDialogService
} from "src/app/dialogs";

import {
    PaymentMethods
} from "tilby-models";

export type CampgestCardResponseType = {
    account_id: number,
    card_owner: string,
    card_balance: number,
    credit: number,
    daily_limit: number,
    daily_balance: number,
    checkin_day: Date,
    expiration_day: Date,
    server_time: Date
}

type DialogStatus = 'initialize' | 'search-card' | 'confirm-card' | null;

@Component({
    selector: 'app-campgest-pms-payments-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        MatIconModule,
        TilbyDatePipe,
        MatFormFieldModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        TilbyCurrencyPipe
    ],
    templateUrl: './campgest-pms-payments-dialog.component.html',
    styleUrls: ['./campgest-pms-payments-dialog.component.scss']
})
export class CampgestPmsPaymentsDialogComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly campgestPmsApiService = inject(CampgestPmsApiService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly translateService = inject(TranslateService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly fb = inject(FormBuilder);

    private apiHandler?: CampgestApi;

    protected readonly data: DataDialogData = inject(MAT_DIALOG_DATA);

    loginForm!: FormGroup;
    searchForm!: FormGroup;

    cardOk = true;
    credentialsOk = true;
    card: CampgestCardResponseType | undefined = undefined;
    cardCode: string = '';

    status: WritableSignal<DialogStatus> = signal(null);

    async ngOnInit() {
        this.loginForm = this.fb.group({
            base_url: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            shop_code: ['', Validators.required]
        });

        this.searchForm = this.fb.group({
            cardNumber: ['', Validators.required]
        });

        try {
            this.apiHandler = await this.campgestPmsApiService.getApiInstance(this.data.paymentMethod);
        } catch (err) {
            //Do nothing
        } finally {
            this.updateStatus('search-card');
        }
    }

    protected title = computed(() => {
        switch (this.status()) {
            case 'initialize':
            case 'search-card':
                return this.translateService.instant('CASHREGISTER.CAMPGEST_PMS_PAYMENTS.TITLE');
            case 'confirm-card':
                return this.translateService.instant('CASHREGISTER.CAMPGEST_PMS_PAYMENTS.TITLE_WITH_PAYMENTS') + ' ' + this.tilbyCurrencyPipe.transform(this.data.amount || 0);
            default:
                return '';
        }
    });

    private updateStatus(updatedStatus: DialogStatus) {
        this.status.set(this.apiHandler ? updatedStatus : 'initialize');
    }

    async onLogin() {
        if (!this.loginForm.valid) {
            return;
        }

        const config = {
            ...this.loginForm.value,
        };

        const response = await this.campgestPmsApiService.checkLoginCredentials({
            "baseUrl": this.loginForm.value.base_url,
            "aUserName": this.loginForm.value.username,
            "aPassword": this.loginForm.value.password
        });

        if (response.Result) {
            try {
                this.apiHandler = await this.campgestPmsApiService.setupApi(this.data.paymentMethod, config);
            } catch (err) {
                //Do nothing
            } finally {
                this.updateStatus('search-card');
            }
        } else {
            this.credentialsOk = false;
        }
    }

    async onSearchCard() {
        if (!this.searchForm.valid || !this.apiHandler) {
            return;
        }

        try {
            const response = await this.apiHandler.checkCard({ "aCardCode": this.searchForm.get('cardNumber')?.value });

            this.cardOk = true;
            this.cardCode = this.searchForm.get('cardNumber')?.value;
            this.updateStatus('confirm-card');
            this.card = response;
        } catch (error: any) {
            if (error instanceof CampgestApiError) {
                this.cardOk = false;
            } else {
                this.alertDialogService.openDialog({
                    data: { messageLabel: error.message }
                });
            }
        }
    }

    onCardNumberChange() {
        this.cardOk = true;
    }

    confirm(): void {
        this.matDialogRef.close(this.cardCode);
    }
}

export type DataDialogData = {
    amount: number,
    paymentMethod: PaymentMethods
};

@Injectable({
    providedIn: 'root'
})
export class CampgestPmsPaymentsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: DataDialogData) {
        const config: NonNullableConfigData<any> = { ...this.switchMobileDesktopDimensions(), disableClose: true, data };

        return lastValueFrom(this.dialogRef.open(CampgestPmsPaymentsDialogComponent, config).afterClosed()).then((res: string | number) => {
            return res ? { cardCode: res } : undefined;
        });
    }
}
