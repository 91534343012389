import {
    Component,
    inject,
    Injector,
    Input,
    OnInit,
    signal
} from '@angular/core';

import {CommonModule} from '@angular/common';

import {
    Printers,
    Sales
} from 'tilby-models';

import {
    BarcodeEvent,
    BarcodeManagerService,
    ConfigurationManagerService,
    ConfigurationPreferences,
    EntityManagerService
} from 'src/app/core';

import {
    AlertDialogService,
    ConfirmDialogService,
    ConfirmDialogWithRememberService,
    DocumentPrintersManagerDialogStateService,
    EInvoiceDialogService,
    LotteryCodeDialogStateService,
    OpenDialogsService,
    OrderPrinterSelectDialogOutput,
    OrderPrinterSelectDialogService,
    PendingPrintsDialogService,
    PrinterErrorFiscalDialogService,
    QRCodeDialogService
} from "src/app/dialogs";

import {
    addSelectCustomerDialog,
    documentPrinter,
    errorsLogger,
    leanPMS
} from 'app/ajs-upgraded-providers';

import {GeneralInputButton} from '@tilby/tilby-ui-lib/models';

import {
    ActiveSaleService,
    ActiveSaleStoreService,
    CashregisterStateService,
    DeleteSaleOptions,
    SalePrintingUtilsService,
    SaleUtilsService,
    StoreSaleOptions
} from 'src/app/features/cashregister';

import {subscribeInComponent} from '@tilby/tilby-ui-lib/utilities';
import {TilbyActiveSaleHeaderInfoComponent} from '@tilby/tilby-ui-lib/components/tilby-order';

import {
    SelectSaleDialogService,
} from 'src/app/dialogs/cashregister';

import { ChangeSaleNameDialogService } from 'src/app/dialogs/change-sale-name-dialog';
import { DualService } from 'src/app/dual.service';
import { TranslateService } from '@ngx-translate/core';
import { AlvoloUtilsService } from 'src/app/features/settings/settings-alvolo/service';
import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';
import { HistoricalChangesDialogService } from 'src/app/dialogs/historical-changes-dialog/historical-changes-dialog.component';
import { MagoOrdersDialogService } from 'src/app/dialogs/cashregister';

@Component({
    selector: 'app-active-sale-header-info-wrapper',
    standalone: true,
    imports: [
        CommonModule,
        TilbyActiveSaleHeaderInfoComponent
    ],
    templateUrl: './active-sale-header-info-wrapper.component.html',
    styleUrls: ['./active-sale-header-info-wrapper.component.scss'],

})
export class ActiveSaleHeaderInfoWrapperComponent implements OnInit {
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly addSelectCustomerDialog = inject(addSelectCustomerDialog); // OLD DIALOG UPGRADED
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly alvoloUtilsService = inject(AlvoloUtilsService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly confirmDialogWithRememberService = inject(ConfirmDialogWithRememberService);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly eInvoiceDialogService = inject(EInvoiceDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly injector = inject(Injector);
    private readonly leanPMS = inject(leanPMS);
    private readonly lotteryCodeDialogStateService = inject(LotteryCodeDialogStateService);
    private readonly magoOrdersDialogService = inject(MagoOrdersDialogService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly orderPrinterSelectDialogService = inject(OrderPrinterSelectDialogService);
    private readonly pendingPrintsDialogService = inject(PendingPrintsDialogService);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly qrCodeDialogService = inject(QRCodeDialogService);
    private readonly salePrintingUtilsService = inject(SalePrintingUtilsService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private readonly selectSaleDialogService = inject(SelectSaleDialogService);
    private readonly translateService = inject(TranslateService);
    protected readonly dualService = inject(DualService);
    private readonly historicalChangesDialogService = inject(HistoricalChangesDialogService);
    private readonly errorsLoggerService = inject(errorsLogger);

    @Input({required:true}) sale?:Sales;
    @Input({required:true}) isPaymentsState?:boolean=false;
    @Input() isMobilePortrait = false;

    documentData: string | null = null;

    protected contextMenu: GeneralInputButton[] = [];

    private country : string | undefined = undefined;

    // CONFIGURATION
    protected isAlvoloEnabled = this.alvoloUtilsService.isEnabled();

    // PERMISSIONS
    private isAddNewItemPermitted = this.configurationManagerService.isUserPermitted("cashregister.add_new_item")
    private isBillLockPermitted = this.configurationManagerService.isUserPermitted('cashregister.bill_lock');
    private isDeleteSalePermitted = this.configurationManagerService.isUserPermitted("cashregister.delete_sale");
    private isStoreSalePermitted = this.configurationManagerService.isUserPermitted("cashregister.store_sale");

    protected isOrderPrinterPermitted = this.configurationManagerService.isUserPermitted('cashregister.preorder');

    // FUNCTIONS
    private isSplitSaleEnabled = !!this.configurationManagerService.getPreference('cashregister.enable_split_sale');

    protected isAccessibility : boolean = this.configurationManagerService.getPreference('general.simplified_accessibility') || false;
    protected isMagoEnabled = !!(this.configurationManagerService.getPreference('integrations.mago.publish_sales') || this.configurationManagerService.getPreference('integrations.mago.publish_customers'));

    constructor() {
        subscribeInComponent(BarcodeManagerService.barcodeEvents$, (event) => event.type === 'customer' && this.onBarcodeCustomer(event));

        subscribeInComponent(this.dualService.currentRoute$, (currentRoute) => {
            this.isPaymentsState = currentRoute.includes("payments");
        });

        subscribeInComponent(ActiveSaleStoreService.saleUpdates$, (data) => this.setContextMenu()); 

        subscribeInComponent(ActiveSaleService.printerDocumentDataUpdates$, (data) => {
            this.updateDocumentType(data);
        });
    }

    ngOnInit(){
        if(this.activeSaleService.printerDocumentData) {
            this.updateDocumentType(this.activeSaleService.printerDocumentData);
        }

        this.country = this.configurationManagerService.getShopCountry();
        this.setContextMenu();
    }

    updateDocumentType(data: DocumentPrinterOptions) {
        this.documentData = this.injector.get(DocumentPrintersManagerDialogStateService).getDocumentTypeName(data, 'CASHREGISTER.ACTIVE_SALE', { excludeReceipts: true });
    }

    // HEADER INFO SECTION
    protected async selectSaleAction() {
        const res = await this.selectSaleDialogService.openDialog();

        if (!res || (res.saleId === '') ) {
            return;
        }

        await this.activeSaleService.loadSale(res.saleId);
    }

    protected async addCustomerAction() {
            if(!this.activeSaleService.isActiveSale() /*|| !$scope.isShowcase()*/) {
                return;
            }

            this.activeSaleService.lockPaymentButtons.set(true);

            try{
                const customer = await this.addSelectCustomerDialog.show(this.activeSaleService.currentSale.sale_customer);
                await this.cashregisterStateService.addCustomer(customer);
            } catch(err) {
            } finally {
                this.activeSaleService.lockPaymentButtons.set(false);
            }
    }

    protected async onBarcodeCustomer(data: BarcodeEvent) {
        //Make sure it's a customer event
        if (data.type !== 'customer') {
            return;
        }

        if (data.customer) {
            await this.cashregisterStateService.addCustomer(data.customer);
        } else if (data.fidelity) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.SHOWCASE.NO_CLIENT_WITH_THIS_FIDELITY' } });
        } else if (data.taxCode) {
            if (this.configurationManagerService.getPreference("cashregister.on_taxcode_not_found_show_add_customer") !== false) {
                const customer = await this.addSelectCustomerDialog.show(undefined, { newCustomerData: { tax_code: data.taxCode } });
                await this.cashregisterStateService.addCustomer(customer);
            } else {
                this.activeSaleService.addTaxCode(data.taxCode);
            }
        }
    }

    protected async removeCustomerAction() {
        if(/*$scope.isShowcase()*/ true) {
            const answer = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'CASHREGISTER.ACTIVE_SALE.WANT_TO_REMOVE_CLIENT'
                }
            });

            if (answer) {
                this.activeSaleService.removeCustomer();
            }
        }
    }

    private async setContextMenu() {
        const newContextMenu = await this.getContextMenu();

        // TODO: currently this works since there can be only 3 states for the context menu (no menu, mago, normal)
        // and each has a different length, improve this in the future
        if(this.contextMenu.length !== newContextMenu.length) {
            this.contextMenu = newContextMenu;
        }
    }

    // START - CONTEXT MENU FUNCTIONS
    private async getContextMenu(): Promise<GeneralInputButton[]> {
        const isLeanPMSEnabled = await this.leanPMS.isSetup();

        const documentTypeButton = {
            name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.DOCUMENT_TTPE',
            icon: signal('description'),
            click: () => this.showSelectPrinterDocument(),
            isIt: signal(true)
        };

        const magoOrderButton = {
            name: "CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.MAGO_ORDERS",
            icon: signal("mago"),
            click: () => this.showMagoOrders(),
            isIt: signal(this.isMagoEnabled),
            isDisable: signal(false)
        };

        const deleteSaleButton = {
            name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.DELETE',
            icon: signal('delete'),
            click: () => this.deleteSale(),
            isIt: signal(this.isDeleteSalePermitted),
            isDisable: () => !this.activeSaleService.isActiveSale()
        };

        if(['mago'].includes(this.activeSaleService.currentSale?.channel!)) {
            return [
                documentTypeButton,
                magoOrderButton,
                deleteSaleButton
            ];
        }

        return [
            {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.SEPARATE_BILL',
                icon: signal('call_split'),
                click: () => this.activeSaleService.splitSale(),
                isIt: () => (this.isSplitSaleEnabled && this.canSplitSale())
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.MERGE_BILLS',
                icon: signal('merge'),
                click: () => this.mergeBills(),
                isIt: signal(true),
                isDisable: () => !this.activeSaleService.isActiveSale()
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.LOCK_SALE',
                icon: signal('lock'),
                click: () => this.activeSaleService.setLockStatus(true),
                isIt: () => this.isBillLockPermitted && this.activeSaleService.isActiveSale() && !(this.activeSaleService.currentSale?.bill_lock)
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.UNLOCK_SALE',
                icon: signal('lock_open_right'),
                click: () => this.activeSaleService.setLockStatus(false),
                isIt: () => this.isBillLockPermitted && this.activeSaleService.isActiveSale() && !!(this.activeSaleService.currentSale?.bill_lock)
            },
                documentTypeButton, 
            {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.BILLING_OPTIONS',
                icon: signal('receipt_long'),
                click: () => this.configureEInvoice(),
                isIt: () => this.showEInvoiceConfigMenu()
            }, {
                name: "CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.HOTEL_BOOKING",
                icon: signal("hotel"),
                click: () => this.showLeanHotelBooking(),
                isIt: signal(isLeanPMSEnabled),
                isDisable: () => !this.activeSaleService.isActiveSale()
            }, {
                name: "CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.QRCODE_ALVOLO",
                iconType: "svg",
                icon: signal("alvolo"),
                click: () => this.showAlvoloQrCode(),
                isIt: signal(this.isAlvoloEnabled),
                isDisable: () => !this.activeSaleService.isActiveSale()
            },
                ...(this.isMagoEnabled ? [magoOrderButton] : []),
            {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.ARCHIVE',
                icon: signal('archive'),
                click: () => this.storeSale(),
                isIt: () => this.canStoreSale(),
                isDisable: () => !this.isStoreSalePermitted || !this.activeSaleService.isActiveSale() || !!this.activeSaleService.currentSale.is_summary
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.MODIFY',
                icon: signal('edit'),
                click: () => this.modifySale(),
                isIt: signal(true),
                isDisable: () => !this.activeSaleService.isActiveSale()
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.ADD_ROW',
                icon: signal('variable_add'),
                click: () => this.cashregisterStateService.addNewItemToSale$.next(),
                isIt: signal(this.isAddNewItemPermitted)
            },{
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.LOTTERY_CODE',
                iconType: 'svg',
                icon: signal('lottery_code'),
                click: () => this.showAddLotteryCode(),
                isIt: () => this.activeSaleService.isActiveSale() && this.country === 'IT'
            },{
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.REPRINT_ORDER',
                icon: signal('article_shortcut'),
                click: () => this.reprintOrder(),
                isIt: () => this.activeSaleService.saleType() === 'order' && this.activeSaleService.saleMode() === 'bill'
            }, {
                name: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.DETAILS_SALE',
                icon: signal('overview'),
                click: () => this.openEditHistoryDetail(),
                isIt: () => this.activeSaleService.isActiveSale()
            }, 
                deleteSaleButton
        ]
    }

    private canStoreSale() {
        return ['IT', 'MT'].includes(this.country!)
    }
    private showEInvoiceConfigMenu() {
        return this.activeSaleService.isActiveSale() && ['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(`${this.activeSaleService.printerDocumentData?.document_type?.id||''}`);
    }
    private canSplitSale() {
        if (this.activeSaleService.isActiveSale()) {
            return !this.activeSaleService.currentSale.sale_parent_uuid || (this.activeSaleService.currentSale.sale_parent_uuid === this.activeSaleService.currentSale.uuid);
        } else {
            return false;
        }
    }

    private showAlvoloQrCode() {
        if(!this.isAlvoloEnabled || !this.activeSaleService.isActiveSale()) {
            return;
        }

        const baseUrl = this.alvoloUtilsService.getBaseUrl();
        const alvoloAlias = this.alvoloUtilsService.getAlias();
        const finalUrl = `${baseUrl}/${alvoloAlias}/pickup/Q-${this.activeSaleService.currentSale.uuid}/tavolo/${this.activeSaleService.currentSale.table_id || '0'}`

        //Build the header beforehand to avoid timing issues with the sale (e.g. the sale is unloaded before the QR code is printed)
        const header = [
            this.activeSaleService.currentSale.name
        ];

        if(this.activeSaleService.currentSale.table_id) {
            header.push(`${this.activeSaleService.currentSale.room_name} - ${this.activeSaleService.currentSale.table_name}`);
        }

        header.push(
            ' ',
            this.translateService.instant('CASHREGISTER.ALVOLO_QRCODE.PRINTED_TEXT')
        );

        this.qrCodeDialogService.openDialog({
            data: {
                customActions: [
                    {
                        name: '',
                        icon: signal('print'),
                        isIt: () => true,
                        click: () => this.printAlvoloQRCode(header, finalUrl)
                    }
                ],
                dialogTitle: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.QRCODE_ALVOLO',
                qrCode: {
                    data: finalUrl,
                    width: 300,
                    errorCorrectionLevel: 'medium'
                }
            }
        });
    }

    private async showMagoOrders() {
        const sale = await this.magoOrdersDialogService.openDialog();

        if(sale) {
            this.activeSaleService.openSale(sale);
        }
    }

    async showLeanHotelBooking() {
        if(!this.activeSaleService.isActiveSale()) {
            return;
        }

        const saleInfo = await this.leanPMS.getSaleReservationData(this.activeSaleService.currentSale);

        if(!saleInfo) {
            return;
        }

        this.activeSaleService.updateSaleDetails(saleInfo);
        this.activeSaleService.saveSale();
    }

    async printAlvoloQRCode(header: string[], content: string) {
        if(!this.activeSaleService.isActiveSale()) {
            return;
        }

        const printer = await this.getTargetPrinter('alvolo.default_qrcode_printer', { printersFilter: (printer: Printers) => printer.type != 'nonfiscal' });

        if(!printer) {
            return;
        }

        await this.documentPrinterService.printFreeNonFiscal(header, printer.id, {
            barcode: {
                type: 'QRCODE',
                value: content
            }
        });
    }

    private async mergeBills() {
        if (!this.activeSaleService.isActiveSale()) {
            return;
        }

        const res = await this.selectSaleDialogService.openDialog({
            data: {
                title: 'CASHREGISTER.ACTIVE_SALE.HEADER_INFORMATION.MENU.MERGE_BILLS',
                message: 'DIALOG.SELECT_SALE_DIALOG.MERGE_BILLS_MESSAGE',
                hideCurrentSale: true,
                selectModeOnly: true
            }
        });

        if (res?.saleId) {
            await this.activeSaleService.mergeSales(res.saleId);
        }
    }

    private async deleteSale() {
        if(!this.isDeleteSalePermitted || !this.activeSaleService.isActiveSale()) {
            return;
        }

        const skipPreference = 'dialog.skip_delete.skip_question';
        const skipQuestion = this.configurationManagerService.getPreference(skipPreference);

        if (!skipQuestion) {
            const response = await this.confirmDialogWithRememberService.openDialog({
                data: {
                    textContent: 'CASHREGISTER.ACTIVE_SALE.WANT_TO_DELETE_SALE',
                    textToggle: 'CASHREGISTER.ACTIVE_SALE.REMEMBER_CHOICE',
                    classTextContent: '',
                }
            });

            if (!response.confirm) {
                return;
            }

            if(response.toggleChoice) {
                this.configurationManagerService.setUserPreference(skipPreference, true);
            }
        }

        let deleteOptions: DeleteSaleOptions | undefined;

        // check if we need to ask for a reason to delete
        if (this.configurationManagerService.getSetting('cashregister.delete_sale_needs_reason')) {
            deleteOptions = await this.saleUtilsService.askReason('delete_sale');

            // if no reason is selected, do not delete
            if (!deleteOptions) {
                return;
            }
        }

        try {
            await this.activeSaleService.deleteSale(deleteOptions);
        } catch (error: any) {
            this.alertDialogService.openDialog({ data: { messageLabel: error } });
        }
    }

    private async storeSale() {
        if (!this.canStoreSale() || !this.activeSaleService.isActiveSale()) {
            return;
        }

        const skipPreference = 'dialog.skip_archive.skip_question';
        const skipQuestion = this.configurationManagerService.getPreference(skipPreference);

        if (!skipQuestion) {
            const response = await this.confirmDialogWithRememberService.openDialog({
                data: {
                    textContent: 'CASHREGISTER.ACTIVE_SALE.WANT_TO_ARCHIVE',
                    textToggle: 'CASHREGISTER.ACTIVE_SALE.REMEMBER_CHOICE',
                    classTextContent: '',
                }
            });

            if (!response.confirm) {
                return;
            }

            if(response.toggleChoice) {
                this.configurationManagerService.setUserPreference(skipPreference, true);
            }
        }

        let storeOptions: StoreSaleOptions | undefined;

        // check if we need to ask for a reason to store the sale
        if (this.configurationManagerService.getSetting('cashregister.store_sale_needs_reason')) {
            storeOptions = await this.saleUtilsService.askReason('store_sale');

            // if no reason is selected, do not store
            if (!storeOptions) {
                return;
            }
        }

        try {
            await this.activeSaleService.storeSale(storeOptions);
        } catch (error) {
            this.printerErrorFiscalService.openDialog({data: {error}});
        }
    }

    private async showSelectPrinterDocument() {
        let documentData = await this.injector.get(DocumentPrintersManagerDialogStateService).openDialog({ data: { documentSettings: this.activeSaleService.printerDocumentData! } });

        if(documentData) {
            this.activeSaleService.setPrinterDocumentData(documentData);
        }
    };
    private async configureEInvoice() {
        if(!this.activeSaleService.isActiveSale()) {
            return;
        }

        const salesEInvoice = await this.eInvoiceDialogService.openDialog({ data: { e_invoice: this.activeSaleService.currentSale.e_invoice || {} } });

        if(salesEInvoice) {
            this.activeSaleService.setEInvoiceData(salesEInvoice);
        }
    };
    // END - CONTEXT MENU FUNCTIONS

    private modifySale(){
        if(this.activeSaleService.isActiveSale()) {
            this.activeSaleService.editSale();
        }
    }

    async getTargetPrinter(defaultPrinterPreference: keyof ConfigurationPreferences, options?: { forceSelect?: boolean, printersFilter?: (printer: Printers) => boolean }) {
        let targetPrinter;

        const defaultPrinterId = parseInt(this.configurationManagerService.getPreference(defaultPrinterPreference) || '0', 10);

        if (defaultPrinterId && !options?.forceSelect) {
            let printer = await this.entityManagerService.printers.fetchOneOffline(defaultPrinterId);

            if (this.documentPrinterService.isPrinterUsable(printer)) {
                targetPrinter = printer;
            }
        }

        if(!targetPrinter) {
            let printers = await this.entityManagerService.printers.fetchCollectionOffline().then((printers) => printers.filter(printer => this.documentPrinterService.isPrinterUsable(printer)));

            if(options?.printersFilter) {
                printers = printers.filter(options.printersFilter);
            }

            const dialogOutput: OrderPrinterSelectDialogOutput | undefined  = await this.orderPrinterSelectDialogService.openDialog({ data: { printers, defaultPrinterId: defaultPrinterId, enableSetAsDefault: true } });
            targetPrinter = dialogOutput?.selectedPrinter;
            const setAsDefault = dialogOutput?.setAsDefault;

            if(!targetPrinter) {
                return;
            }

            if (setAsDefault) {
                this.configurationManagerService.setUserPreference(defaultPrinterPreference, targetPrinter.id?.toString() || '0');
            }
        }

        return targetPrinter;
    }

    async printNonFiscalSale(forceSelect? : boolean) {
        await this.activeSaleService.checkSaleConfirm(true);

        const targetPrinter = await this.getTargetPrinter('cashregister.nonfiscalsale_default_printer', { forceSelect });

        if(!targetPrinter) {
            return;
        }

        //Send the sale to the printer
        try {
            await this.activeSaleService.printNonFiscalSale(targetPrinter);

            this.openDialogsService.openSnackBarTilby('CASHREGISTER.ACTIVE_SALE.NON_FISCAL_SALE_PRINTED', 'MISC.OK', { duration: 3000 });
        } catch(error) {
            this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: targetPrinter.id}}});
        }
    }

    private async reprintOrder() {
        const results = await this.activeSaleService.reprintOrder();

        if(this.salePrintingUtilsService.getPrintErrors(results).length) {
            this.pendingPrintsDialogService.openDialog();
        }
    }

    async openEditHistoryDetail() {
        try {
            const [sale, transactions] = this.activeSaleService.getSaleChangesDetails({ includeCurrentChanges: false });

            if(sale && transactions) {
                await this.historicalChangesDialogService.openDialog({data: {title: 'HISTORY.TRANSACTIONS_VIEWER.TITLE', transactions, sale}});
            } else {
                return;
            }

        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    async saleChangeName() {
        const res = await this.injector.get(ChangeSaleNameDialogService).openDialog({saleName: this.activeSaleService.currentSale.name});

        if (!res || (res.saleName === '')) {
            return;
        }
        await this.activeSaleService.saveSale(res.saleName);
        await this.activeSaleService.loadSale(undefined);
    }

    async deselectSaleAction() {
        await this.activeSaleService.loadSale(undefined);
    }

    async showAddLotteryCode() {
        const oldLotteryCode = this.activeSaleService.currentSale.lottery_code;

        const res = await this.lotteryCodeDialogStateService.openDialog({
            data: {
                currentLotteryCode: oldLotteryCode
            }
        });

        if (!res) {
            return;
        }

        const lotteryCode = res.lotteryCode.toUpperCase();

        this.activeSaleService.updateSaleDetails({ lottery_code: lotteryCode });

        const customerId  = this.activeSaleService.currentSale?.sale_customer?.customer_id;

        if(customerId && oldLotteryCode !== lotteryCode) {
            const answer = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE'
                }
            });

            if(answer) {
                let customer = await this.entityManagerService.customers.fetchOneOfflineFirst(customerId);
                if (customer) {
                    customer.lottery_code = lotteryCode;
                    this.entityManagerService.customers.putOneOfflineFirst(customer);
                }
            }
        }
    };
}
