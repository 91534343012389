<div class="tw-h-full tw-w-full">
    <mat-list class="tw-w-full tw-h-full tw-overflow-y-auto tw-p-0">
        <ng-content select="[Header]"/>
        @for(payment of sale?.payments;track payment) {
            <mat-list-item #item class="tw-flex tw-justify-between tw-cursor-pointer tw-items-center tw-w-full tw-py-2 tw-h-[unset]">
                <div class="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-between tw-py-2" tilbyGestures (swipeLeft)="swipeLeftHandler(payment)">
                    @if(payment.paid) {
                        <mat-icon class="tw-mr-2 tw-text-green-500">check_circle</mat-icon>
                    }
                    <span class="tw-font-semibold tw-flex">{{payment.payment_method_name.toTitleCase()}}</span>
                    <span class="tw-ml-auto tw-font-semibold">{{ payment.amount | tilbyCurrency }}</span>
                </div>
                <div class="tw-text-xs">{{getPaymentDetail!(payment)}}</div>
            </mat-list-item>
            <mat-divider class="tw-mx-2"></mat-divider>
        }
    </mat-list>
</div>
