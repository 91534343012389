import angular from 'angular';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions 
} from 'src/app/shared/model/digital-payments.model';

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import {
    BezzleCommonService 
} from '../bedzzle/bedzzle-common';

import { validate as validateUuid } from 'uuid';

import {
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

import { Sales } from 'tilby-models';
import { SpiaggeItApiService } from 'app/modules/application/service/spiagge-it-api/spiagge-it-api';
import { SpiaggeItPaymentsService } from 'app/modules/cashregister/service/dialog/spiagge-it-payments/spiagge-it-payments';

export class SpiaggeItService implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private spiaggeItPayments: SpiaggeItPaymentsService,
        private spiaggeItApi: SpiaggeItApiService,
        private bedzzleCommon: BezzleCommonService,
        private entityManagerService: EntityManagerService,
        private userActiveSession: UserActiveSessionManagerService
    ) {
    }

    public async payment (amount: number, options: DigitalPaymentHandlerOptions) {
        const currentSale = options.sale;
        let folio;

        try {
            folio = await this.spiaggeItPayments.show({ amount: amount, paymentMethodId: options.paymentMethod.id });
        } catch(error) {
            throw (typeof error === 'object' ? 'CANCELED' : error);
        }

        if (!folio) {
            throw 'MISSING_FOLIO';
        }

        const transaction = await this.bedzzleCommon.buildTransaction(folio, currentSale);

        try {
            const response = await this.spiaggeItApi.createTransaction(options.paymentMethod.id, transaction);

            if(response.data.folioId) {
                folio.folioId = response.data.folioId;
                transaction.folioId = response.data.folioId;
            }

            let responseString = '';

            try {
                responseString = JSON.stringify(transaction);
            } catch(err) {
                //Nothing to do
            } finally {
                return {
                    acquirer_name: 'Spiagge.it',
                    payment_data: responseString,
                    tail: this.bedzzleCommon.buildTail(folio, currentSale, 'spiagge_it'),
                    unclaimed: true
                };
            }
        } catch(error: any) {
            switch (error.status) {
                case -1:
                    throw "SPIAGGE_IT_OFFLINE";
                case 400:
                case 401:
                    throw "SPIAGGE_IT_UNABLE_TO_COMPLETE_TRANSACTION";
                default:
                    throw "UNKNOWN_ERROR";
            }
        }
    }

    private async performRefund(sale: Sales) {
        if (!sale) {
            return;
        }

        //Make sure that the referenced sale had a spiagge.it payment and in that case perform the rollback
        const parentSaleId = sale.sale_parent_id || sale.sale_parent_uuid;

        if(!parentSaleId) {
            return;
        }

        const parentSale = validateUuid(parentSaleId.toString())
            ? await this.entityManagerService.sales.fetchCollectionOnline({ uuid: parentSaleId }).then((sales) => (sales as Sales[])[0])
            : await this.entityManagerService.sales.fetchOneOfflineFirst(parentSaleId);

        if(!parentSale) {
            return;
        }

        const spiaggePayment = parentSale.payments?.find((payment) => payment.payment_method_type_id === 29);

        if(!spiaggePayment) {
            return;
        }

        const paymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(spiaggePayment.payment_method_id);

        if(!paymentMethod) {
            return;
        }

        const shop = this.userActiveSession.getSession()?.shop;

        try {
            return await this.spiaggeItApi.rollbackTransaction(paymentMethod.id, String(shop?.id), parentSale.uuid!);
        } catch(err: any) {
            return err?.data?.error?.message;
        }
    }

    public async refund(amount: number, options: DigitalPaymentHandlerOptions) {
        const currentSale = options.sale;
        const refundResult = await this.performRefund(currentSale);

        return {
            acquirer_name: 'Spiagge.it',
            payment_data: JSON.stringify(refundResult),
            unclaimed: true
        };
    }

    public isEnabled(): boolean {
        return true;
    }

    public async printFailHook (sale: Sales, printerDocumentData: DocumentPrinterOptions) {
        const spiaggePayment = sale.payments?.find((payment) => payment.payment_method_type_id === 29);

        if(!spiaggePayment) {
            return;
        }

        const paymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(spiaggePayment.payment_method_id);

        if(!paymentMethod) {
            return;
        }

        let result = 'KO';

        try {
            const shop = this.userActiveSession.getSession()?.shop;
            await this.spiaggeItApi.rollbackTransaction(paymentMethod.id, String(shop?.id), sale.uuid!);
            result = 'OK';
        } catch (err) {
            //Nothing to do
        } finally {
            throw `SPIAGGE_IT_ROLLBACK_${result}`;
        }
    }
}

SpiaggeItService.$inject = [
    "spiaggeItPayments",
    "spiaggeItApi",
    "bedzzleCommon",
    "entityManager",
    "userActiveSession"
];

angular.module('digitalPayments').service('spiaggeIt', SpiaggeItService);