import angular from 'angular';
import _ from 'lodash';
import { paymentMethodTypes } from 'src/app/core';

angular.module('items').controller('DepartmentsCtrl', departmentsCtrl);

departmentsCtrl.$inject = ["$scope", "$translate", "entityManager", "checkManager", "departments", "vats", "activityCodes", "giftcardsTypes", "paymentMethods", "alertDialog", "itemSelector", "util", "leanPMS", "leanPMSApi"];

function departmentsCtrl($scope, $translate, entityManager, checkManager, departments, vats, activityCodes, giftcardsTypes, paymentMethods, alertDialog, itemSelector, util, leanPMS, leanPMSApi) {
    const pmsPaymentMethods = paymentMethodTypes.filter((pm) => pm.class === 'pms').map((pm) => pm.id);

    Object.assign($scope, {
        activityCodes: activityCodes,
        departments: _.sortBy(departments, 'id'),
        hasPMSEnabled: paymentMethods.some((pm) => pmsPaymentMethods.includes(pm.payment_method_type_id)),
        giftcardsEnabled: checkManager.isModuleEnabled("giftcard"),
        giftcardsTypes: giftcardsTypes,
        saveInProgress: false,
        topbar_context: {
            showcaseView: 'crudviewmode',
            back_label: $translate.instant('ITEMS.DEPARTMENTS.PRODUCTS'),
            current_label: $translate.instant('ITEMS.DEPARTMENTS.DEPARTMENTS')
        },
        vats: vats
    });

    const rechargeDepartment = parseInt(checkManager.getPreference('prepaid.recharge_department_id'));

    $scope.exempts = {
        'N4': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N4'),
        'N1': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N1'),
        'N2': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N2'),
        'N3': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N3'),
        'N5': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N5'),
        'N6': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_N6'),
        'VI': $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_VI'),
    };

    $scope.salesTypes = [
        {
            name: $translate.instant('ITEMS.DEPARTMENTS.SALES_TYPES.GOODS'),
            value: 'goods'
        }, {
            name: $translate.instant('ITEMS.DEPARTMENTS.SALES_TYPES.SERVICES'),
            value: 'services'
        }
    ];

    $scope.pristineDepartment = null;

    $scope.getVatValue = function(v) {
        return v ? (v.code ? ($scope.exempts[v.code] || v.code) : v.value + "%") : "";
    };

    $scope.canSave = function() {
        return !_.isEqual($scope.department, $scope.pristineDepartment);
    };

    $scope.isVatLocked = function() {
        return rechargeDepartment === $scope.pristineDepartment.id && !util.isUserAdmin();
    };

    $scope.afterSelectDepartment = function() {
        Object.assign($scope.pristineDepartment, {
            giftcard_type_uuid: $scope.pristineDepartment.giftcard_type_uuid || null,
        });

        $scope.department = structuredClone($scope.pristineDepartment);
    };

    // The first department is selected by default, can be changed to undefined and {}
    var selectDefaultDepartment = function() {
        $scope.pristineDepartment = _.isEmpty($scope.departments) ? {} : _.head($scope.departments);

        $scope.afterSelectDepartment();
    };

    selectDefaultDepartment();

    const isMagoEnabled = () => {
        const integrations_mago_publish_sales = checkManager.getPreference('integrations.mago.publish_sales');
        const integrations_mago_publish_customers = checkManager.getPreference('integrations.mago.publish_customers');
        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };
    $scope.addNewDepartment = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }
        // Fields are now blank
        $scope.pristineDepartment = {
            display: true
        };

        $scope.afterSelectDepartment();
    };

    let chainProductsLeanPMS = [];

    const fetchChainProducts = async () => {
        const isLeanPmsEnabled = await leanPMS.isSetup();

        if(!isLeanPmsEnabled) {
            return;
        }

        try {
            const data = await leanPMSApi.getChainProducts();
            chainProductsLeanPMS = data.results;
        } catch(error) {
        }
    };

    fetchChainProducts();

    $scope.selectChainProduct = () => {
        itemSelector.show(chainProductsLeanPMS).then(function(selectedItem) {
            $scope.department.pms_code = selectedItem.id;
        });
    };

    $scope.saveEdit = async () => {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if($scope.saveInProgress) {
            return;
        }

        const hasDepartmentWithSameName = $scope.departments.some((department) => (department.name === $scope.department.name && department.id !== $scope.department.id));

        if (hasDepartmentWithSameName) {
            alertDialog.show($translate.instant('ITEMS.DEPARTMENTS.DEPARTMENT_ALREADY_EXISTING'));
            return;
        }

        // Send to the BE
        const entityToBE = structuredClone($scope.department);

        try {
            $scope.saveInProgress = true;
            const functionToCall = entityToBE.id ? 'putOneOnline' : 'postOneOnline';

            const updatedEntity = await entityManager.departments[functionToCall](entityToBE);

            if(entityToBE.id) { //After PUT
                angular.copy(updatedEntity, $scope.pristineDepartment);
            } else { //After POST
                $scope.departments = [...$scope.departments, updatedEntity].sort((dep1, dep2) => dep1.id - dep2.id);
                $scope.pristineDepartment = updatedEntity;
            }

            $scope.afterSelectDepartment();
        } catch(error) {
            alertDialog.show($translate.instant('ITEMS.DEPARTMENTS.ERROR_TRY_LATER'));
        } finally {
            $scope.saveInProgress = false;
        }
    };

    $scope.abortEdit = function() {
        if ($scope.department.id) {
            // Update the (form) view
            $scope.afterSelectDepartment();
        } else {
            selectDefaultDepartment();
        }
    };
}
