import {
    Injectable,
    inject,
} from "@angular/core";

import {
    v4 as generateUuid
} from 'uuid';

import {
    ExitPrintRecord,
    OrderSendStatus,
    PendingPrint,
    SalesCashregister,
    VariationPrintRecord,
    VariationSale,
} from "src/app/shared/model/cashregister.model";

import {
    Printers,
    SaleTransactions,
    Sales,
    SalesItemsPriceChanges,
    SalesPriceChanges
} from "tilby-models";

import {
    ActiveSaleStoreService,
    SaleTransactionUtilsService
} from "src/app/features/cashregister";

import {
    documentPrinter,
    fiscalUtils,
    noFiscalPrinters,
} from "app/ajs-upgraded-providers";

import {
    ConfigurationManagerService,
    EntityManagerService,
    OperatorManagerService,
    StorageManagerService
} from "src/app/core";

import {
    groupBy,
    keyBy,
    MathUtils
} from "src/app/shared/utils";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import { TableVariation } from "app/modules/printers/service/non-fiscal-driver/non-fiscal-driver";
import { TranslateService } from "@ngx-translate/core";
import { stringToLines } from "src/app/shared/string-utils";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

type SaleNonFiscalDocumentLine = {
    type: 'text',
    align: 'left' | 'center',
    content: string,
    bold?: boolean,
    doubleHeight?: boolean
} | {
    type: 'qrcode',
    align: 'center',
    content: string,
    size: number
}

type SaleToNonFiscalDocumentOptions = {
    tail?: string;
    printerColumns?: number
}

@Injectable({
    providedIn: "root"
})
export class SalePrintingUtilsService {
    private readonly activeSaleStore = inject(ActiveSaleStoreService);
    private readonly saleTransactionUtils = inject(SaleTransactionUtilsService);
    private readonly entityManagerService = inject(EntityManagerService)
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly documentPrinter = inject(documentPrinter);
    private readonly NoFiscalPrinters = inject(noFiscalPrinters);
    private readonly operatorManagerService = inject(OperatorManagerService);
    private readonly storageManagerService = inject(StorageManagerService);
    private readonly translateService = inject(TranslateService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);

    public getPrintErrors (results: OrderSendStatus[]) {
        return results.filter(res => !['PRINTED', 'EMPTY_ORDER'].includes(res.result));
    }

    private async saveFailedOrderSends (results: OrderSendStatus[], record: (ExitPrintRecord | VariationPrintRecord)) {
        const channels = await this.entityManagerService.channels.fetchCollectionOffline();
        const channelsById = keyBy(channels, (c) => c.id);

        //Find failed sends
        const failedSends = this.getPrintErrors(results).filter(res => ['nonfiscal', 'receipt'].includes(res.printer.type));
        const now = new Date().toISOString();

        const recordsToAdd: PendingPrint[] = [];

        //Map failed sends
        for(const res of failedSends) {
            //Get clean printer
            const printer = await this.entityManagerService.printers.fetchOneOffline(res.printer.id!);
            let recordName = record.sale.name;

            if(record.sale.table_id) {
                recordName = `${record.sale.room_name} - ${record.sale.table_name}`;
            } else if (['take_away', 'delivery'].includes(record.sale.order_type || '')) {
                recordName = `${(channelsById[record.sale.channel!]?.name || '')} - ${this.tilbyDatePipe.transform(record.sale.deliver_at)} (#${record.sale.external_id})`;
            }
            
            recordsToAdd.push({
                ...record,
                attempts: 1,
                first_error_at: now,
                id: generateUuid(),
                name: recordName,
                last_error_at: now,
                printer: printer || res.printer,
            });
        }

        // Return if there are no failed sends
        if(!recordsToAdd.length) {
            return;
        }

        //Save failed sends
        await this.storageManagerService.saveCollection('pending_prints', recordsToAdd);
    }

    public async retryPendingPrint(pendingRecord: PendingPrint, alternativePrinter?: Printers) {
        const record = structuredClone(pendingRecord);
        const targetPrinter = structuredClone(pendingRecord.printer);
        
        if(alternativePrinter) {
            //Assign connection and driver data to target printer
            const { name, driver, ip_address, port, connection_type, ssl } = alternativePrinter;

            Object.assign(targetPrinter, { name, driver, ip_address, port, connection_type, ssl });
        }

        let results: OrderSendStatus[] = [];

        const reprintInfo = {
            reprint_source: record.printer.name,
            reprint_new: targetPrinter.name
        };

        if('variation' in record && record.variation) {
            Object.assign(record.variation, { reprint_info: reprintInfo });
        }

        Object.assign(record.sale, { reprint_info: reprintInfo });

        switch(record.print_type) {
            case 'exit':
                results = await this.sendExitToPrinterCore(record.sale, record.exit, [ targetPrinter ]);
                break;
            case 'variation':
                results = await this.sendTransactionsCore(record.sale, record.variation, [ targetPrinter ], record.options);
                break;
        }

        if(this.getPrintErrors(results).length) {
            this.storageManagerService.updateOne('pending_prints', record.id,  {
                attempts: record.attempts + 1,
                last_error_at: new Date().toISOString()
            });
        } else {
            this.storageManagerService.deleteOne('pending_prints', record.id);
        }
    }

    private async sendTransactionsCore(sale: Sales, variationSale: VariationSale | null, printers?: Printers[], options?: any): Promise<OrderSendStatus[]> {
        let results: OrderSendStatus[] = [];

        if (variationSale?.variation) {
            //Send the variation sale to the printers
            results = await this.NoFiscalPrinters.printVariationSale(variationSale, sale, printers);
        } else {
            results = await this.NoFiscalPrinters.printSale(sale, options, printers);
        }

        return results;
    }

    public async sendSaleAsOrder(sale: Sales, options?: any, printers?: Printers[]): Promise<OrderSendStatus[]> {
        const saleToSend = structuredClone(sale);

        Object.assign(saleToSend, { printed_at: new Date().toISOString() });

        const results = await this.NoFiscalPrinters.printSale(saleToSend, options, printers);

        // Add errors to the print errors store
        await this.saveFailedOrderSends(results, { print_type: 'variation', sale: saleToSend, variation: null, options: options });

        return results;
    }

    /**
     * Sends the sale transactions to the order printers
     * 
     * @param {string} saleUuid the uuid of the sale
     * @return {OrderSendStatus[]} the results of the order sending
     */
    public async sendTransactions(saleUuid: string): Promise<OrderSendStatus[]> {
        const [saleTransactions, sale] = await this.activeSaleStore.loadSale(saleUuid, { returnBuiltSale: true, returnRawBuildSale: true });

        if (!saleTransactions || !sale) {
            return [];
        }

        const variationSale = this.saleTransactionUtils.buildVariationSalesFromTransactions(sale, saleTransactions, { mergeTransactions: true })[0];

        // mark the transactions as printed
        await this.saleTransactionUtils.bulkUpdatePendingTransactions(saleTransactions, { printed: true, skip_printing: false });

        if(!variationSale?.variation_items?.length) {
            return [];
        }

        //Prepare and cleanup the sale (must be done after variationSale building)
        this.saleTransactionUtils.prepareSale(sale);

        const now = new Date().toISOString();

        Object.assign(variationSale, { printed_at: now });
        Object.assign(sale, { printed_at: now });

        // Send the variation/sale to the printers
        const results = await this.sendTransactionsCore(sale, variationSale);

        // Add errors to the print errors store
        await this.saveFailedOrderSends(results, { print_type: 'variation', sale: sale, variation: variationSale });

        return results;
    }

    private async updateSale(sale: Sales, updateData: Partial<Sales>, transactionOptions?: Pick<SaleTransactions, 'printed' | 'skip_printing'>) {
        const opData = this.operatorManagerService.getOperatorData();

        //Create update transaction
        const saleTransaction = this.saleTransactionUtils.createSaleTransaction(sale, opData);
        saleTransaction.sale_details = updateData;

        //Save transaction
        await this.saleTransactionUtils.saveTransaction({ ...saleTransaction, ...transactionOptions });
    }

    private async sendExitToPrinterCore(sale: Sales, exit: number, printers?: Printers[], isReprint: boolean = false): Promise<OrderSendStatus[]> {
        //Create a copy of the order for the print function
        const saleToSend = {
            ...structuredClone(sale),
            reprint: isReprint
        };

        //Send the order to the printer
        const results = await this.NoFiscalPrinters.printGoExit(saleToSend, exit, printers) as OrderSendStatus[];

        return results;
    }

    public async sendExitToPrinter(sale: Sales, exits: number | number[], options?: { batchMode?: boolean }): Promise<OrderSendStatus[]> {
        if (!sale || !exits) {
            return [];
        }

        //If exits is a single number, convert it to an array
        if(!Array.isArray(exits)) {
            exits = [exits];
        }

        //Create a copy of the order for the print function
        const saleToSend = Object.assign(structuredClone(sale), { printed_at: new Date().toISOString() });

        const sentExits = saleToSend.sent_exits || {};
        const finalResults: OrderSendStatus[] = [];

        const itemsByExit = groupBy(saleToSend.sale_items || [], si => si.exit);
        let saleNeedsUpdate = false;

        //In batch mode, send all unsent exits that have items
        if(options?.batchMode && !exits.length) {
            exits = Object.keys(itemsByExit).map(exit => parseInt(exit)).filter(exit => exit);
        }

        //Deduplicate exits
        exits = [...new Set(exits)];

        //In batch mode, if all items are on exit 1, send the whole order instead
        if(
            options?.batchMode &&
            exits.length === 1 &&
            exits[0] === 1 &&
            !itemsByExit['null'] &&
            !sentExits[1]
        ) {
            const results = await this.sendSaleAsOrder(saleToSend);
            finalResults.push(...results);

            sentExits[1] = true;
            saleNeedsUpdate = true;
            exits = []; //Disables the next for loop
        }

        for(const exit of exits) {
            const previousExitSendStatus = !!sentExits[exit];

            //If we are in batch mode, skip if the exit has already been sent or if there are no items for this exit
            if(options?.batchMode) {
                if(previousExitSendStatus || !itemsByExit[exit].length) {
                    continue;
                }
            }

            //Call core function
            const results = await this.sendExitToPrinterCore(saleToSend, exit, undefined, previousExitSendStatus);

            //Mark the exit as sent if it was not sent before
            if(!previousExitSendStatus) {
                sentExits[exit] = true;
                saleNeedsUpdate = true;
            }

            // Add errors to the print errors store
            await this.saveFailedOrderSends(results, { print_type: 'exit', sale: saleToSend, exit: exit, isReprint: previousExitSendStatus });

            finalResults.push(...results);
        }

        if(options?.batchMode) {
            await this.saleTransactionUtils.bulkUpdateSalePendingTransactions(saleToSend.uuid!, { printed: true });
        }

        //If the exit send was successful, mark it as sent (only if it wasn't sent before)
        if (saleNeedsUpdate) {
            this.updateSale(saleToSend, { sent_exits: sentExits }, { printed: true });
        }

        return finalResults;
    }

    public async printSaleTableVariation(tableVariation: TableVariation, sale: Sales) {
        const results = await this.NoFiscalPrinters.printSaleTableVariation(tableVariation, sale);

        return results;
    }

    public async printNonFiscalSale(saleToPrint: Sales, printer?: number | Printers) {
        let targetPrinter: Printers | undefined;

        if (typeof printer === 'number') {
            targetPrinter = await this.entityManagerService.printers.fetchOneOffline(printer);
        } else {
            targetPrinter = printer;
        }

        if (!targetPrinter) {
            const defaultPrinterId = parseInt(this.configurationManagerService.getPreference('cashregister.nonfiscalsale_default_printer') || '');

            if (defaultPrinterId) {
                targetPrinter = await this.entityManagerService.printers.fetchOneOffline(defaultPrinterId);
            }
        }

        if (!targetPrinter) {
            return;
        }

        await this.documentPrinter.printNonFiscalSale(saleToPrint, targetPrinter);

        const updateData: Partial<SalesCashregister> = {
            printed_prebills: (saleToPrint.printed_prebills || 0) + 1
        }

        if (this.configurationManagerService.getPreference('cashregister.lock_sale_after_prebill') && !saleToPrint.bill_lock) {
            updateData.bill_lock = true;
        }

        this.updateSale(saleToPrint, updateData, { printed: true });
    }

    /**
     * @description
     * Creates a non-fiscal document from a sale.
     *
     * @param sale The sale to convert
     * @param options Optional options
     * @param options.tail Tail lines to add at the end of the document
     * @param options.printerColumns Number of columns to use in the document
     * @returns The non-fiscal document lines
     */
    public saleToNonFiscalDocument(sale: Sales, options?: SaleToNonFiscalDocumentOptions): SaleNonFiscalDocumentLine[] {
        const lines: SaleNonFiscalDocumentLine[] = [];
        const printerColumns = options?.printerColumns || 46;

        const addLine = (line: string, align: 'left' | 'center' = 'left', bold = false, doubleHeight = false) => lines.push({ type: 'text', align, content: `${line}\n`, bold, doubleHeight });

        const calculatePriceChanges = (pChanges: SalesPriceChanges[] | SalesItemsPriceChanges[], partial: number) => {
            for (const pChange of pChanges.sort((p1, p2) => p1.index - p2.index)) {
                const pcAmount = this.fiscalUtils.getPriceChangeAmount(pChange, partial);

                if (pcAmount == null) {
                    continue;
                }

                partial = MathUtils.round(partial + pcAmount);

                const amountStr = `${['surcharge_fix', 'surcharge_perc'].includes(pChange.type) ? "+" : ""}${pcAmount.toFixed(2).replace(".", ",")}`;
                const rowDescription = pChange.description;

                addLine(rowDescription.slice(0, printerColumns - amountStr.length - 2).padEnd(printerColumns - amountStr.length, " ") + amountStr, 'left', false);
            }
        };

        // Add header if paper saving is not enabled
        if (!this.configurationManagerService.getPreference("cashregister.save_paper_on_prebill")) {
            addLine(this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_HEADER').split('\n'), 'center', true);
        }

        // Add header lines from the fiscal receipt
        for (const row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
            addLine(row);
        }

        // Add sale items
        for (const saleItem of this.fiscalUtils.extractSaleItems(sale)) {
            const rowDescription = `${saleItem.quantity}x ${saleItem.name || saleItem.department_name}`;
            const rowPrice = (saleItem.price * saleItem.quantity).toFixed(2).replace(".", ",");

            addLine(rowDescription.slice(0, printerColumns - rowPrice.length - 2).padEnd(printerColumns - rowPrice.length, " ") + rowPrice, 'left', false);

            // Add item notes if present
            if (saleItem.notes) {
                const notesLines = stringToLines(saleItem.notes, printerColumns);

                for (const nl of notesLines) {
                    if (nl.trim()) {
                        addLine(' '.repeat(5) + nl.trim(), 'left', false);
                    }
                }
            }

            // Calculate and add discounts/surcharges for the item
            if (saleItem.price_changes) {
                calculatePriceChanges(saleItem.price_changes, MathUtils.round(saleItem.price * saleItem.quantity));
            }
        }

        // Add subtotal line
        const subTotDescr = this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_SUBTOTAL');
        const subTotAmount = sale.amount!.toFixed(2).replace(".", ",");

        addLine("");
        addLine(subTotDescr.padEnd(printerColumns - subTotAmount.length, " ") + subTotAmount, 'left', true);

        // Calculate and add discounts/surcharges on subtotal
        if (sale.final_amount! >= 0) {
            const partialPrice = MathUtils.round(sale.amount!);
            calculatePriceChanges(sale.price_changes || [], partialPrice);
        }

        // Add final amount line
        const totDescription = this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FINAL_AMOUNT', { currency: TilbyCurrencyPipe.currency.code });
        const totAmount = sale.final_amount!.toFixed(2).replace(".", ",");

        addLine(totDescription.slice(0, printerColumns - totAmount.length - 2).padEnd(printerColumns - totAmount.length, " ") + totAmount, 'left', false, true);

        const addCoverInfo = this.configurationManagerService.getPreference('cashregister.prebill.covers_info.enabled');

        if (addCoverInfo && sale.table_id && sale.covers) {
            addLine("");
            // Add covers line
            const coversDescription = this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.COVERS');
            const covers = sale.covers.toString();
            addLine(coversDescription.slice(0, printerColumns - covers.length - 2).padEnd(printerColumns - covers.length, " ") + covers, 'left', false, false);

            // Add amount per cover line
            const amountPerCover = MathUtils.round(sale.final_amount! / sale.covers).toFixed(2).replace(".", ",");
            const amountDescription = this.configurationManagerService.getPreference('cashregister.prebill.covers_info.per_cover_amount_label') || this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.COVER_AMOUNT');
            addLine(amountDescription.slice(0, printerColumns - amountPerCover.length - 2).padEnd(printerColumns - amountPerCover.length, " ") + amountPerCover, 'left', false, false);

            addLine("");
        }

        // Add optional tail lines if provided
        if (options?.tail) {
            for (const line of ["", ...stringToLines(options.tail), ""]) {
                addLine(line);
            }
        }

        if (!this.configurationManagerService.getPreference('cashregister.save_paper_on_prebill')) {
            for(const line of this.translateService.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FOOTER').split('\n')) {
                addLine(line, 'center');
            }
        } else {
            addLine("");
        }

        // Add QR code if preference is set
        if (this.configurationManagerService.getPreference('fiscalprinter.print_sale_qr_code')) {
            lines.push({ type: 'qrcode', align: 'center', content: `s_uid=${sale.uuid}`, size: 8 });
        }

        return lines;
    }
}