import angular from "angular";

import {
    MathUtils
} from "@tilby/tilby-ui-lib/utilities";

import {
    WellbyPmsPaymentsDialogService
} from "src/app/dialogs/cashregister/wellby-pms-payments-dialog/wellby-pms-payments-dialog.component";

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from "src/app/shared/model/digital-payments.model";

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from "src/app/shared/model/document-printer.model";

import {
    WellbyPmsApi,
    WellbyPmsApiService
} from "src/app/shared/services/digital-payments/wellby/wellby-pms-api.service";

import {
    Items,
    Sales
} from "tilby-models";

export class WellbyPMSPayment implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private wellbyPmsApiService: WellbyPmsApiService,
        private wellBYPmsPaymentsDialog: WellbyPmsPaymentsDialogService,
        private util: any,
        private $translate: any,
    ) {
    }

    async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const currentSale = options.sale;

        let sessionId = undefined;
        let apiHandler: WellbyPmsApi | undefined;

        try {
            const result = await this.wellBYPmsPaymentsDialog.openDialog({
                amount: amount,
                paymentMethod: options.paymentMethod
            });

            if (!result) {
                throw 'CANCELED';
            }

            apiHandler = await this.wellbyPmsApiService.getApiInstance(options.paymentMethod);

            const response = await apiHandler.login();

            if (response?.esitoCodice !== '0') {
                throw 'WELLBY_INVALID_SESSION';
            }

            sessionId = response.sessionId;

            const itemsMap: Record<number, Items> = await this.util.getItemsFromIds(currentSale.sale_items);

            // Check option1 (idListino)
            for (const saleItem of currentSale.sale_items || []) {
                if (!itemsMap[saleItem.item_id!].option1_value && !saleItem.department.pms_code) {
                    throw this.$translate.instant(`DIGITAL_PAYMENTS.WELLBY.ERROR_DURING_PRICE_LIST_ID_CHECK`, { value: saleItem.name });
                }
            }

            for (const saleItem of currentSale.sale_items || []) {
                const saleItemOption = itemsMap[saleItem.item_id!].option1_value;

                const movement = {
                    description: saleItem.name + ' #' + currentSale.id,
                    priceListId: saleItemOption || saleItem.department.pms_code,
                    amount: saleItem.final_price * saleItem.quantity,
                    quantity: saleItem.quantity,
                    unitCost: saleItem.price,
                    discount: MathUtils.round((saleItem.price - saleItem.final_price) * saleItem.quantity),
                    idUser: result.cardCode.userId,
                    idTypeAccount: result.cardCode.accountType,
                    tagNumero: result.cardCode.tagNumber
                }

                const responseAddMovement = await apiHandler.addMovement(sessionId, movement);

                if (responseAddMovement?.esitoCodice !== '0') {
                    throw 'WELLBY_ADD_MOVEMENT_ERROR'; // TODO :L'inserimento del movimento ha dato esisto negativo, lanciare eccezione e TODO : storno di tutti quelli precedenti
                }
            }

            const paymentInfo = (await apiHandler.getPaymentInfoData(sessionId, result.cardCode.accountId)).accounts[0];

            const paymentData = {
                accountId: paymentInfo?.accountId,
                description: paymentInfo?.description || 'Anonimo',
                residualAmount: paymentInfo?.residualAmount
            };

            const responseString = JSON.stringify(paymentData);

            return {
                acquirer_name: 'Wellby PMS',
                payment_data: responseString,
                tail: this.createTail(paymentData),
                unclaimed: true
            };

            // TODO : Lo storno verrà gestito in un altro task specifico.
        } catch (error: any) {
            if (error) {
                throw error.message ? error.message : error;
            } else {
                throw 'UNKNOWN_ERROR';
            }
        } finally {
            if (sessionId && apiHandler) {
                await apiHandler.logout();
            }
        }
    }

    createTail(paymentData: any) {
        const accountId = paymentData.accountId;
        const description = paymentData.description;
        const residualAmount = paymentData.residualAmount;

        const tailLines = [
            "",
            `*** ACQUISTO WELLBY ***`,
            `Conto: ${accountId}`,
            `Nome: ${description}`,
            `Credito residuo: ${residualAmount}`
        ];

        return tailLines.join('\n');
    }

    public isEnabled() {
        return true;
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {

        // TODO : Lo storno (fallback) verrà gestito in un altro task specifico.
        throw 'WELLBY_PMS_ROLLBACK_KO';
    }

}

WellbyPMSPayment.$inject = ['wellbyPmsApiService', 'wellbyPmsPaymentsDialog', 'util', '$translate'];

angular.module('digitalPayments').service('wellbyPMS', WellbyPMSPayment);

